// @flow

import type { PageData, PageIndex } from "../../../redux/data/page/PageTypes";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectPageIndicesOrdered, selectPagesOrdered } from "../../../redux/slice/PageSlice";
import { PageSkeleton } from "./PageSkeleton";
import { usePageSVG } from "../../../redux/hook/usePageSVG";

type PageSvgProps = {
    page: number,
    zoom: number,
    onLoadChange?: boolean => void,
};

/**
 * Fetches and renders the svg for the given page of the currently loaded pdf
 * @constructor
 */
export function PageSvg(props: PageSvgProps) {
    /*
        Fetch Redux stuff
     */
    const pageIndex: PageIndex = useSelector(state => selectPageIndicesOrdered(state)[props.page]);
    const pageData: PageData = useSelector(state => selectPagesOrdered(state)[props.page]);

    /*
        Local State management
     */
    const svgSource = usePageSVG(pageIndex);
    const loading = !svgSource;
    useEffect(() => props.onLoadChange && props.onLoadChange(loading), [ props.onLoadChange, loading ]);

    /*
        Render svg if loaded, skeleton if not
     */

    if (loading) {
        return (
            <PageSkeleton/>
        )
    }

    return (
        <div
            style={{
                backgroundImage: `url(${svgSource})`,
                backgroundSize: "100% 100%",
                backgroundColor: "white",
                width: `${pageData.width * props.zoom}px`,
                height: `${pageData.height * props.zoom}px`,
            }}
        />
    );
}
