// @flow

import type { Authentication } from "../api/structure/Base";
import axios from "axios";
import FileSaver from "file-saver";

/**
 * Converts a file to request data for creating a new document
 * @param file
 * @returns {Promise<{name: string, content: string}>}
 */
export function fileToRequest(file: File, isPersistent: boolean): Promise<{fileName: string, content: string, isPersistent: boolean}> {
    return new Promise<{name: string, content: string}>((resolve, reject) => {
        let reader: FileReader = new FileReader();
        reader.onload = () => {
            if (reader.result !== null) {
                let b64: string = reader.result.toString().replace(/^data:(.*,)?/, "");

                if ((b64.length % 4) > 0) {
                    b64 += "=".repeat(4 - (b64.length % 4));
                }
                resolve({
                    fileName: file.name,
                    content: b64,
                    isPersistent: isPersistent,
                });
            } else {
                reject(new Error("No result from fileread"));
            }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}
