// @flow

import SockJS from "sockjs-client";
import { CompatClient, Stomp, StompSubscription } from "@stomp/stompjs";
import {
    connected,
    connecting,
    connectionError,
    selectConnected,
    selectConnecting,
    selectSocketSession,
    setSession
} from "../slice/SocketSlice";
import type { DocumentData, DocumentIndex, DocumentSTOMPPayload } from "../data/document/DocumentTypes";
import { documentDataToPayload, documentIndexMatch, documentPayloadToData } from "../data/document/DocumentUtil";

import {
    loaded as documentLoaded,
    loading as documentLoading,
    selectDocument,
    selectDocumentIndex,
    selectDocumentLoaded,
    unloaded as documentUnloaded,
    updateConfirmed as documentUpdated,
    updateRejected as documentError,
    updateUnconfirmed as documentUpdatePending
} from "../slice/DocumentSlice";

import {
    loaded as pageLoaded,
    loading as pageLoading,
    selectPage,
    selectPageIndices,
    unloaded as pageUnloaded,
    updateConfirmed as pageUpdated,
    updateRejected as pageError
} from "../slice/PageSlice";
import {
    allUnloaded as contentblockUnloadAll,
    loaded as contentblockLoaded,
    loading as contentblockLoading,
    selectContentBlock,
    selectIndicesFromPage as selectContentBlockIndicesFromPage,
    unloaded as contentblockUnloaded,
    updateConfirmed as contentblockUpdated,
    updateRejected as contentblockError,
    updateUnconfirmed as contentblockUpdatePending
} from "../slice/ContentBlockSlice";
import {
    allUnloaded as pdfElementUnloadAll,
    loaded as pdfElementLoaded,
    loading as pdfElementLoading,
    selectIndicesFromPage as selectPdfElementIndicesFromPage,
    selectPdfElement,
    unloaded as pdfElementUnloaded,
    updateConfirmed as pdfElementUpdated,
    updateRejected as pdfElementError,
    updateUnconfirmed as pdfElementUpdatePending
} from "../slice/PdfElementSlice";
import type { PageData, PageIndex, PageSTOMPPayload } from "../data/page/PageTypes";
import { pagePayloadToData } from "../data/page/PageUtil";
import type {
    ContentBlockData,
    ContentBlockIndex,
    ContentBlockSTOMPPayload
} from "../data/contentblock/ContentBlockTypes";
import { contentBlockDataToPayload, contentBlockPayloadToData } from "../data/contentblock/ContentBlockUtil";
import type { PdfElementData, PdfElementIndex, PdfElementSTOMPPayload } from "../data/pdfelement/PdfElementTypes";
import { pdfElementDataToPayload, pdfElementPayloadToData } from "../data/pdfelement/PdfElementUtil";
import { loadDocument, unloadDocument } from "../action";
import axios from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import type { TaskData, TaskIndex, TaskSTOMPPayload } from "../data/task/TaskTypes";
import { taskPayloadToData } from "../data/task/TaskUtil";
import {
    loaded as taskLoaded,
    loading as taskLoading,
    selectTask,
    selectTaskIndices,
    unloaded as taskUnloaded,
    updateConfirmed as taskUpdated,
    updateRejected as taskError
} from "../slice/TaskSlice";
import { setContentBlockEditing } from "../slice/EditorSlice";
import type {
    AccDocsIssueData,
    AccDocsIssueIndex,
    AccDocsIssueSTOMPPayload
} from "../data/accdocsissues/AccDocsIssueTypes";
import {
    loaded as accDocsIssueLoaded,
    loading as accDocsIssueLoading,
    selectAccDocsIssue,
    unloaded as accDocsIssueUnloaded,
    updateUnconfirmed as accDocsIssueUpdateUnconfirmed,
    updateConfirmed as accDocsIssueUpdateConfirmed,
    updateRejected as accDocsIssueUpdateRejected
} from "../slice/AccDocsIssueSlice"
import {accDocsIssueDataToPayload, accDocsIssuePayloadToData} from "../data/accdocsissues/AccDocsIssueUtil";



/**
 * Creates the stomp message topic for subscribing to an element collection of the given type
 * @param index
 * @param type
 * @param parent the name of the parent element type if any
 * @returns {string}
 */
function getMultiTopic<I>(index: I, type: string, parent: string | null): string {
    return `${type}/multi${parent ? "/" + index[parent] : ""}`;
}

/**
 * Creates the stomp topic to listen to a single element
 * @param index
 * @param type the name of the type the topic should address
 * @returns {string}
 */
function getSingleTopic<I>(index: I, type: string): string {
    return `${type}/single/${index[type]}`;
}

/**
 * Handles websocket connections for sending and recieving data
 * @param storeApi
 * @returns {function(*): function(*)}
 * @constructor
 */
export const MessagingMiddleware = storeApi => next => {
    const websocketUrl = `${process.env.REACT_APP_BACKEND_URL}/websocket`;
    let socket = null;
    let stompclient: CompatClient = null;

    const subscriptions: { value: {topic: string, subscription: StompSubscription }[] } = { value: [] };
    const pendingMessages: { value: any[] } = { value: [] }
    const origin: { value: number } = { value: 0 };

    function makeOrigin(): string {
        const result = `${origin.value}`;
        origin.value = origin.value + 1;
        return result;
    }

    function connect() {
        //if already connecting, abort
        if (selectConnecting(storeApi.getState())) {
            return;
        }

        subscriptions.value = []; //when connecting, all subscriptions are canceled
        storeApi.dispatch(connecting());

        //connect sockjs
        socket = new SockJS(websocketUrl);

        //create stomp client
        stompclient = Stomp.over(socket);
        stompclient.connect({ //TODO: use actual user login once available
                login: "demo@example.com",
                passcode: "demo",
            },
            () => { //connect
                storeApi.dispatch(connected());

                //retrieve origin
                const subscribeRef = { subscription: null };
                subscribeRef.subscription = stompclient.subscribe("/topic/identity", message => {
                    storeApi.dispatch(setSession({ session: message.body }));
                    subscribeRef.subscription?.unsubscribe();


                    //when the session has been established, we need to re dispatch all actions that have been added to the queue
                    for (const action of pendingMessages.value) {
                        storeApi.dispatch(action);
                    }
                });
                stompclient.publish({
                    destination: "/app/identity",
                });
            }, () => { //error
                stompclient.deactivate(); // deactivate client after error occured
                storeApi.dispatch(connectionError({ error: "Stomp lost connection" }));
                setTimeout(connect, 2100); //slightly different delay to avoid connecting 2 times
            }, () => { //closed socket
                stompclient.deactivate(); // deactivate client after error occured
                storeApi.dispatch(connectionError({ error: "Stomp lost connection" }));
                setTimeout(connect, 2000); //slightly different delay to avoid connecting 2 times
            });
    }

    function subscribeMultipleElements<I, P, D>(index: I, type: string, parent: string | null, payloadConverter: P => D, createElement: (I, boolean) => void, syncElement: (I, number, D) => void, error: (string, string) => void) {
        let topic: string = getMultiTopic<I>(index, type, parent);
        if (subscriptions.value.findIndex(target => target.topic === topic) < 0) {
            const subscription = stompclient.subscribe(`/topic/${topic}`, (message) => {
                const { index, action, element, origin, error } = JSON.parse(message.body);
                const isFromOwnCause = origin.session === selectSocketSession(storeApi.getState())
                const shortOrigin = isFromOwnCause ? origin.identifier : "-1";
                const errorMessage = error?.join("\n");

                if (action === "SYNC") {
                    syncElement(index, element.version, payloadConverter(element));
                } else if (action === "CREATE") {
                    createElement(index, isFromOwnCause);
                } else if (action === "ERROR" && shortOrigin >= 0) { // only print errors from this client
                    error(errorMessage, shortOrigin);
                } else {
                    console.log(`unexpected multi element action: ${action}`);
                }
            });
            subscriptions.value = [...subscriptions.value, {topic: topic, subscription: subscription}];
        }
    }

    function subscribeSingleElement<I, P, D>(index: I, type: string, payloadConverter: P => D, updateElement: (D, number, string) => void, errorElement: (string, string) => void, deleteElement: () => void) {
        let topic: string = getSingleTopic<I>(index, type);
        if (subscriptions.value.findIndex(target => target.topic === topic) < 0) {
            const subscription = stompclient.subscribe(`/topic/${topic}`, (message) => {
                const { index, action, element, origin, errors } = JSON.parse(message.body);

                //parse origin
                const session = selectSocketSession(storeApi.getState());
                const shortOrigin = origin.startsWith(session) ? origin.split(":")[1] : "-1";
                if (action === "UPDATE" || action === "SYNC") {
                    updateElement(payloadConverter(element), element.version, shortOrigin);
                } else if (action === "ERROR" && shortOrigin >= 0) { //only print errors caused by this client
                    errorElement(errors?.join("\n"), shortOrigin);
                } else if (action === "DELETE") {
                    deleteElement();
                }
            });
            subscriptions.value = [...subscriptions.value, {topic: topic, subscription: subscription}];
        }
    }

    function unsubscribeMultipleElements<I>(index: I, type: string, parent: string) {
        let topic: string = getMultiTopic<I>(index, type, parent);
        // stompclient.unsubscribe(`/topic/${topic}`); This does not work... we need the subscription objects
        subscriptions.value.find(target => target.topic === topic).subscription.unsubscribe();
        subscriptions.value = subscriptions.value.filter(target => target.topic !== topic);
    }

    function unsubscribeSingleElement<I>(index: I, type: string) {
        let topic: string = getSingleTopic<I>(index, type);
        // stompclient.unsubscribe(`/topic/${topic}`); This does not work, we need the subscription objects
        subscriptions.value.find(target => target.topic === topic).subscription.unsubscribe();
        subscriptions.value = subscriptions.value.filter(target => target !== topic);
    }

    function updateElement<I, P, D>(index: I, type: string, payloadConverter: D => P, update: D): string {
        const topic = getSingleTopic(index, type);
        const payload: P = payloadConverter(update);
        const origin = makeOrigin();
        stompclient.publish({
            headers: {
                "accDocsIdentity": selectSocketSession(storeApi.getState()),
            },
            destination: `/app/${topic}`,
            body: JSON.stringify({
                origin: origin,
                element: {
                    id: index[type],
                    ...payload
                },
                requestType: "UPDATE",
            }),
        });

        return origin;
    }

    function createDocument(document: DocumentData, binary: string): string {
        const topic = getMultiTopic({ document: -1 }, "document", null);
        const payload: DocumentSTOMPPayload = documentDataToPayload(document);
        const origin = makeOrigin();
        //TODO: find out why this message closes connection

        // stompclient.publish({
        //     destination: `/app/${topic}`,
        //     body: JSON.stringify({
        //         origin: origin,
        //         element: payload,
        //         binary: binary,
        //         requestType: "CREATE",
        //     }),
        // });

        console.log(`${process.env.REACT_APP_BACKEND_URL}/d`);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/d`, {
            fileName: document.fileName,
            content: binary,
            isPersistent: document.isPersistent
        }, {
            auth: {
                username: "demo@example.com",
                password: "demo",
            },
        });
    }

    //Only contentblocks can be created and deleted from the frontend

    function createContentBlock(index: PageIndex, initial: ContentBlockData): string {
        const topic = getMultiTopic<ContentBlockIndex>({ ...index, contentblock: -1 }, "contentblock", "page");
        const payload: ContentBlockSTOMPPayload = contentBlockDataToPayload(initial);
        const origin = makeOrigin();
        stompclient.publish({
            headers: {
                "accDocsIdentity": selectSocketSession(storeApi.getState()),
            },
            destination: `/app/${topic}`,
            body: JSON.stringify({
                origin: origin,
                element: payload,
                requestType: "CREATE",
            }),
        });

        return origin;
    }

    function deleteContentBlock(index: ContentBlockIndex): string {
        const topic = getSingleTopic<ContentBlockIndex>(index, "contentblock");
        const origin = makeOrigin();
        stompclient.publish({
            headers: {
                "accDocsIdentity": selectSocketSession(storeApi.getState()),
            },
            destination: `/app/${topic}`,
            body: JSON.stringify({
                origin: origin,
                requestType: "DELETE",
            }),
        });

        return origin;
    }

    function deleteAccDocsIssue(index: AccDocsIssueIndex): string {
        const topic = getSingleTopic<AccDocsIssueIndex>(index, "accDocsIssue");
        const origin= makeOrigin();
        stompclient.publish({
            headers: {
                "accDocsIdentity": selectSocketSession(storeApi.getState()),
            },
            destination: `/app/${topic}`,
            body: JSON.stringify({
                origin: origin,
                requestType: "Delete",
            }),
        });

        return origin;
    }

    //element listeners
    function listenTo<I, P, D>(type: string, index: I, payloadConverter: P => D,
                               elementSelector: (any, I) => D,
                               elementLoading: ({ index: I }) => PayloadAction<any>,
                               elementLoaded: ({ index: I, version: number, data: D }) => PayloadAction<any>,
                               elementUpdated: ({ index: I, version: number, data: D, origin: string }) => PayloadAction<any>,
                               elementError: ({ index: I, origin: string }) => PayloadAction<any>,
                               elementUnloaded: ({ index: I }) => PayloadAction<any>,
                               forceSync: boolean = true
    ) {
        subscribeSingleElement<I, P, D>(index, type, payloadConverter,
            (data, version, origin) => {
                if (!elementSelector(storeApi.getState(), index)) {
                    storeApi.dispatch(elementLoaded({ index: index, version: version, data: data }));
                } else {
                    storeApi.dispatch(elementUpdated({ index: index, version: version, data: data, origin: origin }));
                }
            },
            (message, origin) => {
                console.error(`Request ${origin} failed: ${message}`);
                storeApi.dispatch(elementError({ index: index, origin: origin }));
            },
            () => { //deleting the element should just lead to the element being unloaded on the frontend
                unsubscribeSingleElement<I>(index, type);
                storeApi.dispatch(elementUnloaded({ index: index }));
            }
        );

        //sync element
        if (forceSync) {
            storeApi.dispatch(elementLoading({ index: index }));
            stompclient.publish({
                headers: {
                    "accDocsIdentity": selectSocketSession(storeApi.getState()),
                },
                destination: `/app/${getSingleTopic<I>(index, type)}`,
                body: JSON.stringify({
                    origin: makeOrigin(),
                    requestType: "SYNC",
                }),
            });
        }
    }

    //connect in a few seconds
    setTimeout(connect, 2000);

    return action => {
        //messaging is only concerned with the 'data' actions for loading/unloading data
        if (action.type.startsWith('data')) {
            //if websocket is not connected, we just add the action to the queue
            if (!selectConnected(storeApi.getState()) && !selectSocketSession(storeApi.getState())) {
                pendingMessages.value = [ ...pendingMessages.value, action ];
                return; //dont enter switch statement
            }

            switch (action.type) {
                //manage document
                case "data/document/create":
                    const cdOrigin = makeOrigin(); //TODO: make sure its our document that has been created
                    subscribeMultipleElements<DocumentIndex, DocumentSTOMPPayload, DocumentData>({ document: -1 }, "document", null, documentPayloadToData,
                        (index) => {
                            //load document normally and unsubscribe from topic
                            storeApi.dispatch(loadDocument(index.document));
                            unsubscribeMultipleElements<DocumentIndex>({ document: -1 }, "document", null);
                        },
                        (index, version, data) => { /* Syncing documents over the multiple elements topic is not needed for now */ },
                        (message, origin) => {
                            console.error(`Request ${origin} failed: ${message}`);
                            if (origin === cdOrigin) {
                                unsubscribeMultipleElements<DocumentIndex>({ document: -1 }, "document", null);
                                console.error(`failed to create document`);
                            }
                        }
                    );
                    createDocument(action.payload.element, action.payload.binary); //send request to create document
                    break;

                case 'data/document/load':
                    //if a document is already loaded, see if a load/unload is necessary
                    if (selectDocumentLoaded(storeApi.getState())) {
                        if (documentIndexMatch(selectDocumentIndex(storeApi.getState()), action.payload.index)) {
                            break; //if the correct document is already loaded, dont do anythin
                            // no return statement here, to avoid missing sideeffects that come after the switch statement
                        } else { //if a different document is already loaded, unload it and try again
                            storeApi.dispatch(unloadDocument());
                            storeApi.dispatch(action);
                            break;
                        }
                    }

                    //document is not loaded, so we need to load it
                    const dlIndex = action.payload.index;
                    console.log("dlIndex: ", dlIndex);
                    listenTo<DocumentIndex, DocumentSTOMPPayload, DocumentData>("document", dlIndex, documentPayloadToData,
                        selectDocument, documentLoading, documentLoaded, documentUpdated, documentError, documentUnloaded);

                    //when loading a document, all page dummies are loaded aswell
                    subscribeMultipleElements<PageIndex, PageSTOMPPayload, PageData>({
                            ...dlIndex,
                            page: -1
                        }, "page", "document", pagePayloadToData,
                        (index) => {
                            listenTo<PageIndex, PageSTOMPPayload, PageData>("page", index, pagePayloadToData,
                                selectPage, pageLoading, pageLoaded, pageUpdated, pageError, pageUnloaded);
                        },
                        (index, version, data) => {
                            //a synced element needs to be subscribed to
                            storeApi.dispatch(pageLoading({ index: index }));
                            storeApi.dispatch(pageLoaded({ index: index, data: data, version: version }));
                            listenTo<PageIndex, PageSTOMPPayload, PageData>("page", index, pagePayloadToData,
                                selectPage, pageLoading, pageLoaded, pageUpdated, pageError, pageUnloaded, false);
                        },
                        (message, origin) => console.error(`Request ${origin} failed: ${message}`)
                    );

                    console.log("get multi Topic pages: ", getMultiTopic<PageIndex>({ ...dlIndex, page: -1 }, "page", "document"));
                    //sync pages
                    stompclient.publish({
                        headers: {
                            "accDocsIdentity": selectSocketSession(storeApi.getState()),
                        },
                        destination: `/app/${getMultiTopic<PageIndex>({ ...dlIndex, page: -1 }, "page", "document")}`,
                        body: JSON.stringify({
                            origin: makeOrigin(),
                            requestType: "SYNC",
                        }),
                    });

                    // also subscribe to accDocsIssues
                    subscribeMultipleElements<AccDocsIssueIndex, AccDocsIssueSTOMPPayload, AccDocsIssueData>({
                            ...dlIndex,
                            accDocsIssue: -1
                        }, "accDocsIssue", "document", accDocsIssuePayloadToData,
                        (index) => {
                            listenTo<AccDocsIssueIndex, AccDocsIssueSTOMPPayload, AccDocsIssueData>("accDocsIssue", index, accDocsIssuePayloadToData,
                                selectAccDocsIssue, accDocsIssueLoading, accDocsIssueLoaded, accDocsIssueUpdateConfirmed, accDocsIssueUpdateRejected, accDocsIssueUnloaded);
                        },
                        (index, version, data) => {
                            storeApi.dispatch(accDocsIssueLoading({index: index}));
                            storeApi.dispatch(accDocsIssueLoaded({index: index, version: version, data: data}));
                            listenTo<AccDocsIssueIndex, AccDocsIssueSTOMPPayload, AccDocsIssueData>("accDocsIssue", index, accDocsIssuePayloadToData,
                                selectAccDocsIssue, accDocsIssueLoading, accDocsIssueLoaded, accDocsIssueUpdateConfirmed, accDocsIssueUpdateRejected, accDocsIssueUnloaded, false);
                        },
                        (message, origin) => console.error(`Request ${origin} failed ${message}`)
                    );

                    console.log("getMultiTopic", getMultiTopic<AccDocsIssueIndex>({...dlIndex, accDocsIssue: -1}, "accDocsIssue", "document"));
                    //sync accDocsIssues
                    stompclient.publish({
                        headers: {
                            "accDocsIdentity": selectSocketSession(storeApi.getState()),
                        },
                        destination: `/app/${getMultiTopic<AccDocsIssueIndex>({...dlIndex, accDocsIssue: -1}, "accDocsIssue", "document")}`,
                        body: JSON.stringify({
                            origin:makeOrigin(),
                            requestType: "SYNC",
                        }),
                    });

                    // also subscribe to task updates
                    subscribeMultipleElements<TaskIndex, TaskSTOMPPayload, TaskData>({
                            ...dlIndex,
                            task: -1
                        }, "task", "document",
                        taskPayloadToData,
                        (index) => {
                            listenTo<TaskIndex, TaskSTOMPPayload, TaskData>("task", index, taskPayloadToData,
                                selectTask, taskLoading, taskLoaded, taskUpdated, taskError, taskUnloaded);
                        },
                        (index, version, data) => {
                            storeApi.dispatch(taskLoading({ index: index }));
                            storeApi.dispatch(taskLoaded({ index: index, version: version, data: data }));
                            listenTo<TaskIndex, TaskSTOMPPayload, TaskData>("task", index, taskPayloadToData,
                                selectTask, taskLoading, taskLoaded, taskUpdated, taskError, taskUnloaded, false);
                        },
                        (message, origin) => console.error(`Request ${origin} failed: ${message}`)
                    );

                    break;

                case "data/document/unload": //same thing as deleting it (from the view of the client side
                    const dulIndex = selectDocumentIndex(storeApi.getState());
                    const taskIndices = selectTaskIndices(storeApi.getState());
                    const dulpIndices: PageIndex[] = selectPageIndices(storeApi.getState());
                    unsubscribeSingleElement<DocumentIndex>(dulIndex, "document");

                    //unsubscribe from all pages
                    unsubscribeMultipleElements<PageIndex>(dulIndex, "page", "document");
                    dulpIndices.forEach(dulpIndex => unsubscribeSingleElement(dulpIndex, "page"));

                    // unsubscribe from all tasks
                    unsubscribeMultipleElements<TaskIndex>(dulIndex, "task", "document");
                    taskIndices.forEach(i => unsubscribeSingleElement(i, "task"));

                    storeApi.dispatch(documentUnloaded());
                    //TODO: unload all pages
                    break;

                case "data/document/metadata/update":
                    const dupIndex = selectDocumentIndex(storeApi.getState());
                    const documentData = selectDocument(storeApi.getState());
                    const duOrigin = updateElement<DocumentIndex, DocumentSTOMPPayload, DocumentData>(dupIndex, "document", documentDataToPayload, { ...documentData, ...action.payload });
                    storeApi.dispatch(documentUpdatePending({
                        origin: duOrigin,
                        data: { ...documentData, ...action.payload }
                    }));
                    break;

                //manage page
                case "data/page/load":
                    const plIndex = action.payload.index;

                    //page element is already loaded, but we still need to load all contentblocks and pdfelements
                    subscribeMultipleElements<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>({
                            ...plIndex,
                            contentblock: -1
                        }, "contentblock", "page", contentBlockPayloadToData,
                        (index, ownCause) => {
                            ownCause && storeApi.dispatch(setContentBlockEditing({ contentblock: index }));
                            listenTo<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>("contentblock", index, contentBlockPayloadToData,
                                selectContentBlock, contentblockLoading, contentblockLoaded, contentblockUpdated, contentblockError, contentblockUnloaded);
                        },
                        (index, version, data) => {
                            //when an element is newly created, we need to subscribe to it to keep track of it properly
                            storeApi.dispatch(contentblockLoading({ index: index }));
                            storeApi.dispatch(contentblockLoaded({ index: index, version: version, data: data }));
                            listenTo<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>("contentblock", index, contentBlockPayloadToData,
                                selectContentBlock, contentblockLoading, contentblockLoaded, contentblockUpdated, contentblockError, contentblockUnloaded, false);
                        },
                        (message, origin) => console.error(`Request ${origin} failed: ${message}`)
                    );

                    //sync contentblocks
                    stompclient.publish({
                        headers: {
                            "accDocsIdentity": selectSocketSession(storeApi.getState()),
                        },
                        destination: `/app/${getMultiTopic<ContentBlockIndex>({ ...plIndex, contentblock: -1 }, "contentblock", "page")}`,
                        body: JSON.stringify({
                            origin: makeOrigin(),
                            requestType: "SYNC",
                        }),
                    });

                    subscribeMultipleElements<PdfElementIndex, PdfElementSTOMPPayload, PdfElementData>({
                            ...plIndex,
                            pdfelement: -1
                        }, "pdfelement", "page", pdfElementDataToPayload,
                        (index) => {
                            listenTo<PdfElementIndex, PdfElementSTOMPPayload, PdfElementData>("pdfelement", index, pdfElementPayloadToData,
                                selectPdfElement, pdfElementLoading, pdfElementLoaded, pdfElementUpdated, pdfElementError, pdfElementUnloaded);
                        },
                        (index, version, data) => {
                            storeApi.dispatch(pdfElementLoading({ index: index }));
                            storeApi.dispatch(pdfElementLoaded({ index: index, version: version, data: data }));
                            listenTo<PdfElementIndex, PdfElementSTOMPPayload, PdfElementData>("pdfelement", index, pdfElementPayloadToData,
                                selectPdfElement, pdfElementLoading, pdfElementLoaded, pdfElementUpdated, pdfElementError, pdfElementUnloaded, false);
                        },
                        (message, origin) => console.error(`Request ${origin} failed: ${message}`)
                    );

                    //sync pdfelements
                    stompclient.publish({
                        headers: {
                            "accDocsIdentity": selectSocketSession(storeApi.getState()),
                        },
                        destination: `/app/${getMultiTopic<PdfElementIndex>({ ...plIndex, pdfelement: -1 }, "pdfelement", "page")}`,
                        body: JSON.stringify({
                            origin: makeOrigin(),
                            requestType: "SYNC",
                        }),
                    });
                    break;

                case "data/page/unload":
                    const pulIndex = action.payload.index;

                    //unsubscribe from multi and single topics
                    unsubscribeMultipleElements(pulIndex, "contentblock", "page");
                    unsubscribeMultipleElements(pulIndex, "pdfelement", "page");

                    selectContentBlockIndicesFromPage(storeApi.getState(), pulIndex).forEach(index => unsubscribeSingleElement(index, "contentblock"));
                    selectPdfElementIndicesFromPage(storeApi.getState(), pulIndex).forEach(index => unsubscribeSingleElement(index, "pdfelement"));

                    //unload all elements from this page and unsubscribe from their topics
                    storeApi.dispatch(contentblockUnloadAll({ page: pulIndex }));
                    storeApi.dispatch(pdfElementUnloadAll({ page: pulIndex }));
                    break;

                //manage contentblocks
                case "data/block/create":
                    createContentBlock(action.payload.index, action.payload.initial);
                    break;

                case "data/block/bounds/update":
                    const bubOrigin = updateElement<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>(action.payload.index, "contentblock", contentBlockDataToPayload, {
                        ...selectContentBlock(storeApi.getState(), action.payload.index),
                        ...action.payload.bounds
                    });
                    storeApi.dispatch(contentblockUpdatePending({
                        origin: bubOrigin,
                        index: action.payload.index,
                        data: action.payload.bounds
                    }));
                    break;

                case "data/block/details/update":
                    const budOrigin = updateElement<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>(action.payload.index, "contentblock", contentBlockDataToPayload, {
                        ...selectContentBlock(storeApi.getState(), action.payload.index),
                        details: action.payload.details
                    });
                    storeApi.dispatch(contentblockUpdatePending({
                        origin: budOrigin,
                        index: action.payload.index,
                        data: { details: action.payload.details }
                    }));
                    break;

                case "data/block/ordinal/update":
                    const buoOrigin = updateElement<ContentBlockIndex, ContentBlockSTOMPPayload, ContentBlockData>(action.payload.index, "contentblock", contentBlockDataToPayload, {
                        ...selectContentBlock(storeApi.getState(), action.payload.index),
                        ordinal: action.payload.ordinal
                    });
                    storeApi.dispatch(contentblockUpdatePending({
                        origin: buoOrigin,
                        index: action.payload.index,
                        data: { ordinal: action.payload.ordinal }
                    }));
                    break;

                case "data/block/delete":
                    deleteContentBlock(action.payload.index);
                    break;

                //manage pdfelement
                case "data/pdfelement/ordinal/update":
                    const puoOrigin = updateElement<PdfElementIndex, PdfElementSTOMPPayload, PdfElementData>(action.payload.index, "pdfelement", pdfElementDataToPayload, {
                        ...selectPdfElement(storeApi.getState(), action.payload.index),
                        ordinal: action.payload.ordinal
                    });
                    storeApi.dispatch(pdfElementUpdatePending({
                        origin: puoOrigin,
                        index: action.payload.index,
                        data: { ordinal: action.payload.ordinal }
                    }));
                    break;

                case "data/accDocsIssue/delete":
                    deleteAccDocsIssue(action.payload.index);
                    break;

                case "data/accDocsIssue/update":
                    console.log("inside middleware: action -> ", action);
                    console.log("action.payload.index: ", action.payload.index);
                    const auOrigin = updateElement<AccDocsIssueIndex, AccDocsIssueSTOMPPayload, AccDocsIssueData>(action.payload.index, "accDocsIssue", accDocsIssueDataToPayload, {
                        ...selectAccDocsIssue(storeApi.getState(), action.payload.index),
                        done: action.payload.done,
                        //issueNumber: action.payload.issueNumber,
                    });
                    console.log("auOrigin: ", auOrigin);
                    storeApi.dispatch(accDocsIssueUpdateUnconfirmed({
                        origin: auOrigin,
                        index: action.payload.index,
                        //issueNumber: action.payload.issue,
                        data: { done: action.payload.done },
                    }));
                    break;

            }
        } else {
            next(action);
        }
    };
};
