// @flow

// source: https://www.andiamo.co.uk/resources/iso-language-codes/
export type LanguageCode = {
    language: string,
    languageCode: string
  }
  
  export const LanguageCodes : [LanguageCode] =
  [ 
    {
      "language": "None",
      "languageCode": ""
    },
    {
      "language": "English",
      "languageCode": "en"
    },
    {
      "language": "German (Standard)",
      "languageCode": "de"
    },
    {
      "language": "Spanish (Spain)",
      "languageCode": "es"
    },
    {
      "language": "French (Standard)",
      "languageCode": "fr"
    },
    {
      "language": "Afrikaans",
      "languageCode": "af"
    },
    {
      "language": "Albanian",
      "languageCode": "sq"
    },
    {
      "language": "Arabic (Algeria)",
      "languageCode": "ar-dz"
    },
    {
      "language": "Arabic (Bahrain)",
      "languageCode": "ar-bh"
    },
    {
      "language": "Arabic (Egypt)",
      "languageCode": "ar-eg"
    },
    {
      "language": "Arabic (Iraq)",
      "languageCode": "ar-iq"
    },
    {
      "language": "Arabic (Jordan)",
      "languageCode": "ar-jo"
    },
    {
      "language": "Arabic (Kuwait)",
      "languageCode": "ar-kw"
    },
    {
      "language": "Arabic (Lebanon)",
      "languageCode": "ar-lb"
    },
    {
      "language": "Arabic (Libya)",
      "languageCode": "ar-ly"
    },
    {
      "language": "Arabic (Morocco)",
      "languageCode": "ar-ma"
    },
    {
      "language": "Arabic (Oman)",
      "languageCode": "ar-om"
    },
    {
      "language": "Arabic (Qatar)",
      "languageCode": "ar-qa"
    },
    {
      "language": "Arabic (Saudi Arabia)",
      "languageCode": "ar-sa"
    },
    {
      "language": "Arabic (Syria)",
      "languageCode": "ar-sy"
    },
    {
      "language": "Arabic (Tunisia)",
      "languageCode": "ar-tn"
    },
    {
      "language": "Arabic (U.A.E.)",
      "languageCode": "ar-ae"
    },
    {
      "language": "Arabic (Yemen)",
      "languageCode": "ar-ye"
    },
    {
      "language": "Basque",
      "languageCode": "eu"
    },
    {
      "language": "Belarusian",
      "languageCode": "be"
    },
    {
      "language": "Bulgarian",
      "languageCode": "bg"
    },
    {
      "language": "Catalan",
      "languageCode": "ca"
    },
    {
      "language": "Chinese (Hong Kong)",
      "languageCode": "zh-hk"
    },
    {
      "language": "Chinese (PRC)",
      "languageCode": "zh-cn"
    },
    {
      "language": "Chinese (Singapore)",
      "languageCode": "zh-sg"
    },
    {
      "language": "Chinese (Taiwan)",
      "languageCode": "zh-tw"
    },
    {
      "language": "Croatian",
      "languageCode": "hr"
    },
    {
      "language": "Czech",
      "languageCode": "cs"
    },
    {
      "language": "Danish",
      "languageCode": "da"
    },
    {
      "language": "Dutch (Belgium)",
      "languageCode": "nl-be"
    },
    {
      "language": "Dutch (Standard)",
      "languageCode": "nl"
    },
    {
      "language": "English (Australia)",
      "languageCode": "en-au"
    },
    {
      "language": "English (Belize)",
      "languageCode": "en-bz"
    },
    {
      "language": "English (Canada)",
      "languageCode": "en-ca"
    },
    {
      "language": "English (Ireland)",
      "languageCode": "en-ie"
    },
    {
      "language": "English (Jamaica)",
      "languageCode": "en-jm"
    },
    {
      "language": "English (New Zealand)",
      "languageCode": "en-nz"
    },
    {
      "language": "English (South Africa)",
      "languageCode": "en-za"
    },
    {
      "language": "English (Trinidad)",
      "languageCode": "en-tt"
    },
    {
      "language": "English (United Kingdom)",
      "languageCode": "en-gb"
    },
    {
      "language": "English (United States)",
      "languageCode": "en-us"
    },
    {
      "language": "Estonian",
      "languageCode": "et"
    },
    {
      "language": "Faeroese",
      "languageCode": "fo"
    },
    {
      "language": "Farsi",
      "languageCode": "fa"
    },
    {
      "language": "Finnish",
      "languageCode": "fi"
    },
    {
      "language": "French (Belgium)",
      "languageCode": "fr-be"
    },
    {
      "language": "French (Canada)",
      "languageCode": "fr-ca"
    },
    {
      "language": "French (Luxembourg)",
      "languageCode": "fr-lu"
    },
    {
      "language": "French (Switzerland)",
      "languageCode": "fr-ch"
    },
    {
      "language": "Gaelic (Scotland)",
      "languageCode": "gd"
    },
    {
      "language": "German (Austria)",
      "languageCode": "de-at"
    },
    {
      "language": "German (Liechtenstein)",
      "languageCode": "de-li"
    },
    {
      "language": "German (Luxembourg)",
      "languageCode": "de-lu"
    },
    {
      "language": "German (Switzerland)",
      "languageCode": "de-ch"
    },
    {
      "language": "Greek",
      "languageCode": "el"
    },
    {
      "language": "Hebrew",
      "languageCode": "he"
    },
    {
      "language": "Hindi",
      "languageCode": "hi"
    },
    {
      "language": "Hungarian",
      "languageCode": "hu"
    },
    {
      "language": "Icelandic",
      "languageCode": "is"
    },
    {
      "language": "Indonesian",
      "languageCode": "id"
    },
    {
      "language": "Irish",
      "languageCode": "ga"
    },
    {
      "language": "Italian (Standard)",
      "languageCode": "it"
    },
    {
      "language": "Italian (Switzerland)",
      "languageCode": "it-ch"
    },
    {
      "language": "Japanese",
      "languageCode": "ja"
    },
    {
      "language": "Korean",
      "languageCode": "ko"
    },
    {
      "language": "Korean (Johab)",
      "languageCode": "ko"
    },
    {
      "language": "Kurdish",
      "languageCode": "ku"
    },
    {
      "language": "Latvian",
      "languageCode": "lv"
    },
    {
      "language": "Lithuanian",
      "languageCode": "lt"
    },
    {
      "language": "Macedonian (FYROM)",
      "languageCode": "mk"
    },
    {
      "language": "Malayalam",
      "languageCode": "ml"
    },
    {
      "language": "Malaysian",
      "languageCode": "ms"
    },
    {
      "language": "Maltese",
      "languageCode": "mt"
    },
    {
      "language": "Norwegian",
      "languageCode": "no"
    },
    {
      "language": "Norwegian (Bokmål)",
      "languageCode": "nb"
    },
    {
      "language": "Norwegian (Nynorsk)",
      "languageCode": "nn"
    },
    {
      "language": "Polish",
      "languageCode": "pl"
    },
    {
      "language": "Portuguese (Brazil)",
      "languageCode": "pt-br"
    },
    {
      "language": "Portuguese (Portugal)",
      "languageCode": "pt"
    },
    {
      "language": "Punjabi",
      "languageCode": "pa"
    },
    {
      "language": "Rhaeto-Romanic",
      "languageCode": "rm"
    },
    {
      "language": "Romanian",
      "languageCode": "ro"
    },
    {
      "language": "Romanian (Republic of Moldova)",
      "languageCode": "ro-md"
    },
    {
      "language": "Russian",
      "languageCode": "ru"
    },
    {
      "language": "Russian (Republic of Moldova)",
      "languageCode": "ru-md"
    },
    {
      "language": "Serbian",
      "languageCode": "sr"
    },
    {
      "language": "Slovak",
      "languageCode": "sk"
    },
    {
      "language": "Slovenian",
      "languageCode": "sl"
    },
    {
      "language": "Sorbian",
      "languageCode": "sb"
    },
    {
      "language": "Spanish (Argentina)",
      "languageCode": "es-ar"
    },
    {
      "language": "Spanish (Bolivia)",
      "languageCode": "es-bo"
    },
    {
      "language": "Spanish (Chile)",
      "languageCode": "es-cl"
    },
    {
      "language": "Spanish (Colombia)",
      "languageCode": "es-co"
    },
    {
      "language": "Spanish (Costa Rica)",
      "languageCode": "es-cr"
    },
    {
      "language": "Spanish (Dominican Republic)",
      "languageCode": "es-do"
    },
    {
      "language": "Spanish (Ecuador)",
      "languageCode": "es-ec"
    },
    {
      "language": "Spanish (El Salvador)",
      "languageCode": "es-sv"
    },
    {
      "language": "Spanish (Guatemala)",
      "languageCode": "es-gt"
    },
    {
      "language": "Spanish (Honduras)",
      "languageCode": "es-hn"
    },
    {
      "language": "Spanish (Mexico)",
      "languageCode": "es-mx"
    },
    {
      "language": "Spanish (Nicaragua)",
      "languageCode": "es-ni"
    },
    {
      "language": "Spanish (Panama)",
      "languageCode": "es-pa"
    },
    {
      "language": "Spanish (Paraguay)",
      "languageCode": "es-py"
    },
    {
      "language": "Spanish (Peru)",
      "languageCode": "es-pe"
    },
    {
      "language": "Spanish (Puerto Rico)",
      "languageCode": "es-pr"
    },
    {
      "language": "Spanish (Uruguay)",
      "languageCode": "es-uy"
    },
    {
      "language": "Spanish (Venezuela)",
      "languageCode": "es-ve"
    },
    {
      "language": "Swedish",
      "languageCode": "sv"
    },
    {
      "language": "Swedish (Finland)",
      "languageCode": "sv-fi"
    },
    {
      "language": "Thai",
      "languageCode": "th"
    },
    {
      "language": "Tsonga",
      "languageCode": "ts"
    },
    {
      "language": "Tswana",
      "languageCode": "tn"
    },
    {
      "language": "Turkish",
      "languageCode": "tr"
    },
    {
      "language": "Ukrainian",
      "languageCode": "ua"
    },
    {
      "language": "Urdu",
      "languageCode": "ur"
    },
    {
      "language": "Venda",
      "languageCode": "ve"
    },
    {
      "language": "Vietnamese",
      "languageCode": "vi"
    },
    {
      "language": "Welsh",
      "languageCode": "cy"
    },
    {
      "language": "Xhosa",
      "languageCode": "xh"
    },
    {
      "language": "Yiddish",
      "languageCode": "ji"
    },
    {
      "language": "Zulu",
      "languageCode": "zu"
    }
   ]