// @flow

import ReactJoyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { selectIsTutorialRunning, setRunTutorial } from "../../../redux/slice/EditorSlice";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@material-ui/core";

/**
 * Object used by the Tutorial component to prepare/cleanup and show a tutorial message
 */
export type TutorialStep = (Step & {
    onBack: (Dispatch<AnyAction>, () => void) => void,
    onNext: (Dispatch<AnyAction>, () => void) => void
});

/**
 * Props for the tutorial tooltip
 */
type TutorialTooltipProps = {
    continuous: boolean,
    index: number,
    isLastStep: number,
    size: number,
    step: TutorialStep,
    backProps: any,
    closeProps: any,
    primaryProps: any,
    skipProps: any,
    tooltipProps: any,
};

/**
 * Custom tooltip
 */
function TutorialTooltip(props: TutorialTooltipProps) {
    const dispatch = useDispatch();

    //TODO: catch next steps and delay them appropriately
    const actualPrimaryProps = {...props.primaryProps};
    delete actualPrimaryProps.onClick;

    const actualBackProps = {...props.backProps};
    delete actualBackProps.onClick;

    //display loading symbol while waiting for next step
    const [ showLoadingIcon, setShowLoadingIcon ] = useState(false);
    const onClickBack = useCallback((e) => {
        if (!!props.step.onBack) {
            setShowLoadingIcon(true);
            props.step.onBack(dispatch, () => {
                setShowLoadingIcon(false);
                props.backProps.onClick(e);
            });
        } else {  //if no onBack method is specified, just execute the back action as normal
            props.backProps.onClick(e);
        }
    }, [ props.step ]);

    const onClickNext = useCallback((e) => {
        if (!!props.step.onNext) {
            setShowLoadingIcon(true);
            props.step.onNext(dispatch, () => {
                setShowLoadingIcon(false);
                props.primaryProps.onClick(e);
            });
        } else {  //if no onBack method is specified, just execute the back action as normal
            props.primaryProps.onClick(e);
        }
    }, [ props.step ]);

    return (
        <Card {...props.tooltipProps} style={{maxWidth: "24rem"}}>
            {props.step.title && (
                <CardHeader style={{backgroundColor: "#4064AD", color: "white"}} title={props.step.title}/>
            )}
            <CardContent>
                {props.step.content && (
                    <Typography variant={"body2"} component={"span"}>
                        {props.step.content}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                {props.skipProps && (
                    <Button disabled={showLoadingIcon} {...props.skipProps}>
                        Skip
                    </Button>
                )}
                {/* Align back and next to the right */}
                <div style={{flexGrow: 1}}/>
                {props.index > 0 && (
                    <Button disabled={showLoadingIcon} variant={"outlined"} {...actualBackProps} onClick={onClickBack}>
                        Back
                    </Button>
                )}
                {props.continuous ? (
                    <Button disabled={showLoadingIcon} variant={"contained"} color={"primary"} {...actualPrimaryProps} onClick={onClickNext}>
                        {props.index >= props.size - 1 ? "Finish" : "Next"}
                    </Button>
                ):(
                    <Button disabled={showLoadingIcon} {...props.closeProps}>
                        Close
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}

type BaseTutorialProps = {
    tutorial: string,
    prepare: (Dispatch<AnyAction>, () => void) => void,
    cleanup: Dispatch<AnyAction> => void,
    steps: TutorialStep[];
};

/**
 * Takes care of running the BaseTutorial when appropriate
 * @returns {*}
 * @constructor
 */
export function Tutorial(props: BaseTutorialProps) {
    const dispatch = useDispatch();
    const tutorialRunning = useSelector(state => selectIsTutorialRunning(state, props.tutorial));
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ preparing, setPreparing ] = useState(false);

    const handleJoyrideCallback = useCallback((data: CallBackProps) => {
        //handle forward/backward events
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
            //prepare next step
            const nextStep = data.index + (data.action === ACTIONS.PREV ? -1 : 1);
            setCurrentStep(nextStep);
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
            dispatch(setRunTutorial({ tutorial: null })); //if tour finished, set running tutorial to false
            setCurrentStep(0);

            !!props.cleanup && props.cleanup(dispatch);
            //TODO: remember that this tutorial was run
        }
    }, [ props.steps ]);

    //run prepare step of the first step when the tutorial opens
    useEffect(() => {
        if (tutorialRunning) { //tutorial starts
            setPreparing(true);
            if (!!props.prepare) {
                props.prepare(dispatch, () => {
                    setCurrentStep(0);
                    setPreparing(false);
                });
            } else {
                setCurrentStep(0);
                setPreparing(false);
            }
        }
    }, [ tutorialRunning ]);

    return (
        <ReactJoyride
            callback={handleJoyrideCallback}
            run={tutorialRunning && !preparing}
            steps={props.steps}
            stepIndex={currentStep}

            tooltipComponent={TutorialTooltip}

            disableCloseOnEsc
            disableOverlayClose
            continuous

            showProgress
            showSkipButton

            disableScrolling
            disableScrollParentFix
        />
    );
}
