// @flow

import { useCallback, useMemo } from "react";
import type { TutorialStep } from "./Tutorial";
import { Tutorial } from "./Tutorial";
import {
    TargetHotzone,
    TargetMetadataTray,
    TargetPdfArea,
    TargetProgressTray,
    TargetDownloadTray,
    TargetReadingOrderTray,
    TargetSideBarExpand,
    TargetSideBarTabs,
    TargetTutorialsTray
} from "./targets";
import { setContentBlockEditing, setSideBarExpanded, setSideBarTab } from "../../../redux/slice/EditorSlice";
import { Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "@material-ui/icons";
import styles from "./BasicEditorTutorial.module.css";
import { SideBarTabReadingOrderConfig } from "../sidebar/SideBarTabReadingOrder";
import { SideBarTabMetadataConfig } from "../sidebar/SideBarTabMetadata";
import { SideBarTabDownloadConfig } from "../sidebar/SideBarTabDownload";
import { SideBarTabTutorialsConfig } from "../sidebar/SideBarTabTutorials";
import {useTranslation} from "react-i18next";

/**
 * The id used in stateManagement to refer to this tutorial
 * @type {string}
 */
export const BasicEditorTutorialId = "editor-basic";

/**
 * A component that shows the basic editor tutorial when necessary
 * @constructor
 */
export function BasicEditorTutorial() {
    //tutorial steps should not be recreated every render
    const {t, i18n} = useTranslation();
    const steps = useMemo<TutorialStep[]>(() => [
        {
            target: TargetPdfArea.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.basics')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.bas1')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetPdfArea.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.movingPage')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.mov1')}<span className={styles.inputKey}>
                        {t('tutorials.basicEditor.mmb')}
                    </span> {t('tutorials.basicEditor.mov2')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetHotzone.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.pagination')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.pag1')} <ChevronLeft className={styles.inlineIcon}/> {t('tutorials.basicEditor.pag2')}
                    <ChevronRight className={styles.inlineIcon}/> {t('tutorials.basicEditor.pag3')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "top",
        },
        {
            target: TargetHotzone.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.zooming')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.zoom1')}  <ZoomIn className={styles.inlineIcon}/>  {t('tutorials.basicEditor.zoom2')}
                    <ZoomOut className={styles.inlineIcon}/>  {t('tutorials.basicEditor.zoom3')} <span className={styles.inputKey}>strg</span>
                    {t('tutorials.basicEditor.zoom4')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "top",
            onNext: (dispatch, start) => {
                dispatch(setSideBarExpanded({ expanded: true }));
                window.setTimeout(() => start(), 500); //start step after animation is finished
            },
        },
        {
            target: TargetSideBarTabs.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.openingSidebar')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.open1')} <ChevronLeft className={styles.inlineIcon}/> {t('tutorials.basicEditor.open2')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left-start",
            onBack: (dispatch, start) => {
                dispatch(setSideBarExpanded({ expanded: false }));
                start();
            },
            onNext: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabReadingOrderConfig.id }));
                start();
            }
        },
        {
            target: TargetReadingOrderTray.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.readingOrder')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.ro1')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onNext: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabMetadataConfig.id }));
                start();
            },
        },
        {
            target: TargetMetadataTray.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.editingMetadata')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.em1')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabReadingOrderConfig.id }));
                start();
            },
            onNext: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabDownloadConfig.id }));
                start();
            },
        },
        {
            target: TargetDownloadTray.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.download')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.download1')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabMetadataConfig.id }));
                start();
            },
            onNext: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabTutorialsConfig.id }));
                start();
            },
        },
        {
            target: TargetTutorialsTray.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.tutorials')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.tut1')} {/* TODO: remove once other tutorials are implemented */}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabDownloadConfig.id }));
                start();
            },
        },
        {
            target: TargetSideBarExpand.target(),
            title: <Typography variant={"h6"}>{t('tutorials.basicEditor.closingSidebar')}</Typography>,
            content: (
                <Typography>
                    {t('tutorials.basicEditor.closing1')} <ChevronRight className={styles.inlineIcon}/> {t('tutorials.basicEditor.closing2')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarTab({ tab: SideBarTabTutorialsConfig.id }));
                start();
            },
        },
    ], []);

    const prepare = useCallback((dispatch, start) => {
        dispatch(setSideBarExpanded({ expanded: false }));
        dispatch(setContentBlockEditing({ contentblock: null }));
        start();
    }, []);

    const cleanup = useCallback(dispatch => {
        dispatch(setSideBarExpanded({ expanded: false }));
    }, []);

    return <Tutorial tutorial={BasicEditorTutorialId} steps={steps} prepare={prepare} cleanup={cleanup}/>;
}
