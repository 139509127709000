// @flow

import type { SideBarTabConfig } from "./SideBarDrawer";
import {
    Description,
    Help,
    HelpOutline,
    HelpSharp,
    HelpRounded,
    QuestionAnswer,
    HelpOutlineRounded, HelpOutlineOutlined, HelpOutlineTwoTone, HelpTwoTone, Info, HelpCenter
} from "@material-ui/icons";
import styles from "./SideBarTabMetadata.module.css";
import {Chip, TextField, Tooltip, Typography} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useEditableMetadata } from "../../../redux/hook/useMetadata";
import React, { useState } from "react";
import { TargetMetadataTray } from "../tutorials/targets";
import { LanguageCodes } from "../../../common/constants/LanguageCodesIETF";
import {useTranslation} from "react-i18next";
import { HtmlTooltip } from "../../../common/element/tooltips/HtmlTooltip";
import { MetadataFrame } from "../../../common/element/metadata/MetadataFrame";

type AddKeywordChipProps = {
    onAddKeyword: string => void,
};

function AddKeywordChip(props: AddKeywordChipProps) {
    const { onAddKeyword } = props;

    const [ editing, setEditing ] = useState<boolean>(false);
    const [ currentKeyword, setCurrentKeyword ] = useState<string>("");

    const stopEditing = (keyword: string, save: boolean) => {
        if (save && currentKeyword.trim().length > 0) {
            onAddKeyword(keyword);
        }

        setEditing(false);
        setCurrentKeyword("");
    }

    return (
        <Chip
            label={editing ? (
                <input
                    value={currentKeyword}
                    onChange={e => setCurrentKeyword(e.target.value)}

                    autoFocus
                    onBlur={() => stopEditing(currentKeyword, true)}
                    onKeyPress={e => e.key === "Enter" && stopEditing(currentKeyword, true)}
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                    }}
                />
            ) : "+"}
            onClick={() => {
                if (!editing) {
                    setEditing(true);
                }
            }}
        />
    )
}

/**
 * Renders editable textfields to allow the user to enter metadata for the document
 * @constructor
 */
export function SideBarTabMetadata() {
    const metadata = useEditableMetadata();

    const initialLanguageIndex = LanguageCodes.indexOf(LanguageCodes.find(x => x.languageCode === metadata.language));
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.container}>
            <MetadataFrame/>
        </div>
    );
}


/**
 * Configuration for the metadata tab in the sidebar
 * @type {{}}
 */
export const SideBarTabMetadataConfig: SideBarTabConfig = {
    id: "metadata",
    name: "Metadata",
    trayTarget: TargetMetadataTray.className(),
    icon: props => <Description {...props}/>,
    render: SideBarTabMetadata,
};
