// @flow

import type { ContentBlockTypeConstants } from "./ContentBlockConstants";
import { allNotNone } from "./ContentBlockConstants";
import styles from "./BlockTypeDropDown.module.css";
import { IconButton, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

type BlockTypeDropDownProps = {
    selectedType: ContentBlockTypeConstants,
    onChangeType: ContentBlockTypeConstants => void,
};

export function BlockTypeDropDown(props: BlockTypeDropDownProps) {
    return (
            <div
                className={styles.container}
                style={{
                    backgroundColor: props.selectedType.color,
                }}
            >
                <props.selectedType.icon/>
                <IconButton size={"small"}>
                    <ExpandMore/>
                </IconButton>

                {/* Selector column */}
                <span
                    className={styles.selector}
                >
                    {allNotNone().map(bt => (
                        <div
                            className={styles.selectorItem}
                            style={{
                                backgroundColor: bt.color,
                            }}
                            onClick={() => props.onChangeType(bt)}
                        >
                            <bt.icon/>
                            <Typography>
                                {bt.name}
                            </Typography>
                        </div>
                    ))}
                </span>
            </div>
    );
}
