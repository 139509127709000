// @flow

import {forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useState} from "react";
import { useSelector } from "react-redux";
import { selectContentBlockEditing } from "../../../redux/slice/EditorSlice";
import { useEditableContentBlock } from "../../../redux/hook/useContentBlock";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Input,
    MenuItem,
    TextField, Tooltip,
    Typography
} from "@material-ui/core";
import styles from "./ContentBlockEditorTable.module.css";
import {Switch} from "react-router";
import {useTranslation} from "react-i18next";
import {usePropertyShadow} from "../../../redux/hook/usePropertyShadow";

function isValidJson(str) {
    try {
        JSON.parse(str);
    } catch(e) {
        console.log("not valid json");
        return false;
    }
    return true;
}

// @todo: ATTENTION! the implementation of the table management is very grug-brained. it's simply a stringified JSON inside the alt-text. It most definitely needs a better solution down the road.
export const ContentBlockEditorTable = forwardRef((props, ref) => {
    /*
        Fetch data
     */
    const contentBlockIndex = useSelector(selectContentBlockEditing);
    const contentBlock = useEditableContentBlock(contentBlockIndex);
    const {t} = useTranslation();
    const [nRows, setNRows] = useState(2);
    const [nCols, setNCols] = useState(2);
    const [contentMode, setContentMode] = useState(false);
    //const baseItem = {content: "", heading: false};
    const baseTable = {info:{rows: 2, cols: 2}, body: [[{content: "", heading: false}, {content: "", heading: false}], [{content: "", heading: false}, {content: "", heading: false}]]};
    const [table, setTable] = useState(isValidJson(contentBlock.details.alternativeText)? JSON.parse(contentBlock.details.alternativeText) : baseTable);
    const [ignored, forceUpdate] = useReducer(x => x+1, 0);
    //contentBlock.details.alternativeText = JSON.stringify(table);
    console.log("table: ", table);

    useImperativeHandle(ref, () => ({
        //save: () => contentBlock.details.alternativeText = altText,
        save: () => {
            contentBlock.details.alternativeText = JSON.stringify(table);
            contentBlock.details.segmentationConfidence = 1; // @todo: we are setting the confidence to one for now. this might not necessarily always be correct
        }
    }), [table, contentBlock]);

    const style1 = {
        backgroundColor:  "#D2691EFF",
        width: "15rem",
        height: "4rem",
        justifyContent: "flex-start"
    };
    const style2 = {
        backgroundColor: "white",
        width: "15rem",
        height: "4rem",
        justifyContent: "flex-start"
    };

    const changeTableLayout = (rows, cols) => {
        console.log("changing table layout: ", rows, cols);
        let tbl = Array(rows);
        console.log("tbl: ", tbl);
        for (let i = 0; i < rows; i++) {
            tbl[i] = Array(cols);
            for(let j = 0; j < cols; j++) {
                tbl[i][j] = {content: "", heading: false};
            }
        }
        const t = {info: {rows: rows, cols: cols}, body: tbl};
        setTable(t);
        //contentBlock.details.alternativeText = JSON.stringify(t);
        console.log("table after the layout has been changed: ", table);
    };

    const changeNumRows = (e) => {
        console.log("changing numRows: ", e.target.value);
        const newNRows = parseInt(e.target.value);
        if (newNRows <= 1 || isNaN(newNRows) || newNRows === Infinity) return;
        let tmp = table;
        tmp.info.rows = newNRows;
        setTable(tmp);
        setNRows(newNRows);
        changeTableLayout(newNRows, nCols);
    };

    const changeNumCols = (e) => {
        console.log("changing numCols: ", e.target.value);
        const newNCols = parseInt(e.target.value);
        if (newNCols <= 1 || isNaN(newNCols) || newNCols === Infinity) return;
        let tmp = table;
        tmp.info.cols = newNCols;
        setTable(tmp);
        setNCols(newNCols);
        changeTableLayout(nRows, newNCols);
    };

    const toggleContentMode = () => {
        setContentMode(!contentMode);
    }

    const toggleHeaderCell = (row, col) => {
        console.log("clickediclick");
        console.log("toggleHeaderCell", row, col);
        if (contentMode) return;
        const headingState = table.body[row][col].heading;
        let tmp = table;
        tmp.body[row][col].heading = !headingState;
        setTable(tmp);
        forceUpdate();
        //setTable({...tmp});
    };

    const changeCellContent = (event, row, col) => {
        let tmp = table;
        tmp.body[row][col].content = event.target.value;
        setTable(tmp);
    }

    const makeRow = (row) => {
        console.log("making row: ", row, table, nCols);
        return <div id={row} style={{display: "flex", flexDirection: "row", justifyContent:"flex-start"}}>
            {Array.from(Array(table.info.cols), (e, col) => {
                console.log("col: ", col);
                console.log("tbody: ", table.body);
                console.log("tbody[row]: ", table.body[row]);
                const id = row.toString() + col.toString();
                const val = table.body[row][col].content;
                console.log("val: ", val);
                return <div >
                    <TextField
                        onPointerDown={() => toggleHeaderCell(row, col)}
                        variant={"outlined"}
                        id={id}
                        //label={table.body[row][col].content}
                        disabled={!contentMode}

                        style={table.body[row][col].heading ? style1 : style2}
                        //onChange={(event) => changeCellContent(event, row, col)/* called every keystroke */}
                        onBlur={(event) => changeCellContent(event, row, col)/* only when leaving the cell */}
                        defaultValue={val}
                        //value={val}
                    />
                </div>
            })}
        </div>
    };

    const makeTable = () => {
        console.log("making table: ", table, nRows);
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            {Array.from(Array(table.info.rows), (e, row) => {
                console.log("e, row: ", e , row);
                return makeRow(row);
            })}
        </div>
    }


    return (
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", height:"85%"}}>
            <div style={{display: "flex", flexDirection: "row", marginTop: ".5rem", marginBottom: ".75rem", borderBottom: "1px solid"}}>
                <TextField
                    label={t('editors.tableEditor.numRows')}
                    variant={"outlined"}
                    margin={"dense"}
                    type={"number"}
                    onChange={changeNumRows}
                    value={table.info.rows}
                />
                <TextField
                    label={t('editors.tableEditor.numCols')}
                    variant={"outlined"}
                    margin={"dense"}
                    type={"number"}
                    value={table.info.cols}
                    onChange={changeNumCols}
                />
                <Tooltip title={t('tutorials.tableAltTexts.editModeTooltip')} enterDelay={1000}>
                    <FormControlLabel
                        control={<Checkbox checked={contentMode} onChange={toggleContentMode}/>}
                        style={{marginLeft: "1rem"}}
                        label={t('tutorials.tableAltTexts.editMode')}
                        lablePlacement={"start"}
                    />
                </Tooltip>
            </div>
            <div
                style={{overflow: "auto", width: "95%", height: "95%", alignItems:"flex-start", display:"flex"}}>
                {makeTable()}
            </div>
        </div>
    );
});


function TableDummy(props) {
    const foo = props;

    return (
        <div>
            <Typography>Please manually enter the table setup, as our AI is not yet capable of automatically
                 recognising and extracting the contents. Start by selecting rows and columns.</Typography>
        </div>
    )
}
