// @flow

import type { ContentBlockData } from "../../../redux/data/contentblock/ContentBlockTypes";
import {
    ChemicalFormulaConstants,
    HeadingConstants,
    ImageConstants,
    MathExpressionConstants,
    TableConstants,
    TextConstants
} from "./ContentBlockConstants";
import { useSelector } from "react-redux";
import { selectCurrentPageBounds, selectCurrentZoom } from "../../../redux/slice/EditorSlice";
import styles from "./ContentBlockSkeleton.module.css";
import { alpha } from "@material-ui/core";
import { ReactComponent as ImageBlockLogo } from "./image.svg";
import { ReactComponent as ChemistryBlockLogo } from "./chemistry.svg";

type TypeSkeletonProps = {
    top: number,
    left: number,
    width: number,
    height: number,
};

/**
 * Skeleton for a heading block
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function HeadingSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (32 * zoom) && realWidth > (32 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(HeadingConstants.fadeColor, 1),
                }}
            />
        );
    }

    const padding = 4 * zoom;

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.headingBorderSkeleton}`}
            style={{
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth}px`,
                height: `${realHeight}px`,
                backgroundColor: alpha(HeadingConstants.color, .1),
            }}
        >
            <div
                className={styles.textLineSkeleton}
                style={{
                    backgroundColor: HeadingConstants.fadeColor,
                    width: `${props.width - padding}px`,
                    height: `${props.height - padding}px`,
                }}
            />
        </div>
    );
}

/**
 * Renders a loading skeleton for a text block
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function TextSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (32 * zoom) && realWidth > (32 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(TextConstants.fadeColor, 1),
                }}
            />
        );
    }

    const padding = 8 * zoom;

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.textBorderSkeleton}`}
            style={{
                padding: `${padding}px`,
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth - 2 * padding}px`,
                height: `${realHeight - 2 * padding}px`,
                backgroundColor: alpha(TextConstants.color, .1),
                gap: `${8 * zoom}px`,
            }}
        >
            {[...Array(Math.floor(Math.max(0, (realHeight - padding) / (24 * zoom)))).keys()].map((i, a, b) => (
                <div
                    key={i}
                    className={styles.textLineSkeleton}
                    style={{
                        backgroundColor: TextConstants.fadeColor,
                        width: `${(i === b.length - 1) ? realWidth - 64 * zoom : realWidth - 16 * zoom}px`,
                        height: `${16 * zoom}px`,
                    }}
                />
            ))}
        </div>
    );
}

/**
 * Renders a skeleton for a math expression block
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function MathExpressionSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (48 * zoom) && realWidth > (64 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(MathExpressionConstants.fadeColor, 1),
                }}
            />
        );
    }

    const padding = 8 * zoom;

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.mathExpressionBorderSkeleton}`}
            style={{
                padding: `${padding}px`,
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth - 2 * padding}px`,
                height: `${realHeight - 2 * padding}px`,
                backgroundColor: alpha(MathExpressionConstants.color, .1),
                gap: `${8 * zoom}px`,
            }}
        >
            {[...Array(Math.floor(Math.max(0, (realHeight - padding) / (48 * zoom)))).keys()].map(i => {
                const leftFraction = (realWidth + realHeight + i) % 3 === 1;
                const rightFraction = (realWidth + realHeight + i) % 5 === 2;
                const middleBreaker = (realWidth + realHeight + i * 2) % 3;

                return (
                    <div
                        key={i}
                        className={styles.mathExpressionInnerContainer}
                        style={{
                            width: `${realWidth - 16 * zoom}px`,
                            gap: `${8 * zoom}px`,
                        }}
                    >
                        {leftFraction ? (
                            <div
                                className={styles.mathExpressionFractionContainer}
                                style={{
                                    gap: `${4 * zoom}px`,
                                }}
                            >
                                <div
                                    className={styles.textLineSkeleton}
                                    style={{
                                        backgroundColor: MathExpressionConstants.fadeColor,
                                        width: `${(realWidth - 32 * zoom) * (.4 + .1 * middleBreaker)}px`,
                                        height: `${16 * zoom}px`,
                                    }}
                                />
                                <div
                                    className={styles.textLineSkeleton}
                                    style={{
                                        backgroundColor: MathExpressionConstants.fadeColor,
                                        width: `${(realWidth - 32 * zoom) * (.4 + .1 * middleBreaker)}px`,
                                        height: `${16 * zoom}px`
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                className={styles.textLineSkeleton}
                                style={{
                                    backgroundColor: MathExpressionConstants.fadeColor,
                                    width: `${(realWidth - 48 * zoom) * (.4 + .1 * middleBreaker)}px`,
                                    height: `${32 * zoom}px`,
                                }}
                            />
                        )}
                        <div
                            className={styles.textLineSkeleton}
                            style={{
                                backgroundColor: MathExpressionConstants.fadeColor,
                                width: `${16 * zoom}px`,
                                height: `${16 * zoom}px`,
                            }}
                        />
                        {rightFraction ? (
                            <div
                                className={styles.mathExpressionFractionContainer}
                                style={{
                                    gap: `${4 * zoom}px`,
                                }}
                            >
                                <div
                                    className={styles.textLineSkeleton}
                                    style={{
                                        backgroundColor: MathExpressionConstants.fadeColor,
                                        width: `${(realWidth - 32 * zoom) * (.4 + .1 * (2 - middleBreaker))}px`,
                                        height: `${16 * zoom}px`,
                                    }}
                                />
                                <div
                                    className={styles.textLineSkeleton}
                                    style={{
                                        backgroundColor: MathExpressionConstants.fadeColor,
                                        width: `${(realWidth - 32 * zoom) * (.4 + .1 * (2 - middleBreaker))}px`,
                                        height: `${16 * zoom}px`
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                className={styles.textLineSkeleton}
                                style={{
                                    backgroundColor: MathExpressionConstants.fadeColor,
                                    width: `${(realWidth - 48 * zoom) * (.4 + .1 * (2 - middleBreaker))}px`,
                                    height: `${32 * zoom}px`,
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

/**
 * Renders a loading skeleton for a table
 * @param props
 * @constructor
 */
function TableSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (32 * zoom) && realWidth > (32 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(TableConstants.fadeColor, 1),
                }}
            />
        );
    }

    const padding = 8 * zoom;

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.tableBorderSkeleton}`}
            style={{
                padding: `${padding}px`,
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth - 2 * padding}px`,
                height: `${realHeight - 2 * padding}px`,
                backgroundColor: alpha(TableConstants.color, .1),
                gap: `${8 * zoom}px`,
            }}
        >
            <div
                className={styles.textLineSkeleton}
                style={{
                    backgroundColor: TableConstants.fadeColor,
                    width: `${Math.floor(Math.max(0, (realWidth - padding) / (40 * zoom))) * 40 * zoom}px`,
                    height: `${16 * zoom}px`,
                }}
            />
            {[...Array(Math.floor(Math.max(0, (realHeight - padding) / (24 * zoom) - 1))).keys()].map((i, a, b) => (
                <div
                    key={i}
                    className={styles.tableRowContainer}
                    style={{
                        width: `${realWidth - 16 * zoom}px`,
                        gap: `${8 * zoom}px`,
                    }}
                >
                    {[...Array(Math.floor(Math.max(0, (realWidth - padding) / (40 * zoom)))).keys()].map(i => (
                        <div
                            key={i}
                            className={styles.textLineSkeleton}
                            style={{
                                backgroundColor: TableConstants.fadeColor,
                                width: `${32 * zoom}px`,
                                height: `${16 * zoom}px`,
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}

/**
 * Renders a skeleton for an image block
 * @param props
 * @constructor
 */
function ImageSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (32 * zoom) && realWidth > (32 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(ImageConstants.fadeColor, 1),
                }}
            />
        );
    }

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.imageBorderSkeleton}`}
            style={{
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth}px`,
                height: `${realHeight}px`,
                backgroundColor: alpha(ImageConstants.color, .2),
                gap: `${8 * zoom}px`,
            }}
        >
            <ImageBlockLogo style={{
                width: `${realWidth - 16 * zoom}px`,
                height: `${realHeight - 16 * zoom}px`,
                opacity: .5,
            }}/>
        </div>
    );
}

/**
 * Renders a loading skeleton for a chemical formula block
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ChemicalFormulaSkeleton(props: TypeSkeletonProps) {
    const zoom = useSelector(selectCurrentZoom);

    //emulate border size of contentblocks
    const realTop = props.top - 2;
    const realLeft = props.left - 2;
    const realWidth = props.width + 4;
    const realHeight = props.height + 4;

    const renderInner: boolean = realHeight > (32 * zoom) && realWidth > (32 * zoom);

    if (!renderInner) {
        return (
            <div
                className={styles.contentblockBorderSkeleton}
                style={{
                    top: `${realTop}px`,
                    left: `${realLeft}px`,
                    width: `${realWidth}px`,
                    height: `${realHeight}px`,
                    backgroundColor: alpha(ChemicalFormulaConstants.fadeColor, 1),
                }}
            />
        );
    }

    return (
        <div
            className={`${styles.contentblockBorderSkeleton} ${styles.imageBorderSkeleton}`}
            style={{
                top: `${realTop}px`,
                left: `${realLeft}px`,
                width: `${realWidth}px`,
                height: `${realHeight}px`,
                backgroundColor: alpha(ChemicalFormulaConstants.color, .2),
                gap: `${8 * zoom}px`,
            }}
        >
            <ChemistryBlockLogo style={{
                width: `${realWidth - 16 * zoom}px`,
                height: `${realHeight - 16 * zoom}px`,
                opacity: .5,
            }}/>
        </div>
    );
}

type ContentBlockSkeletonProps = {
    contentblock: ContentBlockData,
};

/**
 * Displays a loading skeleton for a given contentblock (adjusts to size and type of the contentblock
 * @param props
 * @constructor
 */
export function ContentBlockSkeleton(props: ContentBlockSkeletonProps) {
    const pageBounds = useSelector(selectCurrentPageBounds);

    const data = props.contentblock;

    if (!!data) { //only render if data is present
        switch (data.details.type) {
            case HeadingConstants.type:
                return <HeadingSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />;
            case TextConstants.type:
                return <TextSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />
            case MathExpressionConstants.type:
                return <MathExpressionSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />
            case TableConstants.type:
                return <TableSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />
            case ImageConstants.type:
                return <ImageSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />
            case ChemicalFormulaConstants.type:
                return <ChemicalFormulaSkeleton
                    top={data.top * pageBounds.height}
                    left={data.left * pageBounds.width}
                    width={data.width * pageBounds.width}
                    height={data.height * pageBounds.height}
                />
        }
    }

    return null;
}
