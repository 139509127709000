// @flow

import { PayloadAction } from "@reduxjs/toolkit";
import {
    enhancedCreateBlock,
    enhancedCreateDocument,
    enhancedDeleteBlock,
    enhancedLoadDocument,
    enhancedLoadPageElements,
    enhancedUpdateBlockBounds,
    enhancedUpdateBlockDetails,
    enhancedUpdateBlockOrdinal,
    enhancedUpdatePdfElementOrdinal
} from "../enhancedAction";
import { selectDocumentIndex } from "../slice/DocumentSlice";
import { fileToRequest } from "../../util/FileUtil";
import { selectPageIndicesOrdered, selectPagesOrdered } from "../slice/PageSlice";
import type { PageIndex } from "../data/page/PageTypes";
import { selectCurrentPage, selectCurrentZoom, startDrag } from "../slice/EditorSlice";
import { selectContentBlock } from "../slice/ContentBlockSlice";

export const ActionEnhancerMiddleware = storeApi => next => (action: PayloadAction<any>) => {
    //only enhance actions which start with 'enhance'
    if (action.type.startsWith('enhance')) {
        const dIndex = selectDocumentIndex(storeApi.getState());
        switch(action.type) {
            //enhance editor actions
            case 'enhance/editor/startdrag':
                const pageData = selectPagesOrdered(storeApi.getState())[selectCurrentPage(storeApi.getState())];
                const zoom = selectCurrentZoom(storeApi.getState());

                next(startDrag({
                    target: !!action.payload.index && selectContentBlock(storeApi.getState(), action.payload.index),
                    targetIndex: action.payload.index,
                    activateModeVertical: action.payload.modeVertical,
                    activateModeHorizontal: action.payload.modeHorizontal,
                    activateModeMove: action.payload.modeMove,
                    pageWidth: (pageData?.width || 0) * zoom,
                    pageHeight: (pageData?.height || 0) * zoom,
                }));
                break;

            //Enhance document actions
            case 'enhance/document/create':
                fileToRequest(action.payload.file, action.payload.isPersistent).then(request => {
                    next(enhancedCreateDocument({ //dummy document with initial data
                        fileName: request.fileName,
                        isPersistent: request.isPersistent,
                    }, request.content));
                });
                break;

            case 'enhance/document/load':
                next(enhancedLoadDocument({
                    document: action.payload.id,
                }));
                break;

            //enhance page actions
            case 'enhance/page/load':
                // only dispatch next action if the document is loaded
                if (!!dIndex) {
                    //find page id for the given pagenumber
                    const pageIndices: PageIndex[] = selectPageIndicesOrdered(storeApi.getState());
                    next(enhancedLoadPageElements(pageIndices[action.payload.pageNumber]));
                } else {
                    console.log(`Cannot load page ${action.payload.pageNumber} since document is not loaded`);
                }
                break;

            //enhance contentblock actions
            case 'enhance/block/create':
                if (!!dIndex) {
                    next(enhancedCreateBlock(selectPageIndicesOrdered(storeApi.getState())[selectCurrentPage(storeApi.getState())], action.payload.initial));
                } else {
                    console.log(`Cannot create contentblock since document is not loaded`);
                }
                break;
            case 'enhance/block/bounds/update':
                if (!!dIndex) {
                    next(enhancedUpdateBlockBounds(action.payload.index, action.payload.bounds));
                } else {
                    console.log(`Cannot update contentblock since document is not loaded`);
                }
                break;
            case 'enhance/block/details/update':
                if (!!dIndex) {
                    next(enhancedUpdateBlockDetails({
                        ...dIndex,
                        page: action.payload.pageNumber,
                        contentblock: action.payload.contentblock,
                    }, action.payload.details));
                } else {
                    console.log(`Cannot update contentblock since document is not loaded`);
                }
                break;
            case 'enhance/block/ordinal/update':
                if (!!dIndex) {
                    next(enhancedUpdateBlockOrdinal({
                        ...dIndex,
                        page: action.payload.pageNumber,
                        contentblock: action.payload.contentBlock,
                    }, action.payload.ordinal));
                } else {
                    console.log(`Cannot update contentblock since document is not loaded`);
                }
                break;
            case 'enhance/block/delete':
                if (!!dIndex) {
                    next(enhancedDeleteBlock({
                        ...dIndex,
                        page: action.payload.pageNumber,
                        contentblock: action.payload.contentBlock,
                    }));
                } else {
                    console.log(`Cannot delete contentblock since document is not loaded`);
                }
                break;

            //enhance pdfelement actions
            case 'enhance/pdfelement/ordinal/update':
                if (!!dIndex) {
                    next(enhancedUpdatePdfElementOrdinal({
                        ...dIndex,
                        page: action.payload.pageNumber,
                        pdfelement: action.payload.pdfelement,
                    }, action.payload.ordinal));
                } else {
                    console.log(`Cannot update pdfelement since document is not loaded`);
                }
                break;
        }
    } else { // if action is not for enhancing, dont touch it
        next(action);
    }
};
