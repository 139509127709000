// @flow

import { useSelector } from "react-redux";
import { selectPageIndicesOrdered, selectPagesOrdered } from "../../../redux/slice/PageSlice";
import { useContentBlocks } from "../../../redux/hook/useContentBlock";
import { selectCurrentPage, selectCurrentZoom } from "../../../redux/slice/EditorSlice";
import { usePdfElements } from "../../../redux/hook/usePdfElement";
import type { PdfElementData } from "../../../redux/data/pdfelement/PdfElementTypes";
import type { ContentBlockData } from "../../../redux/data/contentblock/ContentBlockTypes";

import styles from "./PageSkeleton.module.css";
import { ContentBlockSkeleton } from "../block/ContentBlockSkeleton";

type PageSkeletonProps = {
};

/**
 * Displays a loading skeleton for the page with the given pagenumber
 * @param props
 * @constructor
 */
export function PageSkeleton(props: PageSkeletonProps) {
    /*
        Relevant editor data
     */
    const pageNumber = useSelector(selectCurrentPage);
    const zoom = useSelector(selectCurrentZoom);

    /*
        Retrieve page data
     */
    const pageIndex = useSelector(state => selectPageIndicesOrdered(state)[pageNumber]);
    const pageData = useSelector(state => selectPagesOrdered(state)[pageNumber]);

    /*
        Retrieve contentblock data
     */
    const contentblocks = useContentBlocks();

    /*
        Retrieve pdfelement data
     */
    const pdfelements = usePdfElements();

    /*
        Rendering stuffs
     */
    const pageBounds = {
        width: (pageData?.width || 612) * zoom, //these default valuse are A4 paper size
        height: (pageData?.height || 792) * zoom,
    };

    // TODO: build skeletons for pdfelements and show them during loading
    if ((!contentblocks || contentblocks.length === 0)) {//if neither pdfelements nor contentblocks are loaded for this page, render a default skeleton
        return (
            <div
                className={styles.pageSkeleton}
                style={{
                    padding: `${32 * zoom}px`, //padding needs to be subtracted from page width and height to not alter the page size
                    width: `${pageBounds.width - 64 * zoom}px`,
                    height: `${pageBounds.height - 64 * zoom}px`,
                    gap: `${8 * zoom}px`,
                }}
            >
                {/* show a title element */}
                <div
                    className={styles.textLineSkeleton}
                    style={{
                        alignSelf: "center",
                        width: "70%",
                        height: `${64 * zoom}px`,
                    }}
                />
                {/* show text lines with paragraphs */}
                {[...Array(Math.floor(Math.max((pageBounds.height - 64 * zoom) / (24 * zoom), 0))).keys()].map(i => (
                    <div
                        key={i}
                        className={styles.textLineSkeleton}
                        style={{
                            width: `${((i % 7) === 6) ? 50 + (i % 4) * 10 : 100}%`,
                            height: `${16 * zoom}px`,
                        }}
                    />
                ))}
            </div>
        );
    } else {//if either contentblocks or pdfelements are present, render a skeleton using those
        const pdfelementsToRender: PdfElementData[] = pdfelements || []; //if pdfelements are null, use an empty array instead
        const contentblocksToRender: ContentBlockData[] = contentblocks || []; //if contentblocks are null, use an empty array instead

        return (
            <div
                className={styles.pageSkeleton}
                style={{
                    padding: `${32 * zoom}px`, //padding needs to be subtracted from page width and height to not alter the page size
                    width: `${pageBounds.width - 64 * zoom}px`,
                    height: `${pageBounds.height - 64 * zoom}px`,
                    gap: `${8 * zoom}px`,
                }}
            >
                {contentblocks.map((cb, i) => <ContentBlockSkeleton key={i} contentblock={cb}/>)}
            </div>
        );
    }
}
