// @flow

import type {ElementContainer} from "./SliceTypes";
import {AccDocsIssueIndex, AccDocsIssueData } from "../data/accdocsissues/AccDocsIssueTypes";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";
import {accDocsIssueIndexMatch} from "../data/accdocsissues/AccDocsIssueUtil";
import {addTruthLayer, removeTruthLayer, updateGroundTruth} from "./SliceUtil";


export type AccDocsIssueLoadingPayload = {
    index: AccDocsIssueIndex,
}

export type AccDocsIssueLoadedPayload = {
    index: AccDocsIssueIndex,
    version: number,
    data: AccDocsIssueData,
}

export type AccDocsIssueUpdateUnconfirmedPayload = {
    origin: string,
    index: AccDocsIssueIndex,
    data: AccDocsIssueData,
}

export type AccDocsIssueUpdateRejectedPayload = {
    origin: string,
    index: AccDocsIssueIndex
}

export type AccDocsIssueUpdateConfirmedPayload = {
    origin: string,
    index: AccDocsIssueIndex,
    version: number,
    data: AccDocsIssueData,
}

export type AccDocsIssueUnloadedPayload = {
    index: AccDocsIssueIndex,
}

const accDocsIssueInitialState: { accDocsIssues: ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[]} = { accDocsIssues: []}; // initially empty

const AccDocsIssueSlice = createSlice( {
    name: 'accDocsIssues',
    initialState: accDocsIssueInitialState,
    reducers: {
        loading: (state, action: PayloadAction<AccDocsIssueLoadingPayload> ) => {
            console.log("accDocsIssueLoading: ", action);
            const idx = state.accDocsIssues.findIndex(item => accDocsIssueIndexMatch(item.index, action.payload.index));
            if (idx <= 0) {
                state.accDocsIssues =  [...state.accDocsIssues, {
                    index: action.payload.index,
                    groundTruth: null,
                    resolvedTruth: null,
                    groundTruthVersion: 0,
                    truthLayers: [],
                }];
            }

        },
        loaded: (state, action: PayloadAction<AccDocsIssueLoadedPayload>)  => {
            const existingIndex = state.accDocsIssues.findIndex(accDocsIssue => accDocsIssueIndexMatch(accDocsIssue.index, action.payload.index));
            console.log("inside loaded", action);
            if (existingIndex >= 0) {
                state.accDocsIssues[existingIndex].groundTruth = action.payload.data;
                state.accDocsIssues[existingIndex].resolvedTruth = action.payload.data;
                state.accDocsIssues[existingIndex].groundTruthVersion = action.payload.version;
            }
        },
        unloaded: (state, action: PayloadAction<AccDocsIssueUnloadedPayload>) => {
            console.log("unloading issue: ", action);
            state.accDocsIssues = state.accDocsIssues.filter(accDocsIssue => accDocsIssueIndexMatch(accDocsIssue.index, action.payload.index));
            //accDocsIssue.index.document !== action.payload.index.document
              //                                              || accDocsIssue.index.accDocsIssue !== action.payload.index.accDocsIssue);
        },
        updateUnconfirmed: (state, action: PayloadAction<AccDocsIssueUpdateUnconfirmedPayload>) => {
            console.log("AccDocsIssue updateUnconfirmed", action);
            const existingIndex = state.accDocsIssues.findIndex(accDocsIssue => accDocsIssueIndexMatch(accDocsIssue.index, action.payload.index));
            if (existingIndex >= 0) {
                addTruthLayer(state.accDocsIssues[existingIndex], action.payload.origin, action.payload.data);
            }
        },
        updateRejected: (state, action: PayloadAction<AccDocsIssueUpdateRejectedPayload>) => {
            console.log("AccDocsIssue updateRejected");
            const existingIndex = state.accDocsIssues.findIndex(accDocsIssue => accDocsIssueIndexMatch(accDocsIssue.index, action.payload.index));
            console.log("inside updateRejected");
            if (existingIndex >= 0) {
                removeTruthLayer(state.accDocsIssues[existingIndex], action.payload.origin);
            }
        },
        updateConfirmed: (state, action: PayloadAction<AccDocsIssueUpdateConfirmedPayload>) => {
            console.log("AccDocsIssue updateConfirmed", action);
            const existingIndex = state.accDocsIssues.findIndex(accDocsIssue => accDocsIssue.index.document === action.payload.index.document
                                                        && accDocsIssue.index.accDocsIssue === action.payload.index.accDocsIssue);
            if (existingIndex >= 0) {
                removeTruthLayer(state.accDocsIssues[existingIndex], action.payload.origin);
                updateGroundTruth(state.accDocsIssues[existingIndex], action.payload.data, action.payload.version);
            }
        },
    },
});


export const { loading,
                loaded,
                unloaded,
                updateUnconfirmed,
                updateRejected,
                updateConfirmed } = AccDocsIssueSlice.actions;

export default AccDocsIssueSlice.reducer;

//basic selector
function selectAllAccDocsIssues(state): ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[] {
    //console.log("selectAllAccDocsIssues state: ", state);
    //console.log("selectAllAccDocsIssues accDocsIssues: ", state.accDocsIssues);
    return state.accDocsIssues.accDocsIssues;
}

//selectors
export const selectAccDocsIssueIndices = createSelector([
        selectAllAccDocsIssues
    ],
    (accDocsIssues: ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[]) =>
        accDocsIssues.map(accDocsIssue => accDocsIssue.index));

export const selectAccDocsIssuesIndicesOrdered = createSelector([
        selectAllAccDocsIssues
    ],
    (accDocsIssues: ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[]) => [...accDocsIssues].sort(
        (a, b) => !!a.resolvedTruth ? !!b.resolvedTruth ? a.index.accDocsIssue - b.index.accDocsIssue : -1 : 1)
        .map(accDocsIssue => {
            console.log("accdocsIssue.resolvedTruth", accDocsIssue.resolvedTruth);
            if(accDocsIssue.resolvedTruth != null) {
                return accDocsIssue.index; // @todo: there seems to be an issue where some issues are null sometimes. Make sure that doesn't happen in the future?
            }
            console.log("after if");
        }).filter(idx => {
            return idx != null;
        }));

export const selectAccDocsIssues = createSelector([
        selectAllAccDocsIssues
    ],
    (accDocsIssues: ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[]) =>
        accDocsIssues.map(accDocsIssue => accDocsIssue.resolvedTruth));

export const selectAccDocsIssuesOrdered = createSelector([
        selectAccDocsIssues
    ],
    (accDocsIssues: AccDocsIssueData[]) =>
        accDocsIssues.sort((a, b) => !!a && !!b ? a.id - b.id : !!a ? -1 : 1));

export const selectAccDocsIssue = createSelector([
        selectAllAccDocsIssues,
        (state, accDocsIssueIndex: AccDocsIssueIndex) => accDocsIssueIndex,
    ],
    (accDocsIssues: ElementContainer<AccDocsIssueIndex, AccDocsIssueData>[], accDocsIssueIndex: AccDocsIssueIndex) => {
        //console.log("selectAccDocsIssue accDocsIssueIndex ", accDocsIssueIndex);
        //console.log("all accDocsIssues are: ", accDocsIssues);
        const existingIndex = accDocsIssues.findIndex(i => accDocsIssueIndexMatch(i.index, accDocsIssueIndex));
        //console.log("the existingIndex is: ", existingIndex);
        if (existingIndex >= 0) {
            //console.log("returning following: ", accDocsIssues[existingIndex].resolvedTruth);
            return accDocsIssues[existingIndex].resolvedTruth;
        } else {
            return null;
        }
    });


