// @flow

import type { ContentBlockData, ContentBlockIndex } from "../../../redux/data/contentblock/ContentBlockTypes";
import { useSelector } from "react-redux";
import { selectContentBlock } from "../../../redux/slice/ContentBlockSlice";
import { selectPage } from "../../../redux/slice/PageSlice";
import styles from "./BlockSvg.module.css";
import { CircularProgress } from "@material-ui/core";
import type { PageData } from "../../../redux/data/page/PageTypes";
import { usePageSVG } from "../../../redux/hook/usePageSVG";

type BlockSvgProps = {
    index: ContentBlockIndex,
    zoom?: number,
};
export function BlockSvg(props: BlockSvgProps) {
    /*
        Validate props
     */
    const actualZoom = props.zoom || 1;

    /*
        Fetch data from state
     */
    const blockData: ContentBlockData = useSelector(state => selectContentBlock(state, props.index));
    const pageData: PageData = useSelector(state => selectPage(state, props.index));

    /*
        Fetch svg
     */
    const svgSource = usePageSVG(props.index);
    const loading = !svgSource;

    if (loading) {
        let width = 124;
        let height = 124;
        if (!!blockData && !!pageData) {
            width = blockData.width * pageData.width * actualZoom;
            height = blockData.height * pageData.height * actualZoom;
        }

        return (
            <div
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    backgroundColor: "white",
                }}
                className={styles.loadingContainer}
            >
                <CircularProgress color={"primary"}/>
            </div>
        );
    }

    //if not loading, svg is present and we can render it instead
    let width = 1;
    let height = 1;
    let left = 0;
    let top = 0;
    if (!!blockData) {
        width = blockData.width;
        height = blockData.height;
        left = blockData.left;
        top = blockData.top;
    }

    return (
        <div
            style={{
                backgroundImage: `url(${svgSource})`,
                backgroundSize: `${100 / width}% ${100 / height}%`,
                backgroundPosition: `${-pageData.width * actualZoom * left}px ${-pageData.height * actualZoom * top}px`,
                backgroundColor: "white",
                width: `${pageData.width * actualZoom * width}px`,
                height: `${pageData.height * actualZoom * height}px`,
            }}
        />
    );
}
