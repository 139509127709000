// @flow

/**
 * Simple type which is used to more accurately track classNames/targets for tutorials and css-labels
 */
class TutorialTarget {

    constructor(className: string) {
        this.classValue = className;
    }
    className() {
        return this.classValue;
    }

    target() {
        return `.${this.className()}`;
    }
}

/*
    Basic Editor components
 */
export const TargetPdfArea: TutorialTarget = new TutorialTarget("pdf-area");
export const TargetHotzone: TutorialTarget = new TutorialTarget("editor-hotzone");

/*
    SideBar components
 */
export const TargetSideBarComplete: TutorialTarget = new TutorialTarget("sidebar-complete");
export const TargetSideBarTabs: TutorialTarget = new TutorialTarget("sidebar-tabs");
export const TargetSideBarExpand: TutorialTarget = new TutorialTarget("sidebar-expand");

//TODO: targets for all sidebar tabs and possibly their contents
/*
    ReadingOrder targets
 */
export const TargetReadingOrderTray: TutorialTarget = new TutorialTarget("reading-order-tray");

export const TargetReadingOrderList: TutorialTarget = new TutorialTarget("reading-order-list");

export const TargetReadingOrderOverlay: TutorialTarget = new TutorialTarget("reading-order-overlay");

export const TargetReadingOrderAnchorAll: TutorialTarget = new TutorialTarget("reading-order-anchor-all");
export const TargetReadingOrderAnchor0: TutorialTarget = new TutorialTarget("reading-order-anchor-0");
export const TargetReadingOrderAnchor1: TutorialTarget = new TutorialTarget("reading-order-anchor-1");
export const TargetReadingOrderAnchor2: TutorialTarget = new TutorialTarget("reading-order-anchor-2");

/*
    Metadata targets
 */
export const TargetMetadataTray: TutorialTarget = new TutorialTarget("metadata-tray");

/*
    Progress targets
 */
export const TargetProgressTray: TutorialTarget = new TutorialTarget("progress-tray");

/*
   Download targets
 */
export const TargetDownloadTray: TutorialTarget = new TutorialTarget("download-tray");

/*
    Tutorials targets
 */
export const TargetTutorialsTray: TutorialTarget = new TutorialTarget("tutorials-tray");

/*
    Settings target
 */
export const TargetSettingsTray: TutorialTarget = new TutorialTarget("settings-tray");

/*
    Editor components
 */

//TODO: targets for all editor types and their contents

/*
    Home Page targets
 */
export const TargetHomePageLogo: TutorialTarget = new TutorialTarget("home-page-logo");
export const TargetHomePageTerms: TutorialTarget = new TutorialTarget("home-page-terms");
