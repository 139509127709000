// @flow

import { useDispatch, useSelector } from "react-redux";
import { selectCurrentPage } from "../slice/EditorSlice";
import { selectPage, selectPageIndicesOrdered } from "../slice/PageSlice";
import {
    selectContentBlock,
    selectFromPage,
    selectFromPageOrdered,
    selectIndicesFromPage,
    selectIndicesFromPageOrdered
} from "../slice/ContentBlockSlice";
import type { ContentBlockData, ContentBlockDetails, ContentBlockIndex } from "../data/contentblock/ContentBlockTypes";
import { useCallback, useMemo } from "react";
import { deleteBlock, updateBlockBounds, updateBlockDetails, updateBlockOrdinal } from "../action";

export function useContentBlockIndices() {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectIndicesFromPage(state, currentPageIndex));
}

export function useContentBlockIndicesOrdered() {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectIndicesFromPageOrdered(state, currentPageIndex));
}

/**
 * Returns a list of contentblocks on the currently shown page
 * @returns {*[]}
 */
export function useContentBlocks(): ContentBlockData[] {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectFromPage(state, currentPageIndex));
}

/**
 * Returns a list of contentblocks on the currently shown page ordered by ordinal
 * @returns {ContentBlockData[]}
 */
export function useContentBlocksOrdered() {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectFromPageOrdered(state, currentPageIndex));
}

export function useContentBlock(index: ContentBlockIndex): ContentBlockData | null {
    return useSelector(state => !!index ? selectContentBlock(state, index) : null);
}

/**
 * Returns an object representation of the contentblock which calls appropriate events when edited
 * @param index
 */
export function useEditableContentBlock(index: ContentBlockIndex) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(state => !!index ? selectPage(state, index)?.pageNumber : null);

    const onChangeWidth = useCallback<(number, number, number) => void>((pageNumber, contentblock, width) => dispatch(updateBlockBounds(pageNumber, contentblock, { width: width })), [dispatch]);
    const onChangeHeight = useCallback<(number, number, number) => void>((pageNumber, contentblock, height) => dispatch(updateBlockBounds(pageNumber, contentblock, { height: height })), [dispatch]);
    const onChangeLeft = useCallback<(number, number, number) => void>((pageNumber, contentblock, left) => dispatch(updateBlockBounds(pageNumber, contentblock, { left: left })), [dispatch]);
    const onChangeTop = useCallback<(number, number, number) => void>((pageNumber, contentblock, top) => dispatch(updateBlockBounds(pageNumber, contentblock, { top: top })), [dispatch]);
    const onPatchBounds = useCallback<(number, number, $Shape<{
        left: number,
        top: number,
        width: number,
        height: number,
    }>) => void>((pageNumber, contentblock, bounds) => dispatch(updateBlockBounds(pageNumber, contentblock, bounds)));

    const onChangeOrdinal = useCallback<(number, number, number) => void>((pageNumber, contentblock, ordinal) => dispatch(updateBlockOrdinal(pageNumber, contentblock, ordinal)), [dispatch]);
    const onChangeDetails = useCallback<(number, number, $Shape<ContentBlockData["details"]>) => void>((pageNumber, contentblock, details) => dispatch(updateBlockDetails(pageNumber, contentblock, {
        type: details.type,
        alternativeText: details.alternativeText,
        language: details.language,
        level: details.level,
        aiConfidence: details.aiConfidence,
    })), [dispatch]);
    const onDeleteContentBlock = useCallback<(number, number) =>void>((pageNumber, contentblock) => dispatch(deleteBlock(pageNumber, contentblock)), [dispatch]);

    const contentblock: ContentBlockData = useSelector(state => !!index ? selectContentBlock(state, index) : null);

    //create
    const result = useMemo(() => {
        if (!!contentblock) {
            const result: $Shape<ContentBlockData> = {
                runningAutofillServicesValue: contentblock.runningAutofillServices,
                get runningAutofillServices(): number {
                    return this.runningAutofillServicesValue;
                },

                widthValue: contentblock.width,
                get width(): number {
                    return this.widthValue;
                },
                set width(value: number) {
                    this.widthValue = value;
                    onChangeWidth(pageNumber, index.contentblock, value);
                },

                heightValue: contentblock.height,
                get height(): number {
                    return this.heightValue;
                },
                set height(value: number) {
                    this.heightValue = value;
                    onChangeHeight(pageNumber, index.contentblock, value);
                },

                leftValue: contentblock.left,
                get left(): number {
                    return this.leftValue;
                },
                set left(value: number) {
                    this.leftValue = value;
                    onChangeLeft(pageNumber, index.contentblock, value);
                },

                topValue: contentblock.top,
                get top(): number {
                    return this.topValue;
                },
                set top(value: number) {
                    this.topValue = value;
                    onChangeTop(pageNumber, index.contentblock, value);
                },

                ordinalValue: contentblock.ordinal,
                get ordinal(): number {
                    return this.ordinalValue;
                },
                set ordinal(value: number) {
                    this.ordinalValue = value;
                    onChangeOrdinal(pageNumber, index.contentblock, value);
                },

                detailsValue: {
                    typeValue: contentblock.details.type,
                    get type(): ContentBlockDetails["type"] {
                        return this.typeValue;
                    },
                    set type(value: ContentBlockDetails["type"]) {
                        this.typeValue = value;
                        onChangeDetails(pageNumber, index.contentblock, this);
                    },

                    //autofill log should not be changable
                    autofillLogValue: contentblock.details.autofillLog,
                    get autofillLog(): ContentBlockDetails["autofillLog"] {
                        return this.autofillLogValue;
                    },

                    alternativeTextValue: contentblock.details.alternativeText,
                    get alternativeText(): ContentBlockDetails["alternativeText"] {
                        return this.alternativeTextValue;
                    },
                    set alternativeText(value: ContentBlockDetails["alternativeText"]) {
                        this.alternativeTextValue = value;
                        console.log(value);
                        onChangeDetails(pageNumber, index.contentblock, this);
                    },

                    languageValue: contentblock.details.language,
                    get language(): ContentBlockDetails["language"] {
                        return this.languageValue;
                    },
                    set language(value: ContentBlockDetails["language"]) {
                        this.languageValue = value;
                        onChangeDetails(pageNumber, index.contentblock, this);
                    },

                    levelValue: contentblock.details.level,
                    get level(): ContentBlockDetails["level"] {
                        return this.levelValue;
                    },
                    set level(value: ContentBlockDetails["level"]) {
                        this.levelValue = value;
                        onChangeDetails(pageNumber, index.contentblock, this);
                    },

                    aiConfidenceValue: contentblock.details.aiConfidence,
                    get aiConfidence(): ContentBlockDetails["aiConfidence"] {
                        return this.aiConfidenceValue;
                    },
                    set aidConfidence(value: ContentBlockDetails["aiConfidence"]){
                        this.aiConfidenceValue = value;
                        onChangeDetails(pageNumber, index.contentblock, this);
                    },
                },
                get details(): ContentBlockData["details"] {
                    return this.detailsValue;
                },

                patch: function (value: $Shape<$Diff<ContentBlockData, { details: any }>>) {
                    this.widthValue = value.width || this.widthValue;
                    this.heightValue = value.height || this.heightValue;
                    this.leftValue = value.left || this.leftValue;
                    this.topValue = value.top || this.topValue;
                    this.ordinalValue = value.ordinal || this.ordinalValue;

                    onPatchBounds(pageNumber, index.contentblock, value);

                    if(!!value.ordinal) {
                        onChangeOrdinal(pageNumber, index.contentblock, value.ordinal);
                    }
                },

                delete: function() {
                    onDeleteContentBlock(pageNumber, index.contentblock);
                }
            };

            result.patch = result.patch.bind(result);
            result.delete = result.delete.bind(result);

            return result;
        }

        return null;
    }, [ contentblock, index ]);

    return result;
}
