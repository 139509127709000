// @flow

import { configureStore } from "@reduxjs/toolkit";
import DocumentSlice from "./slice/DocumentSlice";
import PageSlice from "./slice/PageSlice";
import ContentBlockSlice from "./slice/ContentBlockSlice";
import PdfElementSlice from "./slice/PdfElementSlice";
import EditorSlice from "./slice/EditorSlice";
import { ActionEnhancerMiddleware } from "./middleware/ActionEnhancerMiddleware";
import { MessagingMiddleware } from "./middleware/MessagingMiddleware";
import SocketSlice from "./slice/SocketSlice";
import TaskSlice from "./slice/TaskSlice";
import { LoggingMiddleware } from "./middleware/LoggingMiddleware";
import LoggingSlice from "./slice/LoggingSlice";
import { PageElementCacheControllerMiddleware } from "./middleware/PageElementCacheControllerMiddleware";
import accDocsIssueSlice from "./slice/AccDocsIssueSlice";

/*
    Configure store
 */

export default configureStore({
    reducer: {
        //backend state
        documents: DocumentSlice,
        pages: PageSlice,
        contentblocks: ContentBlockSlice,
        pdfelements: PdfElementSlice,
        tasks: TaskSlice,
        accDocsIssues: accDocsIssueSlice,

        //editor state
        editor: EditorSlice,

        //socket connection
        socket: SocketSlice,

        //logging
        logging: LoggingSlice,
    },

    //add middleware to enhance actions and handle websocket connection with the backend and messaging
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: ['enhance/document/create'], //allows us to dispatch a file and read it in the action enhancer middleware
        },
    }).concat([ ActionEnhancerMiddleware, PageElementCacheControllerMiddleware, MessagingMiddleware, LoggingMiddleware ]),
})
