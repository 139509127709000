// @flow

import {Typography} from "@material-ui/core";
import styles from "./ContactInfo.module.css";
import {useTranslation} from "react-i18next";
import access_at_kit from "../../img/access@kit_rgb_dt.png"

export function ContactInfo() {

    const {t} = useTranslation();
    return(
        <div className={`${styles.contactsContent}`}>
            <center>
                <img src={access_at_kit} width={"250px"} alt={"Logo of ACCESS@KIT"}/>
            </center>
            <strong>{t('homePage.kit')}<br/>
                {t('homePage.accessAtKIT')}</strong><br/>
            Adenauerring 10 (Geb. 50.28)<br/>
            76131 Karlsruhe<br/><br/>
            Dr. Thorsten Schwarz - <a href="mailto:thorsten.schwarz@kit.edu">thorsten.schwarz@kit.edu</a><br/>
            <br/>
            {t('homePage.persons')}<br/>
                Yufan Chen, Zihan Chen, Patryk Dzierzawski, Merlin Knäble, Frederik Lingg, Giuseppe Melfi, Omar Moured, Linus Osthof,
                Manuel Portz, Gabriel Sailer, Ann-Christin Schmidt, Dr. Thorsten Schwarz, Christoph Stelz,
                Maximilian Walz, David Wilkening
                <br/><br/>
            {/*TODO: Segementation Model Picker*/}
        </div>
    );
}
