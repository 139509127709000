// @flow

import { useCallback, useContext, useEffect, useRef } from "react";
import { HotkeyContext } from "./HotkeyContext";

/**
 * Registers a hotkey as soon as the component is mounted and unregisters it once it is unmounted
 * @param mapping the name of the hotkey mapping
 * @param target the keys to press to activate the handler (may be a list to allow multiple key combinations/sequences) (see https://github.com/greena13/react-hotkeys for examples of valid targets)
 * @param handler a function to execute when any of the specified targets is triggered
 * @param dependencies the dependencies of the supplied handler function (see useCallback which is used internally to memoize the handler function)
 */
export function useHotkey(mapping: string, target: string | string[], handler: () => void, dependencies: any[]) {
    const [ setHotkey: (string, string | string[], () => void) => void, removeHotkey: string => void ] = useContext(HotkeyContext);

    const handlerMemo: () => void = useCallback<() => void>(handler, dependencies); //memoize handler to avoid unnecessary updates
    const mappingNameRef: { current: string | null } = useRef<string | null>(null);

    useEffect(() => {
        //Remove previous mapping if name has changed
        if (mappingNameRef.current && mappingNameRef.current !== mapping) {
            removeHotkey(mappingNameRef.current);
        }

        mappingNameRef.current = mapping;
        if (!!mapping) { //no mapping name causes the mapping to not be registered
            setHotkey(mapping, target, handlerMemo);
        }

        return () => removeHotkey(mappingNameRef.current);
    }, [ mapping, target, handlerMemo ]);
}
