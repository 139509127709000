// @flow

import React, { Fragment, useCallback, useRef, useState } from "react";
import { HotkeyContext } from "./HotkeyContext";
import { GlobalHotKeys } from "react-hotkeys";

type HotkeyProviderProps = {
    children: React$Node,
}

/**
 * Provides Global functionality for hotkeys. Should be used in the root elements of the application, children may use `useHotkey` to register hotkeys while they are mounted
 * @returns {JSX.Element}
 * @constructor
 */
export function HotkeyProvider({ children }: HotkeyProviderProps) {
    const [ keymap: { [string]: string }, setKeymap: { [string]: string } => void ] = useState<{ [string]: string }>({});
    const keymapRef: { current: { [string]: string }} = useRef<{ [string]: string }>({});
    const [ handlers: { [string]: () => void }, setHandlers: { [string]: () => void } => void ] = useState<{ [string]: () => void }>({});
    const handlersRef: { current: { [string]: () => void }} = useRef<{ [string]: () => void }>({});

    //keep as few references for the adding/removing methods as possible
    const addHotkey: (string, string | string[], () => void) => void = useCallback((mapping, target, handler) => {
        // since hotkeys may change during the run of the application, overriding a hotkey is legal and there is no need to check it
        //we need to change the actual object reference, otherwise the state will not be updated correctly
        keymapRef.current = {...keymapRef.current};
        keymapRef.current[mapping] = target;
        handlersRef.current = {...handlersRef.current};
        handlersRef.current[mapping] = handler;

        setKeymap(keymapRef.current);
        setHandlers(handlersRef.current);
    }, [ setKeymap, setHandlers ]);

    const removeHotkey: string => void = useCallback((mapping) => {
        keymapRef.current.hasOwnProperty(mapping) && delete keymapRef.current[mapping];
        handlersRef.current.hasOwnProperty(mapping) && delete handlersRef.current[mapping];

        //we need to change the actual object reference, otherwise the state will not be updated correctly
        keymapRef.current = {...keymapRef.current};
        handlersRef.current = {...handlersRef.current};

        setKeymap(keymapRef.current);
        setHandlers(handlersRef.current);
    }, [ setKeymap, setHandlers ]);

    return (
        <Fragment>
            <GlobalHotKeys
                keyMap={keymap}
                handlers={handlers}
                allowChanges={true}
            />
            <HotkeyContext.Provider value={[ addHotkey, removeHotkey ]}>
                {children}
            </HotkeyContext.Provider>
        </Fragment>
    );
}
