import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { CookiesProvider } from "react-cookie";

console.log(`Basename: ${process.env.REACT_APP_BASENAME}, Backend URL: ${process.env.REACT_APP_BACKEND_URL}`);
ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
            <React.Suspense fallback="Loading...">
                <Provider store={store}> {/*store is the global data storage (every state is tracked here)*/}
                    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                        <App/>
                    </BrowserRouter>
                </Provider>
            </React.Suspense>
    </CookiesProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
