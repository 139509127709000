// @flow

import type { PdfElementData } from "../data/pdfelement/PdfElementTypes";
import { useSelector } from "react-redux";
import { selectFromPage, selectFromPageOrdered } from "../slice/PdfElementSlice";
import { selectCurrentPage } from "../slice/EditorSlice";
import { selectPageIndicesOrdered } from "../slice/PageSlice";

/**
 * Returns an array of pdfelements which are situated on the currently shown page
 * @returns {*[]}
 */
export function usePdfElements(): PdfElementData[] {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectFromPage(state, currentPageIndex));
}

/**
 * Returns an array of pdfelements which are situated on the currently shown page sorted by ordinal
 * @returns {PdfElementData[]}
 */
export function usePdfElementsOrdered(): PdfElementData[] {
    const currentPageNumber = useSelector(selectCurrentPage);
    const currentPageIndex = useSelector(selectPageIndicesOrdered)[currentPageNumber];

    return useSelector(state => selectFromPageOrdered(state, currentPageIndex));
}
