// @flow

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { LoggingMessage, LoggingSeverity } from "../app/logging/LoggingTypes";

type LogPayload = {
    message: LoggingMessage,
    index: number,
};

type RemovePayload = {
    index: number,
};

type LoggingData = {
    level: LoggingSeverity,
    messages: {
        message: LoggingMessage,
        index: number,
    }[],
};

const initialValue = {
    level: "WARNING",
    messages: [],
};

const LoggingSlice = createSlice<LoggingData>({
    name: "logging",
    initialState: initialValue,
    reducers: {
        addMessage: (state, action: PayloadAction<LogPayload>) => {
            state.messages = [ ...state.messages, {
                message: action.payload.message,
                index: action.payload.index,
            }];
        },
        removeMessage: (state, action: PayloadAction<RemovePayload>) => {
            const messagePosition = state.messages.findIndex(message => message.nextIndex === action.payload.index);
            if (messagePosition >= 0) {
                state.messages = [ ...state.messages.slice(0, messagePosition), ...state.messages.slice(messagePosition + 1)];
            }
        }
    }
});

export const { addMessage, removeMessage } = LoggingSlice.actions;
export default LoggingSlice.reducer;

/**
 * Selects all messages currently in the logging slice
 * @param state
 * @returns {*}
 */
export function selectMessages(state): { message: LoggingMessage, index: number }[] {
    return state.logging.messages;
}

/**
 * Selects the top logging message if it is newer than the given timeout
 * @param state
 * @param timeout the timeout in milliseconds
 * @returns {{message: LoggingMessage, index: number}|null}
 */
export function selectLastMessage(state, timeout): { message: LoggingMessage, index: number } | null {
    if (state.logging.messages.length > 0) {
        const message: { message: LoggingMessage, index: number } = state.logging.messages[state.logging.messages.length - 1];
        if (message.message.severity >= state.logging.level
            && (+new Date() - message.message.timestamp) < timeout) { // only return the message if it is newer than the given timeout
            return message;
        }
    }

    return null;
}
