// @flow

import type { TaskData, TaskIndex, TaskSTOMPPayload } from "./TaskTypes";

export function taskPayloadToData(payload: TaskSTOMPPayload) : TaskData {
  console.log("Payload: ", payload);
  return {
    currentPage: payload.currentPage,
    percentage: payload.percentage,
    completed: payload.completed,
    successful: payload.successful,
    message: payload.message,
    type: payload.type
  };
}

export function taskDataToPayload(data: TaskData) : TaskSTOMPPayload {
  // Tasks are updated unilaterally from the server to the client.

  return {};
}

export function taskIndexMatch(a: TaskIndex, b: TaskIndex) : boolean {
  return a.document === b.document && a.task === b.task;
}

