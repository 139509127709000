// @flow

import { useDocumentView } from "../../redux/hook/useDocumentView";
import { CircularProgress, IconButton } from "@material-ui/core";
import { useDispatch, useSelector, useStore } from "react-redux";
import { selectDocument, selectDocumentIndex } from "../../redux/slice/DocumentSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { selectPagesOrdered } from "../../redux/slice/PageSlice";
import type { PageData } from "../../redux/data/page/PageTypes";
import { Pagination } from "@material-ui/lab";
import type { DocumentData } from "../../redux/data/document/DocumentTypes";
import { loadPageElements } from "../../redux/action";
import {
    selectContentBlockEditing,
    selectCurrentPage,
    selectCurrentPageBounds,
    selectCurrentZoom,
    selectSideBarExpanded,
    selectSideBarTab,
    setCurrentPage,
    setCurrentZoom, setRunTutorial,
    setSideBarExpanded
} from "../../redux/slice/EditorSlice";
import { ContentBlockRenderer } from "./ContentBlockRenderer";
import { CenterFocusWeak, ZoomIn, ZoomOut } from "@material-ui/icons";
import styles from "./BlockEditorContent.module.css";
import { PageSvg } from "../../common/element/page/PageSvg";

import { useSpring } from "react-spring";

import { SideBarDrawer } from "./sidebar/SideBarDrawer";
import { TargetHotzone, TargetPdfArea } from "./tutorials/targets";
import { BasicEditorTutorial } from "./tutorials/BasicEditorTutorial";
import { ReadingOrderOverlay } from "./ReadingOrderOverlay";
import { SideBarTabReadingOrderConfig } from "./sidebar/SideBarTabReadingOrder";
import { ReadingOrderTutorial } from "./tutorials/ReadingOrderTutorial";
import { NewContentBlockEditMenu } from "./NewContentBlockEditMenu";
import { PageSVGProvider } from "../../redux/hook/usePageSVG";
import {useCookies} from "react-cookie";
import SegmentationTaskProgressDisplay from "../../common/element/segmentation/SegmentationTaskProgressDisplay";


// Tutorial IDs
import { BasicEditorTutorialId } from "./tutorials/BasicEditorTutorial";
import {StartupTutorial, StartupTutorialId} from "./tutorials/StartupTutorial";


const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export function BlockEditorContent() {
    //redux basic
    const store = useStore();
    const dispatch = useDispatch();
    const [tutorialRun, setTutorialRun] = useState(false); // run tutorial only the first time
    const [cookies] = useCookies(["devMode"]);

    // running startup tutorial
    useEffect(() => {
        // @todo: replace cookie with user data backend?
        if(!cookies.devMode && !tutorialRun) {
            console.log("starting tutorial");
            async function runTutorial() {
                await delay(600);
                dispatch(setRunTutorial({tutorial: StartupTutorialId}));
            }
            runTutorial();
            console.log("ending tutorial");
            setTutorialRun(true);
        }
    });


    //document details
    const loaded = useDocumentView();
    const documentIndex = useSelector(selectDocumentIndex);
    const document: DocumentData = useSelector(selectDocument);

    //page data
    const currentPageIndex = useSelector(selectCurrentPage);
    const hasCenteredPageRef = useRef<boolean>(false);
    const handlePaginationChange = useCallback((e, value) => {
        hasCenteredPageRef.current = false;
        dispatch(setCurrentPage({ pageNumber: value - 1 }));
    }, []);
    const currentPage: PageData = useSelector(selectPagesOrdered)[currentPageIndex];
    const [ pageSvgLoading, setPageSvgLoading ] = useState<boolean>(false);
    const [isDropped, setIsDropped] = useState(false);
    const [droppedIntoId, setDroppedIntoId] = useState(-1);

    //track size of pdf viewing area
    const pdfAreaRef = useRef();
    const [ pdfAreaPresent, setPdfAreaPresent ] = useState(false);
    const onPdfAreaChanged = useCallback((element: HTMLDivElement) => {
        const previous = pdfAreaRef.current;
        pdfAreaRef.current = element;
        if (!previous) {
            setPdfAreaPresent(true);
        }
    }, [])
    const pdfAreaBounds = {
        width: pdfAreaRef.current?.getBoundingClientRect().width || 0,
        height: pdfAreaRef.current?.getBoundingClientRect().height || 0,
    };

    //track editing area to allow catching scroll events on it (in case of zoom)
    const currentZoom = useSelector(selectCurrentZoom);
    const editingAreaRef = useRef<HTMLDivElement>(null);
    const editingListener = useCallback((event: HTMLElementEventMap["wheel"]) => {
        if (event.ctrlKey) { //control is pressed while scrolling means zoom is requested
            event.preventDefault(); //prevent browser scrolling

            //if currently editing, do not zoom
            if (!!selectContentBlockEditing(store.getState())) {
                return;
            }

            //calculate new zoom
            const currentZoom = selectCurrentZoom(store.getState());
            const newZoom = currentZoom + (event.deltaY > 0 ? -.1 : .1);

            //calculate new scrolling positions
            if (!!pdfAreaRef.current) {
                const zoomRatio = newZoom / currentZoom;

                //only actual page is zoomed, the editing area is pagewidth + 1.8 * pdfareawidth
                //we calculate mouseposition relative to the page instead
                const pdfAreaBB = pdfAreaRef.current.getBoundingClientRect();
                const editingAreaBB = editingAreaRef.current.getBoundingClientRect();
                const pageBounds = selectCurrentPageBounds(store.getState());

                const mouseLeftFromEdit = event.clientX - editingAreaBB.left;
                const mouseTopFromEdit = event.clientY - editingAreaBB.top;

                const mouseLeftPageRel = Math.min(Math.max((mouseLeftFromEdit - (pdfAreaBB.width * 0.9)) / pageBounds.width, 0), 1);
                const mouseTopPageRel = Math.min(Math.max((mouseTopFromEdit - (pdfAreaBB.height * 0.9)) / pageBounds.height, 0), 1);

                const scrollDeltaLeft = mouseLeftPageRel * pageBounds.width * (zoomRatio - 1);
                const scrollDeltaTop = mouseTopPageRel * pageBounds.height * (zoomRatio - 1);

                pdfAreaRef.current.scrollLeft = pdfAreaRef.current.scrollLeft + scrollDeltaLeft;
                pdfAreaRef.current.scrollTop = pdfAreaRef.current.scrollTop + scrollDeltaTop;
            }

            //calculate and dispatch new zoom
            dispatch(setCurrentZoom({zoom: newZoom}));
        }
    }, []);

    const onEditingAreaChanged = useCallback((area: HTMLDivElement) => {
        //remove listener from old area
        if (!!editingAreaRef.current) {
            editingAreaRef.current.removeEventListener("wheel", editingListener, { passive: false })
        }
        editingAreaRef.current = area;

        //add listener to new area
        if (!!editingAreaRef.current) {
            editingAreaRef.current.addEventListener("wheel", editingListener, { passive: false });
        }
    }, [ editingListener ]);

    //track page move callbacks
    const [ isPageMoving, setIsPageMoving ] = useState<boolean>(false);
    const pageMovingRef = useRef<boolean>(false);
    pageMovingRef.current = isPageMoving; //synchronize each time the state changes
    const handleMouseMove = useCallback((event: HTMLElementEventMap["mousemove"]) => {
        if (pageMovingRef.current) {
            pdfAreaRef.current.scrollLeft = pdfAreaRef.current.scrollLeft - event.movementX;
            pdfAreaRef.current.scrollTop = pdfAreaRef.current.scrollTop - event.movementY;
        }
    }, []);
    const handleMouseDown = useCallback((event: HTMLElementEventMap["mousedown"]) => {
        const leftMouse = (event.buttons & 1) > 0;
        const rightMouse = (event.buttons & 2) > 0;
        const wheel = (event.buttons & 4) > 0;

        if (wheel && !leftMouse && !rightMouse) {
            setIsPageMoving(true);
        }
    }, []);
    const handleMouseUp = useCallback((event: HTMLElementEventMap["mouseup"]) => {
        setIsPageMoving(false); //page move should stop if any mouse button goes up
    }, []);
    const handleMouseLeave = useCallback(() => {// end pagemove if editor bounds are left
        setIsPageMoving(false);
    }, []);

    //utility methods
    const scrollToPosition: (number, number, ?string) => void = useCallback((left: number, top: number, behaviour: ?string= "smooth") => {
        if (!!pdfAreaRef.current) {
            pdfAreaRef.current.scroll({
                top: top,
                left: left,
                behavior: behaviour,
            });
        }
    }, []);

    //animation of scroll position and zoom, zoomScrollValues is never used, since all relevance stuff is done in the on change method
    const [ zoomScrollValues, zoomScrollAnimation ] = useSpring(() => ({
        zoom: 1,
        scrollLeft: 0,
        scrollTop: 0,
        onChange: (result, spring) => {
            dispatch(setCurrentZoom({ zoom: result.value.zoom }));
            scrollToPosition(result.value.scrollLeft, result.value.scrollTop, "auto");
        }
    }));

    //save and restore view info
    const viewInfoRef = useRef<{ scrollTop: number, scrollLeft: number, zoom: number }>({
        scrollTop: 0,
        scrollLeft: 0,
        zoom: 1,
    });
    const pushViewInfo = useCallback((zoom, scrollLeft, scrollTop) => {
        viewInfoRef.current = {
            zoom: selectCurrentZoom(store.getState()),
            scrollLeft: pdfAreaRef.current?.scrollLeft || 0,
            scrollTop: pdfAreaRef.current?.scrollTop || 0,
        };

        //set pushed info as start for animation
        zoomScrollAnimation.set(viewInfoRef.current); //properties are named identicly

        //start animating to target values
        zoomScrollAnimation.start({
            zoom: zoom,
            scrollLeft: scrollLeft,
            scrollTop: scrollTop,
        });
    }, []);
    const popViewInfo = useCallback(() => {
        //set current info as start for the animation
        zoomScrollAnimation.set({
            zoom: selectCurrentZoom(store.getState()),
            scrollLeft: pdfAreaRef.current?.scrollLeft || 0,
            scrollTop: pdfAreaRef.current?.scrollTop || 0,
        });

        //start animating back to previous view
        zoomScrollAnimation.start(viewInfoRef.current);
    }, []);

    //calculate current page size
    const pageBounds = useSelector(selectCurrentPageBounds);

    const realignPage = useCallback(() => {
        //set current info as start for the animation
        zoomScrollAnimation.set({
            zoom: selectCurrentZoom(store.getState()),
            scrollLeft: pdfAreaRef.current?.scrollLeft || 0,
            scrollTop: pdfAreaRef.current?.scrollTop || 0,
        });

        //calculate target zoom
        const targetWidth = pdfAreaRef.current.getBoundingClientRect().width;
        const targetHeight = pdfAreaRef.current.getBoundingClientRect().height;
        const page: PageData = selectPagesOrdered(store.getState())[selectCurrentPage(store.getState())];

        const goalZoom = Math.min( targetWidth / (1.25 * page.width), targetHeight / (1.25 * page.height));

        zoomScrollAnimation.start({
            zoom: goalZoom,
            scrollLeft: .4 * targetWidth + page.width * goalZoom / 2,
            scrollTop: .4 * targetHeight + page.height * goalZoom / 2,
        });
    }, []);

    //dispatch load page
    useEffect(() => { //dispatch load command every time the page number changes
        if (!!currentPage && pdfAreaPresent) {
            dispatch(loadPageElements(currentPageIndex));
            realignPage();
        }
    }, [ currentPageIndex, !!currentPage, pdfAreaPresent ]);

    useEffect(() => {//when page is changed, close the sidebar
        dispatch(setSideBarExpanded({ expanded: false }));
    }, [ currentPageIndex ])

    //realign page when sidebar opens
    const readingOrderShown = useSelector(state => selectSideBarExpanded(state) && selectSideBarTab(state) === SideBarTabReadingOrderConfig.id);
    useEffect(() => {
        if (readingOrderShown) {
            realignPage();
        }
    }, [ readingOrderShown ]);

    if (!loaded || !currentPage) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <PageSVGProvider>
            <div
                style={{
                    position: "relative",
                    flexGrow: 1,

                    display: "flex",
                    flexDirection:"row",
                    overflow: "hidden",
                }}
            >
                {/* View document and contentblock area */}
                <div
                    style={{
                        flexGrow: "1",

                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    <SegmentationTaskProgressDisplay/>
                    {/* pdf container */}
                    <div
                        ref={onPdfAreaChanged}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseLeave}
                        onMouseMove={handleMouseMove}
                        style={{
                            position: "absolute",
                            flexGrow: "1",
                            overflow: "scroll",
                            width: "100%",
                            height: "100%",
                            maxWidth: "100%",
                            maxHeight: "100%",
                        }}
                    >
                        <div
                            ref={onEditingAreaChanged}
                            style={{
                                position: "relative",

                                //give 10% of visibility in each direction
                                width: `${pageBounds.width + 1.8 * pdfAreaBounds.width}px`,
                                height: `${pageBounds.height + 1.8 * pdfAreaBounds.height}px`,

                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                        <span
                            className={TargetPdfArea.className() /* Tutorials find elements by className */}
                            style={{
                                display: "flex",
                                position: "relative",
                            }}
                        >
                            {currentPage && <PageSvg page={currentPageIndex} zoom={currentZoom} onLoadChange={setPageSvgLoading}/>}
                            {currentPage && !pageSvgLoading && <ContentBlockRenderer isPageMove={isPageMoving}/>}
                            {currentPage && !pageSvgLoading && <ReadingOrderOverlay/>}
                        </span>
                            {/*<ContentBlockEditMenu*/}
                            {/*    editingAreaHeight={pdfAreaBounds.height * 1.8 + pageBounds.height}*/}
                            {/*    editingAreaWidth={pdfAreaBounds.width * 1.8 + pageBounds.width}*/}
                            {/*    editingWindowHeight={pdfAreaBounds.height}*/}
                            {/*    editingWindowWidth={pdfAreaBounds.width}*/}
                            {/*    onPushView={pushViewInfo}*/}
                            {/*    onPopView={popViewInfo}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    {/* Hotzone for Pagination and Zoom */}
                    <div
                        className={`${styles.hotZone} ${TargetHotzone.className()}`}
                    >
                        <Pagination
                            count={document.numPages}
                            shape={"rounded"}
                            onChange={handlePaginationChange}
                        />
                        <IconButton
                            onClick={() => {
                                const currentZoom = selectCurrentZoom(store.getState());
                                const newZoom = currentZoom + .1;
                                dispatch(setCurrentZoom({zoom: newZoom}));
                            }}
                        >
                            <ZoomIn/>
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                const currentZoom = selectCurrentZoom(store.getState());
                                const newZoom = currentZoom - .1;
                                dispatch(setCurrentZoom({zoom: newZoom}));
                            }}
                        >
                            <ZoomOut/>
                        </IconButton>
                        <IconButton
                            onClick={realignPage}
                        >
                            <CenterFocusWeak/>
                        </IconButton>
                    </div>
                </div>

                {/* edit menu */}
                <NewContentBlockEditMenu/>

                {/* expandable/collapsable sidebar  */}
                <SideBarDrawer/>

                {/* Tutorials */}
                <BasicEditorTutorial/>
                <ReadingOrderTutorial/>
                <StartupTutorial/>
            </div>
        </PageSVGProvider>
    );
}
