// @flow

import {
    Button,
    Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    InputAdornment,
    OutlinedInput, Switch,
    Typography
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { AttachFile, Height } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDocumentIndex, selectDocumentLoaded } from "../../redux/slice/DocumentSlice";
import { createDocument } from "../../redux/action";
import type { DocumentIndex } from "../../redux/data/document/DocumentTypes";
import acc_doc_logo from "../../img/ACCESSible-DOCs-Logo.png";
import acc_doc_neg from "../../img/ACCESSible-DOCs-Logo_negativ.png";
import kit_logo from "../../img/kit_logo_de_farbe_positiv.png";
import workflow_img from "../../img/workflow.png";
import workflow_neg from "../../img/workflow-negativ.png";
import { useTheme } from '@material-ui/core/styles';
import {TargetHomePageLogo, TargetHomePageTerms} from "../blockeditor/tutorials/targets";
import {HomePageTutorial, HomePageTutorialId} from "../blockeditor/tutorials/HomePageTutorial";
import {setRunTutorial} from "../../redux/slice/EditorSlice";
import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {DisplayLanguageSelector} from "../../common/element/displaylanguage/DisplayLanguageSelector";
import {ContactInfo} from "./ContactInfo";
import {DropzoneArea} from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles'

type HomeViewContentProps = {};

const useStyles = makeStyles({
  enabledButton: {
    background: '#4064AD!important',
    '&:disabled': {
      background: '#cfcfcf!important',
    }
  },
});

/**
 * Home view of the application. Presents the user with an upload dialog and cookie and privacy notice (cookie notice not implemented yet)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function HomeViewContent(props: HomeViewContentProps) {
    const history = useHistory();

    // theming
    const theme = useTheme();
    const bg_col = theme.palette.background.default;

    // language
    const { t, i18n } = useTranslation();

    // cookies
    const [cookies, setCookie, removeCookie] = useCookies();

    const toggleDevModeCookie = () => {
        cookies["devMode"]? removeCookie("devMode") : setCookie("devMode", true, {path: "/"});
    }
    const devModeEnabled = !!cookies["devMode"];

    //state management
    const [ currentFile: File, setCurrentFile: (File | null) => void ] = useState<File | null>(null);
    const [ isPersistent: boolean, setPersistent: boolean => void ] = useState<boolean>(true);
    const [ termsAccepted: boolean, setTermsAccepted: boolean => void ] = useState<boolean>(true);
    const [ isCreating: boolean, setIsCreating: boolean => void ] = useState<boolean>(false);
    const [ showContacts: boolean, setShowContacts: boolean => void ] = useState<boolean>(false);

    //redux
    const dispatch = useDispatch();
    const loaded = useSelector(selectDocumentLoaded);
    const documentIndex: DocumentIndex = useSelector(selectDocumentIndex);
    useEffect(() => {
            if (loaded) {
                setIsCreating(false);
                history.push(`/loading/${documentIndex.document}`);
            }
        }, [ loaded, documentIndex ]);

    const toggleContact = () => {
        setShowContacts(!showContacts);
    }

    const [disabled, setDisabled] = React.useState(false)
    const classes = useStyles()
    const toggleDisabled = () => setDisabled(prev => !prev)


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#E4E4E4",
            }}
        >
            <div
                id={"head"}
                style={{
                    borderBottomStyle: "solid",
                    padding: "20px",
                    alignItems: "left",
                    width: "800px"
                 }}
            >
                <div
                    style={{
                        textAlign: "left",
                        float: "left"
                    }}>
                <img src={acc_doc_logo} width={"350px"} height={"auto"} alt="Logo of ACCESSIBLE DOCs"/>
                </div>
                <div style={{textAlign: "right"}}>
                    <DisplayLanguageSelector/>
                </div>
                <div style={{textAlign: "right"}}>
                    <FormControlLabel
                        control={<Switch checked={devModeEnabled} onChange={toggleDevModeCookie}/>}
                        label={"Dev Mode Cookie"}
                        labelPlacement="start"
                    />
                </div>
            </div>

            <div id={"infobox"}
                 style={{
                    fontSize: "16px",
                    backgroundColor: "#fff",
                    color: "red",
                    maxWidth: "800px",
                    margin: "20px auto",
                    padding: "20px",
                    lineHeight: 1.5,
                    borderStyle: "solid",
                    borderRadius: ".5rem"
                 }}>
                {t('homePage.infobox')}
            </div>

            <div
                id={"body"}
                style={{
                    margin: "0",
                    padding: "20px",
                    width: "800px",
                    fontFamily: "Arial, sans-serif",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    textAlign: "justify"
                }}
            >
                <h2>{t('homePage.welcome')}</h2>
                <Typography>
                    <Trans i18nKey="homePage.descriptionText1" components={{ 1: <br /> }} />
                </Typography>
                <br/><br/>
                <div style={{textAlign: "center"}} ><img src={workflow_img} style={{textAlign: "center"}} width={"80%"} height={"auto"} alt=""/>
                <br/><br/>
                </div>
                <Typography>
                    {t('homePage.stepsBelow')}
                </Typography>
                <ol style={{lineHeight: 1.5}} >
                    <li>{t('homePage.stepsLI1')}</li>
                    <li>{t('homePage.stepsLI2')}</li>
                    <li>{t('homePage.stepsLI3')}</li>
                    <li>{t('homePage.stepsLI4')}</li>
                </ol>

                <div id={"upload-mask"}
                    style={{
                        /*display: "flex",
                        flexDirection: "column",*/
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: ".5rem",
                        backgroundColor: "#c8dadf",
                        padding: "20px 20px",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        /*width: "100%",*/
                        textAlign: "center",
                        marginBottom: "0.5rem"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            justifyContent: "center",
                            margin: "0.5rem",
                            padding: "0.5rem",
                        }}
                    >
                        <DropzoneArea
                            onChange={e => setCurrentFile(e[0])}
                            acceptedFiles={['application/pdf']}
                            filesLimit={1}
                            maxFileSize={52428800}
                            dropzoneText={t('homePage.uploadInstructions')}
                            showFileNames={true}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                            previewChipProps={{classes: { root: classes.previewChip } }}
                            previewText={t('homePage.uploadfiles')}
                        />
                        {/*
                        <FormControl variant={"outlined"}>
                            <OutlinedInput
                                onChange={e => setCurrentFile(e.target.files[0])}
                                type={"file"}
                                inputProps={{
                                    accept: ".pdf",
                                }}
                                startAdornment={
                                    <InputAdornment position={"start"}>
                                        <AttachFile color={"inherit"}/>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        */}
                        <FormControlLabel control={<Checkbox checked={isPersistent} onChange={e => setPersistent(e.target.checked)} disabled/>} label={"persistent"}/>

                    </div>
                    {/* @TODO: re-enable once terms and conditions exist
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0.5rem",
                            padding: "0.5rem",
                        }}
                    >
                        <Typography>
                            Insert Terms of service link here or in label
                        </Typography>
                        <FormControlLabel control={<Checkbox checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)}/>}
                                          label={"Accept Terms and Conditions"} className={TargetHomePageTerms.className()}/>
                    </div>
                    */}

                    <Button
                        color={"primary"}
                        className={classes.enabledButton}
                        style={{
                            align: "center",
                            borderRadius: ".5rem", 
                            boxSizing: "border-box", 
                            color: "white", 
                            padding: "1rem 1.75rem",
                            cursor: "pointer",
                            width: "100%"}} 
                        disabled={!termsAccepted || !currentFile} onClick={() => {
                        setIsCreating(true);
                        dispatch(createDocument(currentFile, isPersistent));
                        }}
                        variant={"outlined"}
                    >
                        {t('homePage.startProcess')}
                    </Button>
                    {isCreating && <div style={{textAlign: "center"}}><CircularProgress/> </div>}


                    {/*<div
                        id="progress_button"
                        style={{
                            backgroundColor:"#4064AD",
                            borderRadius: ".5rem",
                            boxSizing: "border-box",
                            color: "white",
                            padding: "1rem 1.75rem",
                            cursor: "pointer",
                            width: "100%"}}
                        color={"primary"}
                        disabled={!termsAccepted || !currentFile} onClick={() => {
                        setIsCreating(true);
                        dispatch(createDocument(currentFile, isPersistent));
                    }}>
                        {t('homePage.startProcess')}
                    </div>*/}

                </div>

               <Typography style={{
                    cursor: "pointer",
                    textAlign: "center",
                    marginTop: "1em"
                }} onClick={toggleContact}><b><u>{t('homePage.contact')}</u></b></Typography>
                {showContacts && <ContactInfo/>}

            </div>
            <div
            id="footer"
            style={{
                textAlign: "center",
                fontSize: "10pt",
                padding: "20px",
                borderTop: "2px solid"
            }}>
            © 2021 - 2023 <a href="https://www.access.kit.edu">Zentrum für digitale Barrierefreiheit und Assistive Technologien (ACCESS@KIT)</a> - <a href="https://www.kit.edu">Karlsruher Institut für Technologie (KIT)</a>
        </div>
        </div>
    );
}

