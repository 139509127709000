// @flow

import { forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { selectContentBlockEditing } from "../../../redux/slice/EditorSlice";
import { useEditableContentBlock } from "../../../redux/hook/useContentBlock";

export const ContentBlockEditorChemicalFormula = forwardRef((props, ref) => {
    /*
        Fetch data
     */
    const contentBlockIndex = useSelector(selectContentBlockEditing);
    const contentBlock = useEditableContentBlock(contentBlockIndex);

    /*
        State management
     */
    // TODO: add states for data the user can edit

    /*
        imperative handle to trigger saving of data
     */
    useImperativeHandle(ref, () => ({
        save: () => {/* submit gathered data here */},
    }), [])

    return (
        <div/>
    );
});
