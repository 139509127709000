// @flow
import {Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export function HeadingDescriptionHelperFrame() {
    const {t, i18n} = useTranslation();
    return (
        <div>
            <ol>
                <li>{t('tutorials.headingAltTexts.li1')}</li>
                <li>{t('tutorials.headingAltTexts.li2')}</li>
                <li>{t('tutorials.headingAltTexts.li3')}</li>
                <li>{t('tutorials.headingAltTexts.li4')}</li>
            </ol>
            <Typography>{t('tutorials.headingAltTexts.exampleBelow')}</Typography>
            <ol>
                <li>{t('tutorials.headingAltTexts.exTitle')}</li>
                <li>{t('tutorials.headingAltTexts.exChap1')}</li>
                <ol>
                    <li>{t('tutorials.headingAltTexts.exChap1Sec1')}</li>
                    <li>{t('tutorials.headingAltTexts.exChap1Sec2')}</li>
                    <ol>
                        <li>{t('tutorials.headingAltTexts.exChap1Sec2Subsec1')}</li>
                    </ol>
                </ol>
                <li>{t('tutorials.headingAltTexts.exChap2')}</li>
                <ol>
                    <li>{t('tutorials.headingAltTexts.exChap2Sec1')}</li>
                    <ol>
                        <li>{t('tutorials.headingAltTexts.exChap2Sec1Subsec1')}</li>
                    </ol>
                </ol>
            </ol>
        </div>
    )
}
