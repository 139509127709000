// @flow

import { useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadDocument } from "../action";
import { selectDocumentIndex, selectDocumentLoaded } from "../slice/DocumentSlice";
import { documentIndexMatch } from "../data/document/DocumentUtil";

/**
 * Makes sure that the document given in the url is loaded using redux.
 * This hook should only be used in the toplevel-component of a view that requires the document to be loaded
 * (e.g. BlockEditorContent)
 * @return whether the document is loaded
 */
export function useDocumentView() {
    const match = useRouteMatch();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDocument(parseInt(match.params.document)));
    }, [ match.params.document ]);

    const documentLoaded = useSelector(selectDocumentLoaded);
    const documentIndex = useSelector(selectDocumentIndex);

    return documentLoaded && documentIndexMatch(documentIndex, { document: parseInt(match.params.document) });
}
