// @flow

import { createAction } from "@reduxjs/toolkit";
import type { ContentBlockData, ContentBlockIndex } from "./data/contentblock/ContentBlockTypes";
import type { LoggingSeverity } from "./app/logging/LoggingTypes";
import { DEBUG, ERROR, INFO, WARNING } from "./app/logging/LoggingTypes";
import type {AccDocsIssueIndex} from "./data/accdocsissues/AccDocsIssueTypes";

// logging
export const logMessage = createAction('log', (severity: LoggingSeverity, message: string, origin: string, timeout: number=10000) => ({
    payload: {
        severity: severity,
        message: message,
        origin: origin,
        timeout: timeout,
    },
}));

export const logDebug = (message: string, origin: string, timeout: number =10000) => logMessage(DEBUG, message, origin, timeout);

export const logInfo = (message: string, origin: string, timeout: number =10000) => logMessage(INFO, message, origin, timeout);

export const logWarning = (message: string, origin: string, timeout: number =10000) => logMessage(WARNING, message, origin, timeout);

export const logError = (message: string, origin: string, timeout: number =10000) => logMessage(ERROR, message, origin, timeout);

//editor data
export const startContentBlockDrag = createAction('enhance/editor/startdrag', (index: ContentBlockIndex, dragMove: boolean, dragVertical: boolean, dragHorizontal: boolean) => ({
    payload: {
        index: index,
        modeVertical: dragVertical,
        modeHorizontal: dragHorizontal,
        modeMove: dragMove,
    },
}));

export const startNewDrag = createAction('enhance/editor/startdrag', () => ({
    payload: {
        index: null,
        modeVertical: true,
        modeHorizontal: true,
    },
}));

// managing backend data

/**
 * Uses the given file to create a document on the backend and load it once its available
 */
export const createDocument = createAction('enhance/document/create', (file: File, isPersistent: boolean) => ({
    payload: {
        file: file,
        isPersistent: isPersistent,
    },
}));

/**
 * Loads the document with the given id
 */
export const loadDocument = createAction('enhance/document/load', (documentId: number) => ({
    payload: {
        id: documentId,
    },
}));

/**
 * Unloads the currently loaded document
 */
export const unloadDocument = createAction('data/document/unload'); //only one document is loaded at a time, so no need to specify which to unload

/**
 * Loads the given page of the currently loaded document
 */
export const loadPageElements = createAction('enhance/page/load', (pageNumber: number) => ({
    payload: {
        pageNumber: pageNumber,
    },
}));

//manipulating backend data

/**
 * Updates the document metadata of the currently loaded document with the given fields.
 * Possible fields:
 * - title: the embedded title of the document
 * - author: the author of the document
 * - language: the language used in the document
 * - subject: what the document is about?
 * - keywords: what is the document associated with?
 */
export const updateDocumentMetadata = createAction('data/document/metadata/update', (metadata: $Shape<{
    title: ?string;
    author: ?string;
    language: ?string;
    subject: ?string;
    keywords: ?(string[]);
}>) => ({
    payload: {
        ...metadata,
    },
}));

/**
 * Creates a new Contentblock with the given initial data:
 * - left
 * - top
 * - width
 * - height
 * - ordinal (can be omitted)
 * - details (any of HeadingDetails, TextBodyDetails, ExpressionDetails, TableDetails, ImageDetails or ChemicalFormulaDetails)
 */
export const createBlock = createAction('enhance/block/create', (initial: ContentBlockData) => ({
    payload: {
        initial: initial,
    },
}));

/**
 * Updates the bounds of the contentblock:
 * - left
 * - top
 * - width
 * - height
 */
export const updateBlockBounds = createAction('enhance/block/bounds/update', (index: ContentBlockIndex, update: $Shape<{
    left: number,
    top: number,
    width: number,
    height: number,
}>) => ({
    payload: {
        index: index,
        bounds: update,
    },
}));

/**
 * Updates the details of the contentblock
 */
export const updateBlockDetails = createAction('enhance/block/details/update', (pageNumber: number, contentBlock: number, update: $Shape<ContentBlockData["details"]>) => ({
    payload: {
        pageNumber: pageNumber,
        contentblock: contentBlock,
        details: update,
    }
}));

/**
 * Updates the ordinal of the given contentblock
 */
export const updateBlockOrdinal = createAction('enhance/block/ordinal/update', (pageNumber: number, contentBlock: number, ordinal: number) => ({
    payload: {
        pageNumber: pageNumber,
        contentBlock: contentBlock,
        ordinal: ordinal,
    },
}));

/**
 * Deletes the given block
 */
export const deleteBlock = createAction('enhance/block/delete', (pageNumber: number, contentBlock: number) => ({
    payload: {
        pageNumber: pageNumber,
        contentBlock: contentBlock,
    },
}));

/**
 * Updates the ordinal of the given pdfelement
 */
export const updatePdfElementOrdinal = createAction('enhance/pdfelement/ordinal/update', (pageNumber: number, pdfelement: number, ordinal: number) => ({
    payload: {
        pageNumber: pageNumber,
        pdfelement: pdfelement,
        ordinal: ordinal,
    },
}));


export const updateAccDocsIssue = createAction('data/accDocsIssue/update', (index: AccDocsIssueIndex, issueId: number, done: boolean) => ({
    payload: {
        index: index,
        //issueNumber: issueNumber,
        done: done,
        // todo: other fields we may want to update?
    },

}));
