// @flow

import {
    closestCorners,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Divider, Typography } from "@material-ui/core";
import { useContentBlockIndicesOrdered, useContentBlocksOrdered } from "../../../redux/hook/useContentBlock";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentPage } from "../../../redux/slice/EditorSlice";
import { ReadingOrderSidebarItem, ReadingOrderSidebarItemDragOverlay } from "./ReadingOrderSidebarItem.js";
import { updateBlockOrdinal } from "../../../redux/action";
import { selectDocumentIndex } from "../../../redux/slice/DocumentSlice";
import type { SideBarTabConfig } from "./SideBarDrawer";
import { Sort } from "@material-ui/icons";
import { TargetReadingOrderList, TargetReadingOrderTray } from "../tutorials/targets";
import styles from "./SideBarTabReadingOrder.module.css";
import {useTranslation} from "react-i18next";


/**
 * Config of the sidebar tab which displays the reading order
 * @type {{name: string, icon: (function(*): *), id: string, render: SideBarTabReadingOrder}}
 */
export const SideBarTabReadingOrderConfig: SideBarTabConfig = {
    id: "readingorder",
    name: "Reading Order",
    trayTarget: TargetReadingOrderTray.className(),
    icon: props => <Sort {...props}/>,
    render: SideBarTabReadingOrder,
}
/**
 * Renders a list of contentblocks which constitutes the reading order
 * @returns {JSX.Element}
 * @constructor
 */
export function SideBarTabReadingOrder() {
    const dispatch = useDispatch();
    const pageNumber = useSelector(selectCurrentPage);
    const doc = useSelector(selectDocumentIndex).document;
    const contentBlockIndices = useContentBlockIndicesOrdered();

    const [activeId, setActiveId] = useState(null);
    const [activeCbi, setActiveCbi] = useState(null);

    let contentBlocks = useContentBlocksOrdered();
    const { t, i18n } = useTranslation();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragStart = useCallback((event) => {
        console.log("drag start:", event);
        const {active} = event;
        setActiveId(active.id);
        setActiveCbi()
    });

    const handleDragEnd = useCallback((event) => {

        const {active, over} = event;
        /*console.log("dragend: ",event);
        const a = JSON.parse(active).contentblock;
        const o = JSON.parse(over).contentblock;
        console.log("a, o: ", a, o);
         */
        if (active.id !== over.id) {
            const active_index = contentBlockIndices.findIndex(elem => elem.contentblock === active.id);
            const over_index = contentBlockIndices.findIndex(elem => elem.contentblock === over.id);
            // @todo: make sure it works in both directions (active < over and active > over)

            if (active_index > over_index) {

                const startpoint = over_index;
                const endpoint = active_index - 1;

                for (let i = startpoint; i <= endpoint; i++) {

                    dispatch((updateBlockOrdinal(pageNumber, contentBlockIndices[i].contentblock, i+1)));
                }
                dispatch(updateBlockOrdinal(pageNumber, active.id, over_index));
            } else {

                const startpoint = active_index + 1;
                const endpoint = over_index;

                for (let i= startpoint; i <= endpoint; i++) {
                    dispatch(updateBlockOrdinal(pageNumber, contentBlockIndices[i].contentblock, i-1));
                }
                dispatch(updateBlockOrdinal(pageNumber, active.id, over_index));
            }
            /*
            const startpoint = active_index < over_index ? active_index : over_index;
            const endpoint = active_index < over_index ? over_index : active_index;
            const origin_ordinal = startpoint;
            console.log("startpoint: ", startpoint, "; endpoint: ", endpoint, "; origin_ordinal: ", origin_ordinal);
            if (active_index < over_index) {
                for (let i = startpoint; i < endpoint; i++) {
                    console.log("i: ", i, "; contentBlockIndices[i]: ", contentBlockIndices[i], "; contentBlocks[i]: ");
                    dispatch(updateBlockOrdinal(pageNumber, contentBlockIndices[i].contentblock, i));
                }
                dispatch(updateBlockOrdinal(pageNumber, active.id, endpoint));
            } else {
                for (let i = startpoint; i < endpoint; i++) {
                    console.log("i: ", i, "; contentBlockIndices[i]: ", contentBlockIndices[i], "; contentBlocks[i]: ");
                    dispatch(updateBlockOrdinal(pageNumber, contentBlockIndices[i].contentblock, i+1));
                }
                dispatch(updateBlockOrdinal(pageNumber, active.id, ));
            }*/


        }

        setActiveId(null);

    }, [pageNumber, contentBlockIndices, dispatch, activeId]);

    return (
        <div className={`${styles.container} ${TargetReadingOrderList.className()}`}>
            <Typography variant={"h6"}>{t('sideBar.ReadingOrder.readingOrder')}</Typography>
            <Divider />
            <div className={styles.readingOrderList}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCorners}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={contentBlockIndices}
                        strategy={verticalListSortingStrategy}
                    >
                        {/* key and id both cannot be of type object, thus we need to use a unique identifier
                            in this case contentblock is a unique number */}
                        {contentBlockIndices.map((cbi) =>
                            <ReadingOrderSidebarItem
                                contentBlockIndex={cbi}
                                key={cbi.contentblock}
                                id={cbi.contentblock}
                                //id={JSON.stringify(cbi)}
                            />
                        )}
                    </SortableContext>
                    <DragOverlay>
                        {activeId ? <ReadingOrderSidebarItemDragOverlay id={activeId} cbi={activeCbi} /> : null}
                    </DragOverlay>
                </DndContext>
            </div>
        </div>
    );
}

