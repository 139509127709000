// @flow

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ConnectionErrorPayload = {
    error: string,
};

const initialState: {
    connecting: boolean,
    connected: boolean,
    stompSession: string | null,
    connectionError: string | null,
} = {
    connecting: false,
    connected: false,
    stompSession: null,
    connectionError: null,
};

const SocketSlice = createSlice({
    name: 'socket',
    initialState: initialState,
    reducers: {
        connecting: (state) => {
            state.connecting = true;
            state.connected = false;
            state.stompSession = null;
        },
        connected: (state) => {
            state.connecting = false;
            state.connected = true;
            state.connectionError = null; //stomp should always connect last
            state.session = null;
        },
        setSession: (state, action: PayloadAction<{ session: string}>) => {
            state.session = action.payload.session;
        },
        connectionError: (state, action: PayloadAction<ConnectionErrorPayload>) => {
            state.connecting = false;
            state.connected = false;
            state.connectionError = action.payload.error;
        },
    }
});

export const { connecting, connected, setSession, connectionError } = SocketSlice.actions;
export default SocketSlice.reducer;

/**
 * Checks whether the sockets are connecting currently
 * @param state
 * @returns {boolean}
 */
export function selectConnecting(state): boolean {
    return state.socket.connecting;
}

/**
 * Checks whether the sockets are currently connected
 * @param state
 * @returns {*}
 */
export function selectConnected(state): boolean {
    return state.socket.connected;
}

/**
 * Checks the last error thrown with the current or last connection
 * @param state
 * @returns {null|string|*}
 */
export function selectSocketError(state): string | null {
    return state.socket.connectionError;
}

/**
 * Selects the session currently used with the websockets
 * @param state
 * @returns {*}
 */
export function selectSocketSession(state): string | null {
    return state.socket.session;
}
