// @flow

import type { DocumentData, DocumentIndex, DocumentSTOMPPayload } from "./DocumentTypes";

/**
 * Converts STOMP payload to document data
 * @param payload
 * @returns {{numPages, fileName, keywords: *, author: (string|string|normalize.Person|PackageJson.Person|*), subject: *, filePath, isPersistent, language: *, title: *, tasks: (*[]|null)}}
 */
export function documentPayloadToData(payload: DocumentSTOMPPayload): DocumentData {
    return {
        //copy metadata
        title: payload.metadata?.title,
        author: payload.metadata?.author,
        language: payload.metadata?.language,
        subject: payload.metadata?.subject,
        keywords: payload.metadata?.keywords,

        //copy flat fields
        fileName: payload.fileName,
        filePath: payload.filePath,
        numPages: payload.numPages,
        isPersistent: payload.isPersistent,
        metadataExtracted: payload.metadataExtracted,

        //copy tasks in new list to avoid accidental modifications
        tasks: !!payload.tasks ? [ ...payload.tasks ] : null,
    };
}

/**
 * Converts data of a document to a stomp message payload to send to the backend
 * @param data
 * @returns {{metadata: ({keywords, author, subject, language, title}|null), isPersistent}}
 */
export function documentDataToPayload(data: DocumentData): DocumentSTOMPPayload {
    const hasMetadata: boolean = !!data.title || !!data.author || !!data.language || !!data.subject || !!data.keywords;

    return {
        metadata: hasMetadata ? {
            title: data.title,
            author: data.author,
            language: data.language,
            subject: data.subject,
            keywords: data.keywords,
        } : null,

        //commented lines will be ignored by the backend, might aswell leave them out
        fileName: data.fileName,
        //filePath: data.filePath,
        //numPages: data.numPages,
        isPersistent: data.isPersistent,

        // tasks: data.tasks,
    };
}

/**
 * Checks if the two supplied document indices match
 * @param a
 * @param b
 * @returns {boolean}
 */
export function documentIndexMatch(a: DocumentIndex, b: DocumentIndex): boolean {
    return a.document === b.document;
}
