// @flow

import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentMetadata } from "../action";
import { selectDocument } from "../slice/DocumentSlice";
import type { DocumentData } from "../data/document/DocumentTypes";

type Metadata = {
    title: string | null,
    author: string | null,
    language: string | null,
    subject: string | null,
    keywords: string[],
}

/**
 * Fetches document metadata from the redux cache. If this metadata is edited directly, the changes will not be applied to the store and not be sent to the backend.
 * This method is intended to be used when you only need the data but not modify it.
 * @returns {Metadata | null}
 */
export function useMetadata(): Metadata | null {
    const currentDocument = useSelector(selectDocument);
    return useMemo<Metadata>(() => !!currentDocument ? {
        title: currentDocument.title,
        author: currentDocument.author,
        subject: currentDocument.subject,
        language: currentDocument.language,
        keywords: currentDocument.keywords,
    } : null, [ currentDocument ]);
}

type EditableMetadata = Metadata & {
    addKeyword: string => void,
    removeKeyword: string => void,
    patch: $Shape<EditableMetadata> => void,
};

/**
 * Fetches the document metadata from redux and uses listeners to automaticly track changes.
 * Use this hook only if you need to modify the data
 * <blockquote>
 *     NEVER USE DELETE ON ANY PROPERTY
 * </blockquote>
 * - metadata#addKeyword() adds a new keyword
 * - metadata#removeKeyword() removes an existing keyword
 * - metadata#patch() changes all provided properties automaticly (and at once, so the changes wont cause multiple messages)
 */
export function useEditableMetadata(): EditableMetadata | null {
    const dispatch = useDispatch();

    const onChangeTitle = useCallback<(string | null) => void>(title => dispatch(updateDocumentMetadata({ title: title })), [ dispatch ]);
    const onChangeAuthor = useCallback<(string | null) => void>( author => dispatch(updateDocumentMetadata({ author: author })), [ dispatch ]);
    const onChangeLanguage = useCallback<(string | null) => void>(language => dispatch(updateDocumentMetadata({ language: language })), [ dispatch ]);
    const onChangeSubject = useCallback<(string | null) => void>(subject => dispatch(updateDocumentMetadata({ subject: subject })), [ dispatch ]);
    const onChangeKeywords = useCallback<string[] => void>(keywords => dispatch(updateDocumentMetadata({ keywords: keywords })), [ dispatch ]);
    const onPatch = useCallback<$Shape<EditableMetadata> => void>(metadata => dispatch(updateDocumentMetadata(metadata)), [ dispatch ]);

    const currentDocument: DocumentData | null = useSelector(selectDocument);
    return useMemo(() => {
        if (!!currentDocument) {
            const result = {
                titleValue: currentDocument.title,
                get title(): string | null {
                    return this.titleValue;
                },
                set title(value: string | null) {
                    this.titleValue = value;
                    onChangeTitle(value);
                },

                authorValue: currentDocument.author,
                get author(): string | null {
                    return this.authorValue;
                },
                set author(value: string | null) {
                    this.authorValue = value;
                    onChangeAuthor(value);
                },

                languageValue: currentDocument.language,
                get language(): string | null {
                    return this.languageValue;
                },
                set language(value: string | null) {
                    this.languageValue = value;
                    onChangeLanguage(value);
                },

                subjectValue: currentDocument.subject,
                get subject(): string | null {
                    return this.subjectValue;
                },
                set subject(value: string | null) {
                    this.subjectValue = value;
                    onChangeSubject(value);
                },

                keywordsValue: currentDocument.keywords,
                get keywords(): string[] {
                    return this.keywordsValue;
                },
                set keywords(value: string[]) {
                    this.keywordsValue = value;
                    onChangeKeywords(value);
                },

                addKeyword: function (keyword: string) {
                    if (this.keywords.findIndex(other => keyword === other) < 0) { // keyword is not present
                        this.keywords = [ ...this.keywords, keyword ];
                    }
                },

                removeKeyword: function (keyword: string) {
                    if (this.keywords.findIndex(other => keyword === other) >= 0) { // keyword is present
                        this.keywords = this.keywords.filter(other => other !== keyword);
                    }
                },

                patch: function (value: $Shape<EditableMetadata>) {
                    this.titleValue = value.title || this.titleValue;
                    this.authorValue = value.author || this.authorValue;
                    this.subjectValue = value.subject || this.subjectValue;
                    this.languageValue = value.language || this.languageValue;
                    this.keywordsValue = value.keywords || this.keywordsValue;

                    onPatch(value);
                },
            };
            result.addKeyword = result.addKeyword.bind(result);
            result.removeKeyword = result.removeKeyword.bind(result);
            result.patch = result.patch.bind(result);

            return result;
        }

        return null;
    }, [ currentDocument, onChangeTitle, onChangeAuthor, onChangeLanguage, onChangeSubject, onChangeKeywords, onPatch ]);
}
