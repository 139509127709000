// @flow

import { useSelector } from "react-redux";
import { selectContentBlockEditing } from "../../../redux/slice/EditorSlice";
import { useEditableContentBlock } from "../../../redux/hook/useContentBlock";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// ace editor for latex code
// there might be the option to include autocomplete code snippets for latex in the future: https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/ext-language_tools";
import { usePropertyShadow } from "../../../redux/hook/usePropertyShadow";

/*
    Styles
*/
const useStyles = makeStyles((theme) => ({
    contentBox: {
      borderRadius: 3,
      padding: 5,
    },
    contextBoxFieldset: {
      borderRadius: 3,
      padding: 5,
      fontSize: 13,
    },
    controlButton: {
      alignSelf: 'stretch',
      marginBottom: 4,
    },
    imageBoxMathjax: {
      minHeight: 100,
      backgroundColor: 'white',
      paddingLeft: 8,
    },
    mathjaxElement: {
      fontSize: 25,
    },
    explanationText: {
      fontSize: 11,
    },
    explanationSymbol: {
      height: 15,
      marginTop: 2
    }
  }));

/*
    Configuring Mathjax
    Important that extensions ams and mathtools are loaded
*/
const configMathJax = {
    loader: { load: ["[tex]/ams", "[tex]/mathtools", "ui/lazy"] },
    tex: {
      packages: { "[+]": ["ams", "mathtools"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    },
    "fast-preview": {
      disabled: true
    },
    messageStyle: "none",
  };

export const ContentBlockEditorMathExpression = forwardRef((props, ref) => {
    /*
        Fetch data
     */
    const contentBlockIndex = useSelector(selectContentBlockEditing);
    const contentBlock = useEditableContentBlock(contentBlockIndex);

    /*
        State management
     */

    const [ texSource, setTexSource ] = usePropertyShadow<string | null>(contentBlock.details.alternativeText);

    const classes = useStyles();

    /*
        Imperative handle for edit menu to trigger saving of the entered data
     */
    useImperativeHandle(ref, () => ({
        save: () => {
            contentBlock.details.alternativeText = texSource;
            contentBlock.details.segmentationConfidence = 1; // @todo: we are setting the confidence to one for now. this might not necessarily always be correct
        },
    }), [ texSource, contentBlock ]);

    return (
        <Container>
      <Box my={2}>
        <Grid container spacing={1} mb={5}>
          <Grid item xs={12}>
            <fieldset className={classes.contextBoxFieldset}>
              <legend>Dynamically rendered formula</legend>
              <Box className={classes.imageBoxMathjax} display="flex" justifyContent="flex-start">
                <div
                  style={{ display: "inline-block" }}
                >
                    <MathJaxContext version={3} config={configMathJax}>
                        <MathJax className={classes.mathjaxElement} dynamic={true}>{'$$' + texSource + '$$'}</MathJax>
                    </MathJaxContext>
                </div>
              </Box>
            </fieldset>
          </Grid>
        </Grid>
      </Box>
      <Grid container mt={2} spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ borderRadius: 3, outline: 1, outlineStyle: "solid", padding: 1 }}>
            <AceEditor
              mode="latex"
              value={texSource}
              onChange={(value, event) => setTexSource(value)}
              fontSize={15}
              height={150}
              editorProps={{ $blockScrolling: true }}
              wrapEnabled={true}
              width={"100%"}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true
              }}
            />
          </div>

        </Grid>
      </Grid>
      <Box mt={0}>
        <Typography className={classes.explanationText}><InfoOutlinedIcon className={classes.explanationSymbol} />The math environment in the above editor is arleady specified to $$. There is no need to use $ or $$.</Typography>
      </Box>
      <Grid />
    </Container>
    )
});
