// @flow

import {Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./TableDescriptionHelperFrame.module.css"

export function TableDescriptionHelperFrame() {
    const {t, i18n} = useTranslation();
    return (
        <div>
            <ol>
                <li>{t('tutorials.tableAltTexts.li1')}</li>
                <li>{t('tutorials.tableAltTexts.li2')}</li>
                <ol>
                    <li>{t('tutorials.tableAltTexts.li2-1')}</li>
                </ol>
            </ol>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <table style={styles}>
                    <tbody>
                        <tr >
                            <th style={styles}>Year</th>
                            <th style={styles}>Version</th>
                        </tr>
                        <tr>
                            <td style={styles}>1999</td>
                            <td style={styles}>v 1.0</td>
                        </tr>
                        <tr>
                            <td style={styles}>2008</td>
                            <td style={styles}>1.1</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr >
                        <th style={styles}>Year</th>
                        <td style={styles}>1999</td>
                        <td style={styles}>2008</td>
                    </tr>
                    <tr>
                        <th style={styles}>Version</th>
                        <td style={styles}>v. 1.0</td>
                        <td style={styles}>v. 1.1</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <Typography>Note the red cells indicate the heading cells.</Typography>
        </div>
    )
}
