// @flow

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ElementContainer } from "./SliceTypes";
import type { DocumentData, DocumentIndex } from "../data/document/DocumentTypes";
import { addTruthLayer, removeTruthLayer, updateGroundTruth } from "./SliceUtil";

export type DocumentLoadingPayload = {
    index: DocumentIndex,
};

export type DocumentLoadedPayload = {
    version: number,
    data: DocumentData,
};

export type DocumentUpdateUnconfirmedPayload = {
    origin: string,
    data: DocumentData,
};

export type DocumentUpdateRejectedPayload = {
    origin: string,
};

export type DocumentUpdateConfirmedPayload = {
    origin: string,
    version: number,
    data: DocumentData,
};

const documentInitialState: ElementContainer<DocumentIndex, DocumentData> = {
    index: null, // index of null indicates, that no document is being watched
    groundTruth: null, //ground truth is loaded once an index is specified
    resolvedTruth: null,
    groundTruthVersion: 0,
    truthLayers: [], //truth layers are initialized as an empty array to make handling it easier
};

/**
 * Used to save state of a specific document and connection details
 * @type {Slice<unknown, SliceCaseReducers<unknown>, string>}
 */
const DocumentSlice = createSlice({
    name: 'document',
    initialState: documentInitialState,
    reducers: {
        /*
            Documents cannot be created or deleted within the redux container, so no need for any reducers for it
         */
        loading: (state, action: PayloadAction<DocumentLoadingPayload>) => {
            state.index = { ...action.payload.index };
            state.groundTruth = null; //technicly not necessary, but this can prevent errors if somewhere else is a mistake
            state.resolvedTruth = null;
            state.groundTruthVersion = 0;
            state.truthLayers = []; //when connecting and directly after, there cannot be any truth layers
        },
        loaded: (state, action: PayloadAction<DocumentLoadedPayload>) => {
            state.groundTruth = action.payload.data;
            state.resolvedTruth = action.payload.data;
            state.groundTruthVersion = action.payload.version;
        },
        unloaded: (state) => {
            state.index = null;
            state.groundTruth = null;
            state.resolvedTruth = null;
            state.truthLayers = [];
        },
        updateUnconfirmed: (state, action: PayloadAction<DocumentUpdateUnconfirmedPayload>) => {
            addTruthLayer(state, action.payload.origin, action.payload.data);
        },
        updateRejected: (state, action: PayloadAction<DocumentUpdateRejectedPayload>) => {
            removeTruthLayer(state, action.payload.origin);
        },
        updateConfirmed: (state, action: PayloadAction<DocumentUpdateConfirmedPayload>) => {
            removeTruthLayer(state, action.payload.origin);
            updateGroundTruth(state, action.payload.data, action.payload.version);
        }
    }
});

export const { loading, loaded, unloaded, updateUnconfirmed, updateRejected , updateConfirmed } = DocumentSlice.actions;
export default DocumentSlice.reducer;

//selectors

/**
 * Selects the index of the currently loaded document
 * @param state
 * @returns {*}
 */
export function selectDocumentIndex(state): DocumentIndex | null {
    return state.documents.index;
}

export function selectDocumentLoading(state): boolean {
    return state.documents.index && !state.documents.resolvedTruth;
}

export function selectDocumentLoaded(state): boolean {
    return !!state.documents.index && !!state.documents.resolvedTruth;
}

/**
 * Selects the current state of the loaded document
 * @param state
 * @returns {DocumentData | null}
 */
export function selectDocument(state): DocumentData | null {
    return state.documents.resolvedTruth;
}
