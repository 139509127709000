// @flow


import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


export function DisplayLanguageSelector() {

    const { t, i18n } = useTranslation();

    const availableLanguages =[
        {
            "language" : "Deutsch",
            "languageCode" : "de"
        },
        {
            "language" : "English",
            "languageCode" : "en"
        }
    ];

    const changeLang = (newlang) => {
        i18n.changeLanguage(newlang, (err, t) =>{
            if (err) {
                console.log("something went wrong changing the language");
            }
        });
    }

    return (
        <div>
            <Typography style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                {t('settings.displayLanguage')}
           
            <FormControl style={{marginLeft:"1em"}} variant="standard">
                <Select
                    native
                    onChange={e => changeLang(e.target.value)}
                    label="Language"
                    inputProps={{
                        name: 'language',
                    }}
                >
                    {availableLanguages.map((item, index) =>
                        (<option key={item.languageCode} value={item.languageCode} defaultValue={item.languageCode === i18n.language}>{item.language}</option>))}
                </Select>
            </FormControl>
            </Typography>
        </div>
    )
}
