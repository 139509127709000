// @flow

import { QuestionAnswer } from "@material-ui/icons";
import styles from "./SideBarTabTutorials.module.css";
import { Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { BasicEditorTutorialId } from "../tutorials/BasicEditorTutorial";
import { setRunTutorial } from "../../../redux/slice/EditorSlice";
import type { SideBarTabConfig } from "./SideBarDrawer";
import { TargetTutorialsTray } from "../tutorials/targets";
import { ReadingOrderTutorialId } from "../tutorials/ReadingOrderTutorial";
import { StartupTutorialId } from "../tutorials/StartupTutorial";
import {useTranslation} from "react-i18next";

/**
 * Renders the tab to control behavior of interactive tutorials
 * @returns {JSX.Element}
 * @constructor
 */
export function SideBarTabTutorials() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    return (
        <div className={styles.container}>
            <Typography variant={"h6"}>
                {t('sideBar.Tutorials.Tutorials')}
            </Typography>
            <Button
                onClick={() => dispatch(setRunTutorial({ tutorial: BasicEditorTutorialId}))}
                variant={"outlined"}
                style={{width: "90%", margin: ".25rem" }}
            >
                {t('sideBar.Tutorials.Basic')}
            </Button>
            <Button
                onClick={() => dispatch(setRunTutorial({ tutorial: ReadingOrderTutorialId }))}
                variant={"outlined"}
                style={{width: "90%", margin: ".25rem" }}
            >
                {t('sideBar.Tutorials.ReadingOrder')}
            </Button>
            <Button
                onClick={() => dispatch(setRunTutorial({tutorial: StartupTutorialId}))}
                variant={"outlined"}
                style={{width: "90%", margin: ".25rem" }}
            >
                {t('sideBar.Tutorials.Startup')}
            </Button>
        </div>
    );
}

/**
 * Config for sidebar tab controling interactive tutorials
 * @type {{name: string, icon: (function(*): *), id: string, render: (function(): *)}}
 */
export const SideBarTabTutorialsConfig: SideBarTabConfig = {
    id: "tutorials",
    name: "Tutorials",
    trayTarget: TargetTutorialsTray.className(),
    icon: props => <QuestionAnswer {...props}/>,
    render: SideBarTabTutorials,
};
