// @flow

import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';


const useStylesBootstrap = makeStyles((theme) => ({
     tooltip: {
         backgroundColor: '#f5f5f9',
         color: 'rgba(0, 0, 0, 0.87)',
         maxWidth: 500,
         fontSize: theme.typography.pxToRem(16),
         border: '1px solid #dadde9',
     },
    }));

export function HtmlTooltip(props) {

    const classes = useStylesBootstrap();

    return(
      <Tooltip classes={classes} {...props}
      ></Tooltip>
    );
}
