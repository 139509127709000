// @flow

import {Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export function MathDescriptionHelperFrame() {
    const {t, i18n} = useTranslation();
    return (
        <div>
            <Typography>
                <ol>
                    <li>{t('tutorials.mathAltTexts.li1')}</li>
                    <li>{t('tutorials.mathAltTexts.li2')}</li>
                    <li>{t('tutorials.mathAltTexts.li3')}</li>
                </ol>
            </Typography>
        </div>
    )
}
