//  @flow

import {Typography} from "@material-ui/core";
import React from "react";

export function ChemplateDescriptionHelperFrame() {
    return (
        <Typography>Not implemented yet</Typography>
    )
}
