// @flow

/*
    Actions which are to be consumed by the messaging middleware and dispatched by the action enhancer middleware
 */

import type { DocumentData, DocumentIndex } from "./data/document/DocumentTypes";
import type { PageIndex } from "./data/page/PageTypes";
import type { ContentBlockData, ContentBlockIndex } from "./data/contentblock/ContentBlockTypes";
import type { PdfElementIndex } from "./data/pdfelement/PdfElementTypes";
import { createAction } from "@reduxjs/toolkit";

export const enhancedCreateDocument = createAction('data/document/create', (document: DocumentData, binary: string) => ({
    payload: {
        element: document,
        binary: binary,
    },
}))

export const enhancedLoadDocument = createAction('data/document/load', (index: DocumentIndex) => ({
    payload: {
        index: index,
    },
}));

export const enhancedLoadPageElements = createAction('data/page/load', (index: PageIndex) => ({
    payload: {
        index: index,
    },
}));

export const enhancedUnloadPageElements = createAction('data/page/unload', (index: PageIndex) => ({
    payload: {
        index: index,
    },
}));

export const enhancedCreateBlock = createAction('data/block/create', (index: PageIndex, initial: ContentBlockData) => ({
    payload: {
        index: index,
        initial: initial,
    },
}));

export const enhancedUpdateBlockBounds = createAction('data/block/bounds/update', (index: ContentBlockIndex, bounds:$Shape<{
    left: number,
    top: number,
    width: number,
    height: number,
}>) => ({
    payload: {
        index: index,
        bounds: bounds,
    },
}));

export const enhancedUpdateBlockDetails = createAction('data/block/details/update', (index: ContentBlockIndex, details: $Shape<ContentBlockData["details"]>) => ({
    payload: {
        index: index,
        details: details,
    },
}));

export const enhancedUpdateBlockOrdinal = createAction('data/block/ordinal/update', (index: ContentBlockIndex, ordinal: number) => ({
    payload: {
        index: index,
        ordinal: ordinal,
    },
}));

export const enhancedDeleteBlock = createAction('data/block/delete', (index: ContentBlockIndex) => ({
    payload: {
        index: index,
    },
}));

export const enhancedUpdatePdfElementOrdinal = createAction('data/pdfelement/ordinal/update', (index: PdfElementIndex, ordinal: number) => ({
    payload: {
        index: index,
        ordinal: ordinal,
    },
}))
