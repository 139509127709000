// @flow

import styles from "./SideBarDrawer.module.css";
import { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectContentBlockEditing,
    selectSideBarExpanded,
    selectSideBarTab,
    setContentBlockEditing,
    setSideBarExpanded,
    setSideBarTab
} from "../../../redux/slice/EditorSlice";
import { ChevronLeft } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
import { SideBarTabReadingOrderConfig } from "./SideBarTabReadingOrder";
import { SideBarTabMetadataConfig } from "./SideBarTabMetadata";
import { SideBarTabDownloadConfig } from "./SideBarTabDownload";
import { SideBarTabTutorialsConfig } from "./SideBarTabTutorials";
import { TargetSideBarComplete, TargetSideBarExpand, TargetSideBarTabs } from "../tutorials/targets";
import { SideBarTabSettingsConfig } from "./SideBarTabSettings";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {SideBarTabProgressConfig} from "./SideBarTabProgress";

export type SideBarTabConfig = {
    id: string,
    name: string,
    trayTarget: string,
    icon: any => React$Node,
    render: () => React$Node,
};

/**
 * List of all sidebar tabs. register new tabs here or take out ones that should no longer be used
 * @type {SideBarTabConfig[]}
 */
const allSideBarTabs: SideBarTabConfig[] = [
    SideBarTabProgressConfig,
    SideBarTabReadingOrderConfig,
    SideBarTabMetadataConfig,
    SideBarTabDownloadConfig,
    SideBarTabTutorialsConfig,
    SideBarTabSettingsConfig,
];

type SideBarDrawerProps = {

};
export function SideBarDrawer(props: SideBarDrawerProps) {
    const dispatch = useDispatch();
    const isEditing = !!useSelector(selectContentBlockEditing);

    const showSideBar = useSelector(selectSideBarExpanded);
    const currentTab = useSelector(selectSideBarTab);

    const theme = useTheme();

    useEffect(() => { //when the user starts editing a block, we close the sidebar automaticly
        if (isEditing) {
            dispatch(setSideBarExpanded({ expanded: false }));
        }
    }, [ isEditing ]);

    const setShowSideBar = useCallback((expanded: boolean) => { //if the user interacts with the sidebar, it is assumed, that he wants to stop editing
        dispatch(setSideBarExpanded({ expanded: expanded }));
        dispatch(setContentBlockEditing({ contentblock: null }));
    }, [ showSideBar ]);

    const currentTabConfig = allSideBarTabs.find(config => config.id === currentTab);

    const { t, i18n} = useTranslation();
    return (
        <Fragment>
            <div className={styles.sideBarDrawerPlaceHolder} style={{backgroundColor: theme.palette.background.default}}/>
            <div className={`${styles.sideBarDrawer} ${TargetSideBarComplete.className()}`}
                style={{"--sidebar-visible": showSideBar ? 1 : 0,
                backgroundColor: theme.palette.background.default}}
            >
                <div className={`${styles.actionTray} ${TargetSideBarTabs.className()}`}>
                    <div className={`${styles.actionTrayButton} ${styles.actionTrayButtonExpand} ${TargetSideBarExpand.className()}`}
                        onClick={() => setShowSideBar(!showSideBar)}
                    >
                        <ChevronLeft/>
                    </div>
                    <Divider/>

                    {/* Tab action tray buttons */}
                    {allSideBarTabs.map(config => (
                        <Fragment key={config.id}>
                            <span className={`${styles.actionTrayButton} ${config.trayTarget}`}
                                style={{
                                    "--custom-background-color": currentTab === config.id ? "#EEEEEE" : "transparent",
                                }}
                                onClick={() => {
                                    if (currentTab === config.id) {
                                        setShowSideBar(!showSideBar); //toggle sidebar if this is the tab currently shown
                                    } else {
                                        dispatch(setSideBarTab({ tab: config.id }));
                                        setShowSideBar(true);
                                    }
                                }}
                            >
                                <config.icon color={"primary"}/>
                                <p className={styles.actionTrayButtonScript}>
                                    {t('sideBar.Drawer.'+ config.name)}
                                </p>
                            </span>
                            <Divider/>
                        </Fragment>
                    ))}
                </div>
                <div className={styles.infoTray}>
                    <currentTabConfig.render key={currentTab}/>
                </div>
            </div>
        </Fragment>
    )
}
