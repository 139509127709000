// @flow

import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectConnected, selectConnecting, selectSocketError } from "../../redux/slice/SocketSlice";
import { useCallback, useMemo } from "react";
import { VCS_REVISION } from "../../constants";
import { SocketStatusIndicator } from "./SocketStatusIndicator";
import { StatusBarElement } from "./StatusBarElement";
import acc_doc_logo from "../../img/ACCESSible-DOCs-Logo.png";

export function StatusBar() {
    const socketConnecting = useSelector(selectConnecting);
    const socketConnected = useSelector(selectConnected);
    const socketError = useSelector(selectSocketError);

    const versionContent = useCallback(({ version }) => <Typography variant={"body2"}>Version {version}</Typography>, []);
    const versionProps = useMemo(() => ({ version: VCS_REVISION }), []);

    return (
        <div
            style={{
                backgroundColor: "#FFFFFF",

                height: "2rem",
                display: "flex",
                flexDirection: "row",
                gap: ".5rem"
            }}
        >
            {/* Version indicator */}
            <StatusBarElement contentElement={versionContent} contentElementProps={versionProps}/>

            <img src={acc_doc_logo} width={"auto"} height={"100%"}/>
            <div style={{flexGrow: 1}}/>
            {/* Logging messages */}
            {/* Socket Status */}
            <SocketStatusIndicator connecting={socketConnecting} connected={socketConnected} error={socketError}/>

        </div>
    );
}
