// @flow

import { createContext, useCallback, useContext, useRef, useState } from "react";
import type { PageIndex } from "../data/page/PageTypes";
import { pageIndexMatch } from "../data/page/PageUtil";
import { useStore } from "react-redux";
import { selectPage } from "../slice/PageSlice";

/**
 * Utility Function to fetch a string from the given url
 * @param url
 * @returns {Promise<string>}
 */
function fetchSvg(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const headers = new Headers();
        headers.append("Authorization", "Basic " + btoa("demo@example.com:demo")); //TODO: extremely unsafe, but we dont care about this for now
        fetch(url, {
            method: "GET",
            headers: headers,
        }).then(response => response.text().then(resolve).catch(reject));
    });
}

/**
 * Context used to access the loaded svg files from all components
 * @type {React$Context<function(*): null>}
 */
export const PageSVGContext: React$Context<[PageIndex => (string | null), PageIndex => void]> = createContext([index => {
    console.log("No PageSVGProvider found");
    return null;
}, index => console.log("No PageSVGProvider found")]);

type PageSVGProviderProps = {
    children: React$Node,
};

/**
 * Manages SVG source for pages and fetches svg sources if unavailable
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function PageSVGProvider(props: PageSVGProviderProps) {
    const store = useStore();

    const fetchPagesRef = useRef<PageIndex[]>([]);
    const svgsRef = useRef<[PageIndex, string]>([]);
    const [ svgs, setSVGs ] = useState<[PageIndex, string][]>([]);

    const getSVG = useCallback((index: PageIndex) => {
        const resultIndex = svgsRef.current.findIndex(i => pageIndexMatch(i[0], index));
        const fetchingIndex = fetchPagesRef.current.findIndex(i => pageIndexMatch(i, index));

        if (resultIndex < 0) { //if page is not loaded or currently loading, fetch it
            if (fetchingIndex < 0) {
                const pageData = selectPage(store.getState(), index);

                fetchPagesRef.current = [...fetchPagesRef.current, index];
                fetchSvg(`${process.env.REACT_APP_BACKEND_URL}/p/${index.page}.svg`).then(t => {
                    svgsRef.current = [...svgsRef.current, [index, `data:image/svg+xml;base64,${btoa(t)}`]];
                    setSVGs(svgsRef.current);

                    //remoge from loading pages
                    fetchPagesRef.current = fetchPagesRef.current.filter(i => !pageIndexMatch(i, index));
                });
            }
            return null;
        }

        return svgs[resultIndex][1];
    }, [ svgs ]);

    const removeSvg = useCallback(index => {
        svgsRef.current = svgsRef.current.filter(i => !pageIndexMatch(i[0], index));
        setSVGs(svgsRef.current);
    }, [ svgs ]);

    return (
        <PageSVGContext.Provider value={[getSVG, removeSvg]}>
            {props.children}
        </PageSVGContext.Provider>
    )
}

/**
 * Selects the svg for the given page if available, otherwise it is loaded
 * @param index
 * @returns {string}
 */
export function usePageSVG(index: PageIndex) {
    const [ getSVG, removeSVG ] = useContext(PageSVGContext);

    return getSVG(index);
}
