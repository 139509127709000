// @flow

import { selectContentBlock, selectFromPageOrdered } from "../../../redux/slice/ContentBlockSlice";
import { selectPageIndicesOrdered } from "../../../redux/slice/PageSlice";
import {
    selectCurrentPage,
    setReadingOrderEditorActive,
    setReadingOrderEditorOrder,
    setSideBarExpanded,
    setSideBarTab
} from "../../../redux/slice/EditorSlice";
import { Dispatch, useCallback, useMemo } from "react";
import type { TutorialStep } from "./Tutorial";
import { Tutorial } from "./Tutorial";
import {
    TargetReadingOrderAnchor0,
    TargetReadingOrderList,
    TargetReadingOrderOverlay,
    TargetReadingOrderTray
} from "./targets";
import { Typography } from "@material-ui/core";
import { SideBarTabReadingOrderConfig } from "../sidebar/SideBarTabReadingOrder";
import type { ContentBlockIndex } from "../../../redux/data/contentblock/ContentBlockTypes";
import { useContentBlockIndicesOrdered } from "../../../redux/hook/useContentBlock";
import type { ContentBlockTypeConstants } from "../../../common/element/block/ContentBlockConstants";
import { constants } from "../../../common/element/block/ContentBlockConstants";
import { useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import styles from "./BasicEditorTutorial.module.css";
import {useTranslation} from "react-i18next";

/**
 * Id used for statemanagement
 * @type {string}
 */
export const ReadingOrderTutorialId = "reading-order";

/**
 * Checks whether the reading order tutorial can be started
 * @param state
 */
export function canStartReadingOrderTutorial(state): string | null {
    //reading order tutorial requires at least 3 contentblocks
    const pageIndex = selectPageIndicesOrdered(state)[selectCurrentPage(state)];
    if (selectFromPageOrdered(state, pageIndex).length < 3) {
        return "Add at least 3 contentblocks to be able to run the reading order tutorial";
    }

    return null;
}

/**
 * Interactive Tutorial for the reading order sidebar and overlay
 * @returns {JSX.Element}
 * @constructor
 */
export function ReadingOrderTutorial() {
    const readingOrder: ContentBlockIndex[] = useContentBlockIndicesOrdered();

    //fetch block constants of the first 3 items (if possible)
    const orderConstants: ContentBlockTypeConstants[] = useSelector(state => {
        const firstItems = readingOrder.slice(0, 3);
        return firstItems.map(index => constants(selectContentBlock(state, index)));
    });

    const {t, i18n} = useTranslation();

    const steps = useMemo<TutorialStep[]>(() => [
        {
            target: TargetReadingOrderTray.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title1')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step1')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetReadingOrderList.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title2')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step2')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetReadingOrderList.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title3')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step3')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetReadingOrderOverlay.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title4')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step4')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {
            target: TargetReadingOrderOverlay.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title5')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step5')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onNext: (dispatch, start) => {
                dispatch(setReadingOrderEditorActive({ active: true }));
                dispatch(setReadingOrderEditorOrder({ order: [readingOrder[0]]}));
                start();
            },
        },
        {
            target: TargetReadingOrderAnchor0.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title6')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step6')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setReadingOrderEditorActive({ active: false }));
                dispatch(setReadingOrderEditorOrder({ order: [] }));
                start();
            },
        },
        {
            target: TargetReadingOrderOverlay.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title7')}</Typography>),
            content:(
                <Typography>
                    {t('tutorials.readingOrder.step7')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            isFixed: true,
            onNext: (dispatch, start) => {
                dispatch(setReadingOrderEditorOrder({ order: readingOrder.slice(0, readingOrder.length - 1)}));
                start();
            },
        },
        {
            target: TargetReadingOrderOverlay.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title8')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step8')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            isFixed: true,
            onBack: (dispatch, start) => {
                dispatch(setReadingOrderEditorOrder({ order: [readingOrder[0]]}));
                start();
            },
            onNext: (dispatch, start) => {
                dispatch(setReadingOrderEditorOrder({ order: []}));
                start();
            },
        },
        {
            target: TargetReadingOrderOverlay.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.readingOrder.title9')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.readingOrder.step9')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            isFixed: true,
            onBack: (dispatch, start) => {
                dispatch(setReadingOrderEditorOrder({ order: readingOrder.slice(0, readingOrder.length - 1)}));
                start();
            },
        },
    ], [ readingOrder ]);

    const prepare = useCallback((dispatch: Dispatch<AnyAction>, start: () => void) => {
        //reading order overlay is shown and tab is expanded
        dispatch(setSideBarExpanded({ expanded: true }));
        dispatch(setSideBarTab({ tab: SideBarTabReadingOrderConfig.id }));

        // reading order editor is not active and selected order is cleared
        dispatch(setReadingOrderEditorActive({ active: false }));
        dispatch(setReadingOrderEditorOrder({ order: [] }));
        window.setTimeout(() => start(), 500); //wait for sidebar to open
    });
    const cleanup = useCallback((dispatch: Dispatch<AnyAction>) => {
        dispatch(setSideBarExpanded({ expanded: true }));
        dispatch(setSideBarTab({ tab: SideBarTabReadingOrderConfig.id }));

        // reading order editor is not active and selected order is cleared
        dispatch(setReadingOrderEditorActive({ active: false }));
        dispatch(setReadingOrderEditorOrder({ order: [] }));
    });

    return (
        <Tutorial tutorial={ReadingOrderTutorialId} steps={steps} prepare={prepare} cleanup={cleanup}/>
    );
}
