// @flow

import {
    ChemicalFormulaConstants,
    HeadingConstants, ImageConstants,
    MathExpressionConstants, NoneConstants, TableConstants,
    TextConstants
} from "../../../common/element/block/ContentBlockConstants";
import {Typography} from "@material-ui/core";
import React from "react";
import { TextDescriptionHelperFrame } from "./TextDescriptionHelperFrame";
import { HeadingDescriptionHelperFrame } from "./HeadingDescriptionHelperFrame";
import {MathDescriptionHelperFrame} from "./MathDescriptionHelperFrame";
import {TableDescriptionHelperFrame} from "./TableDescriptionHelperFrame";
import {ChemplateDescriptionHelperFrame} from "./ChemplateDescriptionHelperFrame";
import {ImageDescriptionHelperFrame} from "./ImageDescriptionHelperFrame";



type EditorHelperSelectProps = {
    type: string;
};

/**
 * Returns a specific tooltip element based on the provided type.
 * @param type: the type of editor currently open
 * @returns {JSX.Element} containing only the correct tooltip
 * @constructor
 */
export function EditorHelperSelect({type}: EditorHelperSelectProps){
    switch (type) {
        case TextConstants.type:
            return <TextDescriptionHelperFrame/>;
        case HeadingConstants.type:
            return <HeadingDescriptionHelperFrame/>;
        case MathExpressionConstants.type:
            return <MathDescriptionHelperFrame/>;
        case TableConstants.type:
            return <TableDescriptionHelperFrame/>;
        case ImageConstants.type:
            return <ImageDescriptionHelperFrame/>;
        case ChemicalFormulaConstants.type:
            return <ChemplateDescriptionHelperFrame/>;
        case NoneConstants.type:
            return <Typography>huh???</Typography>;
        default:
            return <Typography>Currently no helper available for this</Typography>;
    }
}
