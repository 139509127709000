import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";


// check documentation at: https://react.i18next.com/guides/quick-start for more info

/* Readme:
 * translation files are in public/locales/{de/en}
 * contents are ordered in json style as module.string
 * e. g. there are categories for the homepage, tutorials, sidebar
*/
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "de", // @todo find out how to programmatically set the language correctly
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false // react protects form xss
        }
    });

export default i18n;
