// @flow

import type { ContentBlockData, ContentBlockIndex } from "../../../redux/data/contentblock/ContentBlockTypes";
import { useSelector } from "react-redux";
import {
    selectContentBlockDragging,
    selectCurrentDraggingBox,
    selectCurrentPageBounds,
    selectIsNewDragging
} from "../../../redux/slice/EditorSlice";
import { selectContentBlock } from "../../../redux/slice/ContentBlockSlice";
import styles from "./ContentBlockBorderDragging.module.css";
import { constants } from "./ContentBlockConstants";
import { alpha } from "@material-ui/core";

export function ContentBlockBorderDragging() {
    const pageBounds = useSelector(selectCurrentPageBounds);

    const isDraggingNew: boolean = useSelector(selectIsNewDragging);
    const blockDragging: ContentBlockIndex | null = useSelector(selectContentBlockDragging);
    const anyDragging: boolean = isDraggingNew || !!blockDragging;

    const blockDraggingData: ContentBlockData | null = useSelector(state => !!blockDragging ? selectContentBlock(state, blockDragging) : null);

    const currentBox: { left: number, top: number, width: number, height: number } = useSelector(selectCurrentDraggingBox);

    if (anyDragging) {
        const currentConstants = constants(blockDraggingData);

        return (
            <div
                className={styles.contentblockBorder}
                style={{
                    position: "absolute",
                    left: `${currentBox.left * pageBounds.width- 2}px`,
                    top: `${currentBox.top * pageBounds.height - 2}px`,
                    width: `${currentBox.width * pageBounds.width}px`,
                    height: `${currentBox.height * pageBounds.height}px`,

                    backgroundColor: alpha(currentConstants.color, 0.2),
                    borderColor: currentConstants.color,
                }}
            />
        );
    }

    return null; //if no contentblock is currently dragged, dont render
}
