// @flow

import { useRouteMatch } from "react-router";
import {useEffect, useMemo, useState} from "react";
import { useHistory } from "react-router-dom";
import { Card, FormLabel, LinearProgress } from "@material-ui/core";
import { selectTaskByType } from "../../redux/slice/TaskSlice";
import {connect, useSelector} from "react-redux";
import type { TaskData } from "../../redux/data/task/TaskTypes";
import { MetadataEditorDialog } from "../../common/element/metadata/MetadataDialog";
import {selectDocument} from "../../redux/slice/DocumentSlice";
import type {DocumentData} from "../../redux/data/document/DocumentTypes";

type LoadingScreenProps = {
  segmentationTask: ?TaskData,
  extractionTask: ?TaskData
};

function mapStateToProps(state) : LoadingScreenProps {
  const result = {
    segmentationTask: selectTaskByType(state, "segmentation"),
    extractionTask: selectTaskByType(state, "extraction")
  };
  return result;
}

function LoadingScreen(props : LoadingScreenProps) : React$Node {
    const history = useHistory();
    const match = useRouteMatch();

    const documentIndex = useMemo(() => ({
        id: parseInt(match.params.document),
    }), [ match.params.document ]);

    // tester for dialog
    const [dialogOpen, setDialogOpen] = useState(true);

    const document: DocumentData = useSelector(selectDocument);

    const redirectToWizard = () => history.push(`/blockedit/${documentIndex.id}`);

    const extractionTask = props.extractionTask;
    const segmentationTask = props.segmentationTask;

    const handleCloseDialog = () => {
        setDialogOpen(false);
        console.log("ex Task: ", extractionTask?.successful);
        console.log("seg Task: ", segmentationTask?.successful);
        if (extractionTask?.successful// && ((!segmentationTask) // either we are not doing segmentation
            // || (segmentationTask.successful === true)) // …or it has to be completed successfully
        ) {
            console.log("Inside if");
            redirectToWizard();
        }
    };

    // if dialog has been closed before extraction (and segmentation) have been completed
    useEffect(() =>{
        if (!dialogOpen && extractionTask?.successful &&
            ((!segmentationTask) // either we are not doing segmentation
                || (segmentationTask.successful)) // …or it has to be completed successfully
        ) {
            redirectToWizard();
        }
    }, [dialogOpen, extractionTask, segmentationTask]);


    return (
        <Card m={2} style={{
            backgroundColor: "white",
            padding: "1em",
            width: "16em",
            margin: "1em auto"
        }}>
            <div>
                <FormLabel>Extraction</FormLabel>
                <LinearProgress value={extractionTask?.percentage || 0} variant={"determinate"} />
            </div>

            <div style={{
                marginTop: "1em"
            }}>
                <FormLabel>Segmentation</FormLabel>
                <LinearProgress value={segmentationTask?.percentage || 0} variant={"determinate"} />
            </div>
            <MetadataEditorDialog open={dialogOpen} onClose={handleCloseDialog}/>
        </Card>
    );
}

export default connect(mapStateToProps)(LoadingScreen);
