// @flow

import styles from "./SideBarTabReadingOrder.module.css";
import type { SideBarTabConfig } from "./SideBarDrawer";
import { TargetSettingsTray } from "../tutorials/targets";
import { Settings } from "@material-ui/icons";
import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectEditMenuDeveloperModeActive, selectDarkModeEnabled, setEditMenuDeveloperMode, setDarkModeEnabled } from "../../../redux/slice/EditorSlice";
import { useCallback } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {useTranslation} from "react-i18next";
import {DisplayLanguageSelector} from "../../../common/element/displaylanguage/DisplayLanguageSelector";

/**
 * Sidebar for settings in the editor (hot keys, dark mode etc)
 * @constructor
 */
export function SideBarTabSettings() {
    const dispatch = useDispatch();

    const showDeveloperSettings = true; //TODO: load from backend when user accounts are active
    const editMenuDevMode = useSelector(selectEditMenuDeveloperModeActive);
    const handleEditMenuDevModeChange = useCallback((e) => {
        dispatch(setEditMenuDeveloperMode({ active: e.target.checked }));
    }, []);
    const darkModeEnabled = useSelector(selectDarkModeEnabled);
    const handleEditMenuDarkModeChange = useCallback((e) => {
        dispatch(setDarkModeEnabled({enabled: e.target.checked}));
    }, []);

    // translations
    const { t, i18n } = useTranslation();

    const availableLanguages =[
        {
            "language" : "Deutsch",
            "languageCode" : "de"
        },
        {
            "language" : "English",
            "languageCode" : "en"
        }
    ];
    const changeLang = (newlang) => {
        i18n.changeLanguage(newlang, (err, t) =>{
            if (err) {
                console.log("something went wrong changing the language");
            }
        });
    }

    return (
        <div className={styles.container}>
            <Typography variant={"h6"}>
                {t('sideBar.Settings.settings')}
            </Typography>
            <FormControlLabel
                control={<Switch checked={darkModeEnabled} onChange={handleEditMenuDarkModeChange} />}
                label={t('sideBar.Settings.darkMode')}
            />

            <DisplayLanguageSelector/>
            {showDeveloperSettings && (
                <>
                    <Typography variant={"h6"}>
                        {t('sideBar.Settings.devSettings')}
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={editMenuDevMode} onChange={handleEditMenuDevModeChange} />}
                        label={t('sideBar.Settings.showDevInfo')}
                    />


                </>
            )}
        </div>
    )
}

/**
 * Configuration for the metadata tab in the sidebar
 * @type {{}}
 */
export const SideBarTabSettingsConfig: SideBarTabConfig = {
    id: "settings",
    name: "Settings",
    trayTarget: TargetSettingsTray.className(),
    icon: props => <Settings {...props}/>,
    render: SideBarTabSettings,
};
