// @flow

import type { PageData, PageIndex, PageSTOMPPayload } from "./PageTypes";

/**
 * Converts a page payload to page data
 * @param payload
 */
export function pagePayloadToData(payload: PageSTOMPPayload): PageData {
    return {
        width: payload.mediaBox?.width,
        height: payload.mediaBox?.height,
        pageNumber: payload.pageNumber,
    };
}

/**
 * Converts page data into a page payload for stomp communication
 * @param data
 */
export function pageDataToPayload(data: PageData): PageSTOMPPayload {
    // const hasMediaBox: boolean = !!data.width || !!data.height;

    return {
        //page has no fields that can be synchronized
        // mediaBox: hasMediaBox ? {
        //     //page never has an offset
        //     x: 0,
        //     y: 0,
        //     //copy width and height
        //     width: data.width,
        //     height: data.height,
        // } : undefined,
        // pageNumber: data.pageNumber,
    };
}

/**
 * Compares the 2 indices to see if all relevant fields match exactly
 * @param a
 * @param b
 * @returns {boolean}
 */
export function pageIndexMatch(a: PageIndex, b: PageIndex): boolean {
    return a.page === b.page && a.document === b.document;
}
