// @flow

import { useSelector } from "react-redux";
import { selectContentBlockEditing } from "../../../redux/slice/EditorSlice";
import { useEditableContentBlock } from "../../../redux/hook/useContentBlock";
import { Grid, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from "@material-ui/core/Select";

import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePropertyShadow } from "../../../redux/hook/usePropertyShadow";
import { LanguageCodes } from "../../../common/constants/LanguageCodesIETF";
import { useEditableMetadata } from "../../../redux/hook/useMetadata";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  textField: {
    width: 735,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

export const ContentBlockEditorText = forwardRef((props, ref) => {
    /*
        Fetch Data
     */
    const contentBlockIndex = useSelector(selectContentBlockEditing);
    const contentBlock = useEditableContentBlock(contentBlockIndex);
    const metadata = useEditableMetadata();

    /*
        State management
     */
    const classes = useStyles();
    const [ alternativeText, setAlternativeText ] = usePropertyShadow<string>(contentBlock.details.alternativeText);

    const [ language, setLanguage ] = usePropertyShadow<string>(contentBlock.details.language);

    const initialLanguageIndex = LanguageCodes.indexOf(LanguageCodes.find(x => x.languageCode === language));
    const documentLanguage = (typeof LanguageCodes.find(x => x.languageCode === metadata.language) === "undefined") ? "None" : LanguageCodes.find(x => x.languageCode === metadata.language).language;

    /*
        Imperative handle for saving
     */
    useImperativeHandle(ref, () => ({
        save: () => {
            contentBlock.details.alternativeText = alternativeText;
            contentBlock.details.language = language;
            contentBlock.details.segmentationConfidence = 1; // @todo: we are setting the confidence to one for now. this might not necessarily always be correct
        },
    }), [ language, alternativeText, contentBlock ]);

    return (
        <div
            style={{
                padding: "1rem",
                gap: "1rem",
            }}
        >
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={2}
            >
                <Grid item>
                <InputLabel >Language</InputLabel>
                <FormControl style={{textAlign: 'right'}} variant="outlined" className={classes.formControl}>
                        <Select
                        native
                        onChange={e => setLanguage(e.target.value || "")}
                        label="Language"
                        inputProps={{
                            name: 'language',
                        }}
                        >{LanguageCodes.map((item, index) => 
                            <option key={item.languageCode} value={item.languageCode} selected={index === initialLanguageIndex}>{item.language}</option>)}
                        </Select>
                        {initialLanguageIndex === 0 ? <FormHelperText>Metadata language ({documentLanguage}) used</FormHelperText> : null}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        className={classes.textField}
                        label="Replacement Text"
                        multiline
                        rows={10}
                        defaultValue={alternativeText || ""}
                        variant="outlined"
                        value={alternativeText || ""}
                        onChange={e => setAlternativeText(e.target.value || "")}
                    />
                </Grid>
            </Grid>
        </div>
    );
});
