// @flow

import {Trans, useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import React from "react";

export function ImageDescriptionHelperFrame() {
    const {t, i18n} = useTranslation();
    return (
        <div>
            <Typography>
                <Trans i18nKey="tutorials.imageAltTexts.intro" components={{ 1: <br /> }} />
                <ol>
                    <li>{t('tutorials.imageAltTexts.li1')}</li>
                    <li>{t('tutorials.imageAltTexts.li2')}</li>
                    <li>{t('tutorials.imageAltTexts.li3')}</li>
                    <li>{t('tutorials.imageAltTexts.li4')}</li>
                    <li>{t('tutorials.imageAltTexts.li5')}</li>
                    <li>{t('tutorials.imageAltTexts.li6')}</li>
                </ol>
            </Typography>
        </div>
    )
}
