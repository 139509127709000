// @flow

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { PageElementCollection } from "./SliceTypes";
import type { ContentBlockData, ContentBlockIndex } from "../data/contentblock/ContentBlockTypes";
import type { PageLoadedPayload, PageUnloadedPayload } from "./PageSlice";
import { loaded as pageLoaded, unloaded as pageUnloaded } from "./PageSlice";
import { pageIndexMatch } from "../data/page/PageUtil";
import { contentBlockIndexMatch } from "../data/contentblock/ContentBlockUtil";
import { addTruthLayer, removeTruthLayer, updateGroundTruth } from "./SliceUtil";
import type { PageIndex } from "../data/page/PageTypes";

type ContentBlockLoadingPayload = {
    index: ContentBlockIndex,
};

type ContentBlockLoadedPayload = {
    index: ContentBlockIndex,
    version: number,
    data: ContentBlockData,
};

type ContentBlockUnloadedPayload = {
    index: ContentBlockIndex,
};

type ContentBlockUpdateUnconfirmedPayload = {
    origin: string,
    index: ContentBlockIndex,
    data: ContentBlockData,
};

type ContentBlockUpdateRejectedPayload = {
    origin: string,
    index: ContentBlockIndex,
};

type ContentBlockUpdateConfirmedPayload = {
    origin: string,
    index: ContentBlockIndex,
    version: number,
    data: ContentBlockData,
};

type ContentBlockAllUnloadedPayload = {
    page: PageIndex,
};

const contentBlocksInitialState: { contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[] } = { contentBlocks: [] }; //empty array of

const ContentBlockSlice = createSlice({
    name: 'contentblocks',
    initialState: contentBlocksInitialState,
    reducers: {
        loading: (state, action: PayloadAction<ContentBlockLoadingPayload>) => {
            //find page
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { //add new element container to page collection
                //try to find element with identical id before initializing a new one
                if (!state.contentBlocks[pageIndex].elements.some(element => contentBlockIndexMatch(element.index, action.payload.index))) { // if index is not found, add a new container
                    state.contentBlocks[pageIndex].elements = [ ...state.contentBlocks[pageIndex].elements, {
                        index: action.payload.index,
                        groundTruth: null,
                        resolvedTruth: null,
                        groundTruthVersion: 0,
                        truthLayers: [],
                    }];
                }
            }
        },
        loaded: (state, action: PayloadAction<ContentBlockLoadedPayload>) => {
            // find page
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // find element
                const blockIndex = state.contentBlocks[pageIndex].elements.findIndex(elementContainer => contentBlockIndexMatch(elementContainer.index, action.payload.index));
                if (blockIndex >= 0) { // set data when its available
                    state.contentBlocks[pageIndex].elements[blockIndex].groundTruth = action.payload.data;
                    state.contentBlocks[pageIndex].elements[blockIndex].resolvedTruth = action.payload.data;
                    state.contentBlocks[pageIndex].elements[blockIndex].groundTruthVersion = action.payload.version;
                }
            }
        },
        unloaded: (state, action: PayloadAction<ContentBlockUnloadedPayload>) => {
            // find page
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // find element
                state.contentBlocks[pageIndex].elements = state.contentBlocks[pageIndex].elements.filter(contentBlock => !contentBlockIndexMatch(contentBlock.index, action.payload.index));
            }
        },
        updateUnconfirmed: (state, action: PayloadAction<ContentBlockUpdateUnconfirmedPayload>) => {
            // find page
            console.log("ContentBlock updateUnconfirmed", action);
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // find element
                const blockIndex = state.contentBlocks[pageIndex].elements.findIndex(elementContainer => contentBlockIndexMatch(elementContainer.index, action.payload.index));
                if (blockIndex >= 0) { // add truth layer
                    addTruthLayer(state.contentBlocks[pageIndex].elements[blockIndex], action.payload.origin, action.payload.data);
                }
            }
        },
        updateRejected: (state, action: PayloadAction<ContentBlockUpdateRejectedPayload>) => {
            // find page
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // find element
                const blockIndex = state.contentBlocks[pageIndex].elements.findIndex(elementContainer => contentBlockIndexMatch(elementContainer.index, action.payload.index));
                if (blockIndex >= 0) { // remove layer with the same origin
                    removeTruthLayer(state.contentBlocks[pageIndex].elements[blockIndex], action.payload.origin);
                }
            }
        },
        updateConfirmed: (state, action: PayloadAction<ContentBlockUpdateConfirmedPayload>) => {
            // find page
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // find element
                const blockIndex = state.contentBlocks[pageIndex].elements.findIndex(elementContainer => contentBlockIndexMatch(elementContainer.index, action.payload.index));
                if (blockIndex >= 0) { // find layer and confirm it
                    removeTruthLayer(state.contentBlocks[pageIndex].elements[blockIndex], action.payload.origin);
                    updateGroundTruth(state.contentBlocks[pageIndex].elements[blockIndex], action.payload.data, action.payload.version);
                }
            }
        },
        allUnloaded: (state, action: PayloadAction<ContentBlockAllUnloadedPayload>) => {
            const pageIndex = state.contentBlocks.findIndex(pageContainer => pageIndexMatch(pageContainer.page, action.payload.page));
            if (pageIndex >= 0) {
                state.contentBlocks[pageIndex].elements = [];
            }
        }
    },
    extraReducers: { //we need to initialize the containers when pages are loaded and unloaded
        [pageLoaded]: (state, action: PayloadAction<PageLoadedPayload>) => {
            state.contentBlocks = [ ...state.contentBlocks, {
                page: action.payload.index,
                elements: [],
            }];
        },
        [pageUnloaded]: (state, action: PayloadAction<PageUnloadedPayload>) => {
            state.contentBlocks = state.contentBlocks.filter(collection => !pageIndexMatch(collection.page, action.payload.index));
        },
    }
});

export const { loading, loaded, unloaded, updateUnconfirmed, updateRejected, updateConfirmed, allUnloaded } = ContentBlockSlice.actions;
export default ContentBlockSlice.reducer;

//basic selectors
function selectAll(state): PageElementCollection<ContentBlockIndex, ContentBlockData>[] {
    return state.contentblocks.contentBlocks;
}

export const selectAllContentBlocks = createSelector([
        selectAll,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[]) => {
        //console.log("selecting all ContenBlocks: ", contentBlocks);
        //console.log("selecting all ContenBlocks: ", contentBlocks.flat());
        //const try1 = contentBlocks.forEach(collection => return collection.elements.forEach(elem => return elem.resolvedTruth));
        return contentBlocks.map(collection =>
            collection.elements.map(elem =>
                elem.resolvedTruth)).flat();
    });

//selectors

/**
 * Selects the indices of all contentblocks on a given page
 */
export const selectIndicesFromPage = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[], page: PageIndex) => {
        //find page
        const pageIndex = contentBlocks.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) {
            return contentBlocks[pageIndex].elements.map(element => element.index);
        } else {
            return null; // not loaded
        }
    });

/**
 * Selects the indices of all contentblocks on a given page ordered by the ordinal property
 */
export const selectIndicesFromPageOrdered = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[], page: PageIndex) => {
        //find page
        const pageIndex = contentBlocks.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) {
            return [ ...contentBlocks[pageIndex].elements ].sort((a, b) => !!a.resolvedTruth ? !!b.resolvedTruth ? a.resolvedTruth.ordinal - b.resolvedTruth.ordinal : -1 : 1).map(element => element.index);
        } else {
            return null; // not loaded
        }
    });

/**
 * Selects all contentblocks from a given page
 */
export const selectFromPage = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[], page: PageIndex) => {
        //find page
        const pageIndex = contentBlocks.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) {
            return contentBlocks[pageIndex].elements.map(element => element.resolvedTruth);
        } else {
            return null; // not loaded
        }
    });

/**
 * Selects all contentblocks from a given page ordered by the ordinal property
 */
export const selectFromPageOrdered = createSelector([
        selectFromPage,
    ],
    (contentblocks: ContentBlockData[]) => contentblocks.sort((a, b) => !!a ? !!b ? a.ordinal - b.ordinal : -1 : 1)
);

/**
 * Selects a contentblock with a specific index
 * @type {OutputSelector<[(function(*): *|PageElementCollection<ContentBlockIndex, ContentBlockData>[]|[]), (function(*, ContentBlockIndex): ContentBlockIndex)], unknown, (...args: SelectorResultArray<[(function(*): *|PageElementCollection<ContentBlockIndex, ContentBlockData>[]|[]), (function(*, ContentBlockIndex): ContentBlockIndex)]>) => {clearCache: () => void}, GetParamsFromSelectors<[(function(*): *|PageElementCollection<ContentBlockIndex, ContentBlockData>[]|[]), (function(*, ContentBlockIndex): ContentBlockIndex)]>> & {clearCache: () => void}}
 */
export const selectContentBlock = createSelector([
        selectAll,
        (state, contentBlock: ContentBlockIndex) => contentBlock,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[], contentBlock: ContentBlockIndex) => {
        const pageIndex = contentBlocks.findIndex(collection => pageIndexMatch(collection.page, contentBlock));
        if (pageIndex >= 0) {
            const blockIndex = contentBlocks[pageIndex].elements.findIndex(block => contentBlockIndexMatch(block.index, contentBlock));
            if (blockIndex >= 0) {
                return contentBlocks[pageIndex].elements[blockIndex].resolvedTruth;
            }
        }
    });

export const selectContentBlockById = createSelector([
    selectAll,
    (state, contentBlockId: number) => contentBlockId,
    ],
    (contentBlocks: PageElementCollection<ContentBlockIndex, ContentBlockData>[], contentBlockId: number) => {
        let pi = -1;
        let ci = -1;
        console.log("contentBlockId we're looking for: ", contentBlockId);
        contentBlocks.forEach((pg, i) => {
            console.log("pg: ", pg);
            pg.elements.forEach((elem, j) =>  {
                console.log("elem: ", elem);
                if(elem.index.contentblock === contentBlockId) {
                    pi = i;
                    ci = j;
                }
            })
        })

        console.log("pi: ", pi, " ci: ", ci);
        if (pi >= 0 && ci >= 0) {
            const page = contentBlocks[pi].page;
            const contentBlock = contentBlocks[pi].elements[ci].resolvedTruth;
            const contentBlockIndex = contentBlocks[pi].elements[ci].index;
            return {contentBlock, contentBlockIndex, page};
        } else {
            return null;
        }

    });
