// @flow

import type { EditorData } from "../app/editor/EditorTypes";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ContentBlockData, ContentBlockIndex } from "../data/contentblock/ContentBlockTypes";
import { contentBlockIndexMatch } from "../data/contentblock/ContentBlockUtil";
import { selectContentBlock } from "./ContentBlockSlice";
import { selectPagesOrdered } from "./PageSlice";
import type {PageData, PageIndex} from "../data/page/PageTypes";

type EditorSetCurrentPagePayload = {
    pageNumber: 0,
};

type EditorSetCurrentMousePositionPayload = {
    left: number,
    top: number,
};

type EditorSetZoomPayload = {
    zoom: number,
};

type EditorStartDragPayload = {
    target: ContentBlockData | null,
    targetIndex: ContentBlockIndex | null,
    activateModeVertical: boolean,
    activateModeHorizontal: boolean,
    activateModeMove: boolean,
    pageWidth: number,
    pageHeight: number,
};

type EditorEndDragPayload = {
};

type EditorSetContentBlockHighlightedPayload = {
    contentblock: ContentBlockIndex | null,
};

type EditorSetContentBlockEditingPayload = {
    contentblock: ContentBlockIndex | null,
};

type EditorSetSideBarExpandedPayload = {
    expanded: boolean,
};

type EditorSetSideBarTabPayload = {
    tab: string,
};

type EditorSetRunTutorialPayload = {
    tutorial: string,
};

type EditorSetReadingOrderEditorActivePayload = {
    active: boolean,
};

type EditorSetReadingOrderEditorOrderPayload = {
    order: ContentBlockIndex[],
};

type EditorSetEditMenuDeveloperModePayload = {
    active: boolean,
};

type DarkModeEnabledPayload = {
    enabled: boolean,
};

type EditorSetPageAndContentBlockPayload = {
  pageNumber: number,
  contentBlock: ContentBlockIndex,
};

const editorSliceInitialState: EditorData = {
    currentPageNumber: 0,
    currentMousePosition: null,
    currentZoom: 1,
    draggingContext: null, //no dragging context to begin with
    editMenuDeveloperMode: false,
    contentblockHighlighted: null,
    contentblockEditing: null,
    darkModeEnabled: false,
    sideBarDrawer: {
        expanded: false,
        tab: "readingorder",
    },
    tutorial: null,
    readingOrderEditor: {
        active: false,
        order: [],
    },
};

const EditorSlice = createSlice({
    name: 'editor',
    initialState: editorSliceInitialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<EditorSetCurrentPagePayload>) => {
            state.currentPageNumber = action.payload.pageNumber;
        },
        setCurrentMousePosition: (state, action: PayloadAction<EditorSetCurrentMousePositionPayload>) => {
            state.currentMousePosition = !!action.payload ? { //payload being null indicates that the mouse is not over the page
                left: action.payload.left,
                top: action.payload.top,
            } : null;
        },
        setCurrentZoom: (state, action: PayloadAction<EditorSetZoomPayload>) => {
            state.currentZoom = action.payload.zoom;
        },
        startDrag: (state, action: PayloadAction<EditorStartDragPayload>) => {
            if (!state.currentMousePosition) { //if mouse is not over the page, no drag can be started
                return;
            }

            const targetData = action.payload.target;
            const isNew = !action.payload.targetIndex;

            const currentPageWidth = action.payload.pageWidth;
            const currentPageHeight = action.payload.pageHeight;

            //check which border is dragged for each mode
            const mouseVerticalTop = (state.currentMousePosition.top / currentPageHeight) - targetData.top < targetData.height / 2;
            const mouseHorizontalLeft = (state.currentMousePosition.left / currentPageWidth) - targetData.left < targetData.height / 2;

            state.draggingContext = {
                target: {
                    isNew: isNew, //if no index is given, this is supposed to create a new contentblock
                    contentblock: action.payload.targetIndex,
                },
                modeVertical: null,
                modeHorizontal: null,
                modeMove: null,
                ...(action.payload.activateModeMove && !!targetData && {
                    modeMove: {
                        oldLeft: targetData.left * currentPageWidth,
                        oldTop: targetData.top * currentPageHeight,
                        startLeft: state.currentMousePosition.left,
                        startTop: state.currentMousePosition.top,
                    },
                }),
                ...(action.payload.activateModeVertical && {
                    modeVertical: {
                        old: isNew ? state.currentMousePosition.top : (targetData.top + (mouseVerticalTop ? 0 : targetData.height)) * currentPageHeight,
                        start: state.currentMousePosition.top,
                        anchor: isNew ? state.currentMousePosition.top : (targetData.top + (mouseVerticalTop ? targetData.height : 0)) * currentPageHeight,
                    }
                }),
                ...(action.payload.activateModeHorizontal && {
                    modeHorizontal: {
                        old: isNew ? state.currentMousePosition.left : (targetData.left + (mouseHorizontalLeft ? 0 : targetData.width)) * currentPageWidth,
                        start: state.currentMousePosition.left,
                        anchor: isNew ? state.currentMousePosition.left : (targetData.left + (mouseHorizontalLeft ? targetData.width : 0)) * currentPageWidth,
                    }
                }),
            }
        },
        endDrag: (state, action: PayloadAction<EditorEndDragPayload>) => {
            state.draggingContext = null; //ending a drag is as easy as removing the context
        },
        setEditMenuDeveloperMode: (state, action: PayloadAction<EditorSetEditMenuDeveloperModePayload>) => {
            state.editMenuDeveloperMode = action.payload.active;
        },
        setContentBlockHighlighted: (state, action: PayloadAction<EditorSetContentBlockHighlightedPayload>) => {
            state.contentblockHighlighted = action.payload.contentblock;
        },
        setContentBlockEditing: (state, action: PayloadAction<EditorSetContentBlockEditingPayload>) => {
            state.contentblockEditing = action.payload.contentblock;
            state.contentblockDragging = null; //reset dragging when editing is started, should technicly never happen
        },
        setSideBarExpanded: (state, action: PayloadAction<EditorSetSideBarExpandedPayload>) => {
            state.sideBarDrawer.expanded = action.payload.expanded;
        },
        setSideBarTab: (state, action: PayloadAction<EditorSetSideBarTabPayload>) => {
            state.sideBarDrawer.tab = action.payload.tab;
        },
        setRunTutorial: (state, action: PayloadAction<EditorSetRunTutorialPayload>) => {
            state.tutorial = action.payload.tutorial;
        },
        setReadingOrderEditorActive: (state, action: PayloadAction<EditorSetReadingOrderEditorActivePayload>) => {
            state.readingOrderEditor.active = action.payload.active;
        },
        setReadingOrderEditorOrder: (state, action: PayloadAction<EditorSetReadingOrderEditorOrderPayload>) => {
            state.readingOrderEditor.order = [ ...action.payload.order ];
        },
        setDarkModeEnabled: (state, action: PayloadAction<DarkModeEnabledPayload>) => {
            state.darkModeEnabled = action.payload.enabled;
        },
        setPageAndContentBlockEditing: (state, action: PayloadAction<EditorSetPageAndContentBlockPayload>) => {
            state.currentPageNumber = action.payload.pageNumber;
            state.contentblockEditing = action.payload.contentBlock;
            state.contentblockDragging = null;
        }
    },
});

export const { setCurrentPage,
                setCurrentMousePosition,
                setCurrentZoom,
                startDrag,
                endDrag,
                setContentBlockHighlighted,
                setContentBlockEditing,
                setSideBarExpanded,
                setSideBarTab,
                setRunTutorial,
                setReadingOrderEditorActive,
                setReadingOrderEditorOrder,
                setEditMenuDeveloperMode,
                setDarkModeEnabled,
                setPageAndContentBlockEditing}
                    = EditorSlice.actions;
export default EditorSlice.reducer;

/**
 * Selects the number of the currently shown page
 * @param state
 * @returns {*}
 */
export function selectCurrentPage(state) {
    return state.editor.currentPageNumber;
}

/**
 * Selects the bounds of the currently shown page in pixels
 * @param state
 * @returns {*|{width: number, height: number}|{width: number, height: number}}
 */
export const selectCurrentPageBounds = createSelector([
    selectCurrentZoom,
    state => selectPagesOrdered(state)[selectCurrentPage(state)],
], (zoom: number, page: PageData) =>({
    width: (page?.width || 0) * zoom,
    height: (page?.height || 0) * zoom,
}));

/**
 * Selects the current zoom factor
 * @param state
 * @returns {number|number|*}
 */
export function selectCurrentZoom(state) {
    return state.editor.currentZoom;
}

/**
 * Selects the contentblock currently highlighted in the editor
 * @param state
 * @returns {null|ContentBLockIndex|*}
 */
export function selectContentBlockHighlighted(state) {
    return state.editor.contentblockHighlighted;
}

/**
 * Selects the index of the contentblock currently dragged if any
 * @param state
 * @returns {null|ContentBlockIndex|*}
 */
export function selectContentBlockDragging(state) {
    return state.editor.draggingContext?.target.contentblock;
}

/**
 * Selects whether the given contentblock is the one currently being dragged
 * @type {OutputSelector<[(function(*): null|ContentBlockIndex|*), (function(*, ContentBlockIndex): ContentBlockIndex)], boolean, (...args: SelectorResultArray<[(function(*): null|ContentBlockIndex|*), (function(*, ContentBlockIndex): ContentBlockIndex)]>) => (boolean & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): null|ContentBlockIndex|*), (function(*, ContentBlockIndex): ContentBlockIndex)]>> & {clearCache: () => void}}
 */
export const selectIsContentBlockDragging = createSelector([
    selectContentBlockDragging,
    (state, index: ContentBlockIndex) => index,
], (a: ContentBlockIndex, b: ContentBlockIndex) => !!a && !!b && contentBlockIndexMatch(a, b));

/**
 * Is true if a drag is active and the drag should result in a new contentblock
 * @param state
 * @returns {boolean}
 */
export function selectIsNewDragging(state): boolean {
    return !!state.editor.draggingContext?.target.isNew;
}

/**
 * Selects the boundingbox of the current drag in % of the page dimensions
 */
export const selectCurrentDraggingBox = createSelector([
    selectMousePosition,
    state => !!state.editor.draggingContext,
    state => !!state.editor.draggingContext ? (selectIsNewDragging(state) ? null : selectContentBlock(state, state.editor.draggingContext.target.contentblock)) : null,
    state => state.editor.draggingContext?.modeMove,
    state => state.editor.draggingContext?.modeVertical,
    state => state.editor.draggingContext?.modeHorizontal,
    selectCurrentPageBounds,
], (mousePosition, isDragging, targetData: ContentBlockData, modeMove: EditorData["draggingContext"]["modeMove"], modeVertical: EditorData["draggingContext"]["modeVertical"], modeHorizontal: EditorData["draggingContext"]["modeHorizontal"], pageBounds: EditorData["currentPageBounds"]) => {
    if (!isDragging) {
        return null;
    }

    let horizontalMin = !!targetData ? targetData.left : 0;
    let horizontalMax = !!targetData ? targetData?.left + targetData.width : 0;
    if (!!modeHorizontal) {
        const horizontalDistance = mousePosition.left - modeHorizontal.start;
        const hA = (modeHorizontal.old + horizontalDistance) / pageBounds.width;
        const hB = modeHorizontal.anchor / pageBounds.width;

        horizontalMin = Math.min(hA, hB);
        horizontalMax = Math.max(hA, hB);
    }

    if (!!modeMove) {
        const horizontalDistance = mousePosition.left - modeMove.startLeft;
        horizontalMin = horizontalMin + (horizontalDistance / pageBounds.width);
        horizontalMax = horizontalMax + (horizontalDistance / pageBounds.width);
    }

    let verticalMin = !!targetData ? targetData.top : 0;
    let verticalMax = !!targetData ? targetData.top + targetData.height : 0;
    if (!!modeVertical) {
        const verticalDistance = mousePosition.top - modeVertical.start;
        const vA = (modeVertical.old + verticalDistance) / pageBounds.height;
        const vB = modeVertical.anchor / pageBounds.height;

        verticalMin = Math.min(vA, vB);
        verticalMax = Math.max(vA, vB);
    }

    if (!!modeMove) {
        const verticalDistance = mousePosition.top - modeMove.startTop;
        verticalMin = verticalMin + (verticalDistance / pageBounds.height);
        verticalMax = verticalMax + (verticalDistance / pageBounds.height);
    }

    return {
        left: horizontalMin,
        top: verticalMin,
        width: horizontalMax - horizontalMin,
        height: verticalMax - verticalMin,
    };
});

/**
 * Selects the index of the ContentBlock currently edited if any
 * @param state
 * @returns {null|ContentBlockIndex|*}
 */
export function selectContentBlockEditing(state) {
    return state.editor.contentblockEditing;
}

/**
 * Selects the current mouseposition if the mouse is hovering the contentblock area
 * @param state
 * @returns {null|{left: number, top: number}|{top: number, left: number}|*}
 */
export function selectMousePosition(state) {
    return state.editor.currentMousePosition;
}

/**
 * Selects whether the sidebardrawer is currently extended or not
 * @param state
 * @returns {boolean|*}
 */
export function selectSideBarExpanded(state) {
    return state.editor.sideBarDrawer.expanded;
}

/**
 * Selects which tab is currently active in the sidebar
 * @param state
 * @returns {string|string|*}
 */
export function selectSideBarTab(state) {
    return state.editor.sideBarDrawer.tab;
}

/**
 * Selects the tutotial currently being run
 * @param state
 * @returns {string|null|*}
 */
export function selectRunTutorial(state) {
    return state.editor.tutorial;
}

/**
 * Checks whether the given tutorial is currently running
 * @type {OutputSelector<[(function(*): string|null|*), (function(*, string): string)], boolean, (...args: SelectorResultArray<[(function(*): string|null|*), (function(*, string): string)]>) => (boolean & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): string|null|*), (function(*, string): string)]>> & {clearCache: () => void}}
 */
export const selectIsTutorialRunning = createSelector([
    selectRunTutorial,
    (state, tutorial: string) => tutorial,
], (runningTutorial: string, targetTutorial: string) => runningTutorial === targetTutorial);

/**
 * Selects whether the reading order editor is currently active
 * @param state
 * @returns {boolean|*}
 */
export function selectReadingOrderEditorActive(state): boolean {
    return state.editor.readingOrderEditor.active;
}

/**
 * Selects the current selected order of the reading order editor
 * @param state
 * @returns {ContentBlockIndex[]|[]|*}
 */
export function selectReadingOrderEditorOrder(state): ContentBlockIndex[] {
    return state.editor.readingOrderEditor.order;
}

/**
 * Selects whether the given contentblock is already present in the selected readingorder of the reading order editor
 * @type {OutputSelector<[(function(*): ContentBlockIndex[]|[]|*), (function(*, ContentBlockIndex): ContentBlockIndex)], boolean, (...args: SelectorResultArray<[(function(*): ContentBlockIndex[]|[]|*), (function(*, ContentBlockIndex): ContentBlockIndex)]>) => (boolean & {clearCache: () => void}), GetParamsFromSelectors<[(function(*): ContentBlockIndex[]|[]|*), (function(*, ContentBlockIndex): ContentBlockIndex)]>> & {clearCache: () => void}}
 */
export const selectContentBlockIsInSelectedOrder = createSelector([
    selectReadingOrderEditorOrder,
    (state, target: ContentBlockIndex) => target,
], (order: ContentBlockIndex[], target: ContentBlockIndex) => order.filter(index => contentBlockIndexMatch(index, target)).length > 0);

/**
 * Selects whether the developer mode is active for edit menus
 * @param state
 * @returns {boolean|*}
 */
export function selectEditMenuDeveloperModeActive(state): boolean {
    return state.editor.editMenuDeveloperMode;
}

export function selectDarkModeEnabled(state): boolean {
    return state.editor.darkModeEnabled;
}
