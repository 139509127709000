// @flow

import { useEffect, useState } from "react";

/**
 * Used to shadow changes of the given property but otherwise works like useSate
 * @param property
 * @returns {(T|(<T>(value: ((<T>(prevState: T) => T) | T)) => void))[]}
 */
export function usePropertyShadow<T>(property: T) {
    const [ value, setValue ] = useState<T>(property);
    useEffect(() => setValue(property), [ property ]);

    return [ value, setValue ];
}
