// @flow

import type {ContentBlockIndex} from "../../../redux/data/contentblock/ContentBlockTypes";
import type {AccDocsIssueData, AccDocsIssueIndex} from "../../../redux/data/accdocsissues/AccDocsIssueTypes";
import {Button, Typography} from "@material-ui/core";
import styles from "./SidebarTabProgressIssueItem.module.css"
import {useDispatch, useSelector} from "react-redux";
import {selectAccDocsIssue, selectAccDocsIssuesIndicesOrdered} from "../../../redux/slice/AccDocsIssueSlice";
import {updateAccDocsIssue} from "../../../redux/action";
import {selectContentBlock, selectContentBlockById} from "../../../redux/slice/ContentBlockSlice";
import {selectDocument, selectDocumentIndex} from "../../../redux/slice/DocumentSlice";
import {useCallback, useEffect, useRef, useState} from "react";
import {setContentBlockEditing, setCurrentPage, setPageAndContentBlockEditing} from "../../../redux/slice/EditorSlice";
import {selectPage} from "../../../redux/slice/PageSlice";
import axios from "axios";
import type {PageIndex} from "../../../redux/data/page/PageTypes";
import { constants } from "../../../common/element/block/ContentBlockConstants";
import {useTranslation} from "react-i18next";

type SidebarTabProgressIssueItemProps = {
    accDocsIssueIndex: AccDocsIssueIndex,
    //accDocsIssue: AccDocsIssueData,
};

export function SideBarTabProgressIssueItem(props: SidebarTabProgressIssueItemProps){
    console.log("++++++ SideBarIssueItem ++++++++");
    const dispatch = useDispatch();
    const idx = props.accDocsIssueIndex;
    //console.log("accDocsIssueIndex: ", idx);
    //const issue = props.accDocsIssue;
    const issue = useSelector(state => selectAccDocsIssue(state, idx));
    console.log("issue: ", issue);



    const cbId = issue.contentBlock; // get the contenBlockId
    console.log("contenblockId: ", cbId);
    const doc = useSelector(selectDocumentIndex);
    const documentId = doc.document;
    console.log("document: ", doc, " ", documentId);
    const [pageId, setPageId] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [replText, setReplText] = useState("");
    const [type, setType] = useState("text");

    const hasCenteredPageRef = useRef<boolean>(false);

    useEffect(() => {
        console.log("using effect");
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/b/${cbId}`, {
            auth: {
                username: "demo@example.com",
                password: "demo",
            }
        }).then(response => {
            console.log("axios response is: ", response);
            setPageId(response.data.pageId);
            setReplText(response.data.details.alternativeText);
            setType(response.data.details.type);
        }).catch(error => {
            console.log("error while fetching", error);
        }).finally(() => {
            setLoading(false);
            console.log("finally");
        })
    }, []);


    const pageIdx = { document: documentId, page: pageId };
    const contentBlockIndex : ContentBlockIndex = { document: documentId, page: pageId, contentblock: cbId };
    //const pageNumber = useSelector(state => selectPage(state, pageIdx)).pageNumber;

    // Issue ID: {props.accDocsIssueIndex.accDocsIssue}
    return(
        <div>{!loading & !issue.done && <SideBarTabProgressIssueItemPopulated pageIndex={pageIdx} issue={issue} issueIndex={idx} replText={replText} contentBlockIndex={contentBlockIndex} type={type}/>}</div>
    )
}


type SidebarTabProgressIssueItemPopulatedProps = {
    pageIndex: PageIndex,
    issue: AccDocsIssueData,
    issueIndex: AccDocsIssueIndex,
    replText: string,
    contentBlockIndex: ContentBlockIndex,
    type: string,
};

export function SideBarTabProgressIssueItemPopulated(props: SidebarTabProgressIssueItemPopulatedProps) {
    const pageIdx = props.pageIndex;
    const issue = props.issue;
    const idx = props.issueIndex;
    const type = props.type;
    const replText = props.replText;
    const cbi = props.contentBlockIndex

    const {t} = useTranslation()

    const contentBlock = useSelector(state => selectContentBlock(state, cbi));
    const contentBlockConstants = constants(contentBlock);
    console.log("Contentblock: ", contentBlock);
    const dispatch = useDispatch();
    const pageNumber = useSelector(state => selectPage(state, pageIdx)).pageNumber;
    const goToIssue = () => {
        console.log("goto issue called", pageNumber);
        //dispatch(setCurrentPage({pageNumber: pageNumber}));
        //dispatch(setContentBlockEditing({contentBlock: cbi}));
        dispatch(setPageAndContentBlockEditing({pageNumber: pageNumber, contentBlock: cbi}));
    }
    const st = styles.issueItem;
    const style = {
        st,
        borderColor: contentBlockConstants.color,
        backgroundColor: contentBlockConstants.fadeColor,
    }
    const handleDoneClick = () => {
        dispatch(updateAccDocsIssue(idx, issue.id, true));
    };

    let displayed_type = "";
    switch(type) {
        case "image_with_text":
            displayed_type = "Image";
            break;
        case "mathexpression":
            displayed_type = "Forumlar";
            break;
        case "heading":
            displayed_type = "Heading";
            break;
        case "text":
            displayed_type = "Text Body";
            break;
        case "table":
            displayed_type = "Table";
            break;
        case "chemicalformula":
            displayed_type = "Chemical Formula";
            break;
        default:
            displayed_type = "unknown";
    }
    return (
        <div
            className={styles.issueItem}
            style={style}
            onClick={goToIssue}
        >
            <div style={{width:"70%"}}>
                <Typography>{t('sideBar.Progress.problemWith')} {t(`types.${type}`)} {t('sideBar.Progress.onPage')} {pageNumber + 1}</Typography>
            </div>


            <span style={{flexGrow: 1}}/>
            <Button
                variant={"outlined"}
                style={{height: "90%"}}
                onClick={handleDoneClick}
            >
                {t('sideBar.Progress.doneEdit')}
            </Button>

        </div>
    )
}
//<Typography>Problem. Solved? {issue.done? "done": "false"}</Typography>

