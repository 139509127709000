// @flow

import type { ContentBlockData, ContentBlockIndex, ContentBlockSTOMPPayload } from "./ContentBlockTypes";

/**
 * Converts a payload from STOMP messages to relevant contentblock data
 * @param payload
 * @returns {{top: *, left: *, width: *, details, height: *, ordinal}}
 */
export function contentBlockPayloadToData(payload: ContentBlockSTOMPPayload): ContentBlockData {
    return {
        left: payload.boundingBox?.x,
        top: payload.boundingBox?.y,
        width: payload.boundingBox?.width,
        height: payload.boundingBox?.height,
        runningAutofillServices: payload.runningAutofillServices,

        page: payload.page,
        ordinal: payload.ordinal,
        details: payload.details,
    }
}

/**
 * Converts contentblock data into a stomp message payload
 * @param data
 * @returns {{boundingBox: ({x, width, y, height}|undefined), details, ordinal}}
 */
export function contentBlockDataToPayload(data: ContentBlockData): ContentBlockSTOMPPayload {
    const hasBoundingBox: boolean = !!data.left || !!data.top || !!data.width || !!data.height;

    return {
        boundingBox: hasBoundingBox ? {
            x: data.left,
            y: data.top,
            width: data.width,
            height: data.height,
        } : undefined,

        details: data.details,
        ordinal: data.ordinal,
    }
}

/**
 * Checks if the given indices match
 * @param a
 * @param b
 * @returns {boolean}
 */
export function contentBlockIndexMatch(a: ContentBlockIndex, b: ContentBlockIndex): boolean {
    return a.document === b.document && a.page === b.page && a.contentblock === b.contentblock;
}
