// @flow

import { green, orange, red, yellow } from "@material-ui/core/colors";
import { Typography } from "@material-ui/core";
import { StatusBarElement } from "./StatusBarElement";
import { useCallback, useMemo } from "react";

type SocketStatusIndicatorProps = {
    connecting: boolean,
    connected: boolean,
    error: string | null,
}

export function SocketStatusIndicator(props: SocketStatusIndicatorProps) {
    const backgroundColor = props.connected ? green[600] : props.connecting ? yellow[600] : props.error ? red[600] : orange[600];
    const message = props.connected ? "Connected" : props.connecting ? "Connecting" : props.error ? "Error" : "Disconnected";
    const textMessage = props.connected ? "Connection established, have fun editing" : "Connection is not established, you may continue editing, but changes may be lost if connection cant be reestablished";

    const renderContent = useCallback(({ message }) => <Typography variant={"body2"}>Status: {message}</Typography>, []);
    const contentProps = useMemo(() => ({
        message: message,
        textMessage: textMessage,
    }), [message, textMessage]);

    return (
        <StatusBarElement color={backgroundColor} contentElement={renderContent} contentElementProps={contentProps}/>
    )
}
