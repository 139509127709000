// @flow

import { blue, green, lightBlue, orange, red, yellow } from "@material-ui/core/colors";
import { CropOriginal, Fireplace, Functions, Subject, TableChart, Title } from "@material-ui/icons";
import React from "react";
import type { ContentBlockData } from "../../../redux/data/contentblock/ContentBlockTypes";
import { ContentBlockEditorText } from "../../../components/blockeditor/editors/ContentBlockEditorText";
import { ContentBlockEditorHeading } from "../../../components/blockeditor/editors/ContentBlockEditorHeading";
import {
    ContentBlockEditorMathExpression
} from "../../../components/blockeditor/editors/ContentBlockEditorMathExpression";
import { ContentBlockEditorTable } from "../../../components/blockeditor/editors/ContentBlockEditorTable";
import { ContentBlockEditorImage } from "../../../components/blockeditor/editors/ContentBlockEditorImage";
import {
    ContentBlockEditorChemicalFormula
} from "../../../components/blockeditor/editors/ContentBlockEditorChemicalFormula";

export type ContentBlockTypeConstants = {
    type: string,
    name: string,
    color: string,
    fadeColor: string,
    icon: (props: any) => React$Node,
    editor: React$Ref => React$Node,
};

export const TextConstants: ContentBlockTypeConstants = {
    type: "text",
    name: "Text",
    color: red[500],
    fadeColor: red[100],
    icon: props => <Subject {...props}/>,
    editor: ref => <ContentBlockEditorText ref={ref}/>,
};

export const HeadingConstants: ContentBlockTypeConstants = {
    type: "heading",
    name: "Heading",
    color: blue[500],
    fadeColor: blue[100],
    icon: props => <Title {...props}/>,
    editor: ref => <ContentBlockEditorHeading ref={ref}/>,
};

export const MathExpressionConstants: ContentBlockTypeConstants = {
    type: "mathexpression",
    name: "Math",
    color: green[500],
    fadeColor: green[100],
    icon: props => <Functions {...props}/>,
    editor: ref => <ContentBlockEditorMathExpression ref={ref}/>,
};

export const TableConstants: ContentBlockTypeConstants = {
    type: "table",
    name: "Table",
    color: orange[500],
    fadeColor: orange[100],
    icon: props => <TableChart {...props}/>,
    editor: ref => <ContentBlockEditorTable ref={ref}/>,
};

export const ImageConstants: ContentBlockTypeConstants = {
    type: "image_with_text",
    name: "Image",
    color: yellow[500],
    fadeColor: yellow[100],
    icon: props => <CropOriginal {...props}/>,
    editor: ref => <ContentBlockEditorImage ref={ref}/>,
};

export const ChemicalFormulaConstants: ContentBlockTypeConstants = {
    type: "chemicalformula",
    name: "Chemistry",
    color: lightBlue[500],
    fadeColor: lightBlue[100],
    icon: props => <Fireplace {...props}/>,
    editor: ref => <ContentBlockEditorChemicalFormula ref={ref}/>,
}

export const NoneConstants: ContentBlockTypeConstants = {
    type: "none",
    name: "None",
    color: "#000000",
    fadeColor: "#555555",
    icon: props => null,
    editor: ref => null,
}

export function all() {
    return [ HeadingConstants, TextConstants, MathExpressionConstants, TableConstants, ImageConstants, ChemicalFormulaConstants, NoneConstants ];
}

export function allNotNone(): ContentBlockTypeConstants[] {
    return [ HeadingConstants, TextConstants, MathExpressionConstants, TableConstants, ImageConstants, ChemicalFormulaConstants ];
}

export function constants(contentblock: ContentBlockData): ContentBlockTypeConstants {
    if (!contentblock || !contentblock.details) {
        return NoneConstants;
    }

    const allConstants = all();
    return allConstants.filter(c => c.type === contentblock.details.type)[0] || NoneConstants;
}
