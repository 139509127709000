// @flow

import type { ContentBlockData, ContentBlockIndex } from "../../../redux/data/contentblock/ContentBlockTypes";
import type { ContentBlockTypeConstants } from "./ContentBlockConstants";
import { constants } from "./ContentBlockConstants";
import { useContentBlock } from "../../../redux/hook/useContentBlock";

import styles from "./ContentBlockBorder.module.css";
import { alpha } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
    selectContentBlockDragging,
    selectContentBlockEditing,
    selectContentBlockHighlighted,
    selectCurrentPageBounds,
    selectIsContentBlockDragging,
    selectMousePosition
} from "../../../redux/slice/EditorSlice";
import { useCallback, useRef } from "react";
import { contentBlockIndexMatch } from "../../../redux/data/contentblock/ContentBlockUtil";

type ContentBlockBorderProps = {
    contentBlock: ContentBlockIndex,
    onStartDrag?: (string, ContentBlockIndex) => void,
    onContextMenu?: ContentBlockIndex => void,
};

function relativePixelCoords(mouseposition: { left: number, top: number}, contentblock: ContentBlockData, pageBounds: {width: number, height: number}): {left: number, top: number, right: number, bottom: number} {
    const pixelLeft = contentblock.left * pageBounds.width;
    const pixelRight = pixelLeft + contentblock.width * pageBounds.width;
    const pixelTop = contentblock.top * pageBounds.height;
    const pixelBottom = pixelTop + contentblock.height * pageBounds.height;

    return {
        left: mouseposition.left - pixelLeft,
        right: pixelRight - mouseposition.left,
        top: mouseposition.top - pixelTop,
        bottom: pixelBottom - mouseposition.top,
    };
}

function isHovering(relativePixelPosition: {left: number, top: number, right: number, bottom: number}): boolean {
    const { left, top, right, bottom } = relativePixelPosition;
    return left >= 0 && top >= 0 && right >= 0 && bottom >= 0;
}

function isBorderHovering(border: string, relativePixelPosition: {left: number, top: number, right: number, bottom: number}): boolean {
    return relativePixelPosition[border] <= 5;
}

export function ContentBlockBorder(props: ContentBlockBorderProps) {
    const contentBlockData = useContentBlock(props.contentBlock);
    const contentBlockConstants: ContentBlockTypeConstants = constants(contentBlockData);

    const anyDragging = !!useSelector(selectContentBlockDragging); //if any contentblock is dragging we do not want to show the hover response of the contentblockborder
    const isDragging = useSelector(state => selectIsContentBlockDragging(state, props.contentBlock));
    const anyEditing = useSelector(state => !!selectContentBlockEditing(state));
    const isEditing = useSelector(state => {
        const editingIndex = selectContentBlockEditing(state);
        return !!editingIndex ? contentBlockIndexMatch(editingIndex, props.contentBlock) : false;
    });
    const isHighlighted = useSelector(state => {
        const highlightedIndex = selectContentBlockHighlighted(state);
        return !!highlightedIndex && contentBlockIndexMatch(props.contentBlock, highlightedIndex);
    });

    //hover and border calculations, done without useEffect etc, since it is recalculated everytime the mouseposition changes anyway
    const pageBounds = useSelector(selectCurrentPageBounds);
    const mousePosition = useSelector(selectMousePosition);
    const relativeMousePosition = (!!mousePosition && !!contentBlockData) ? relativePixelCoords(mousePosition, contentBlockData, pageBounds) : { left: -1, right: -1, top: -1, bottom: -1};
    const hovering = isHovering(relativeMousePosition);
    const hoverBorders = ["left", "right", "top", "bottom"].map(border => isBorderHovering(border, relativeMousePosition));
    let cursor = "move";

    //check for corner resize after edge resize so corners take precedent
    if (hoverBorders[0] || hoverBorders[1]) cursor = "ew-resize";
    if (hoverBorders[2] || hoverBorders[3]) cursor = "ns-resize";
    if ((hoverBorders[0] && hoverBorders[2]) || (hoverBorders[1] && hoverBorders[3])) cursor = "nwse-resize";
    if ((hoverBorders[0] && hoverBorders[3]) || (hoverBorders[1] && hoverBorders[2])) cursor = "nesw-resize";

    //mouse event handler
    const handleMouseDown = useCallback(event => {
        //check all clicked buttons
        const leftButton: boolean = (event.buttons & 1) > 0;
        const rightButton: boolean = (event.buttons & 2) > 0;
        const wheel: boolean = (event.buttons & 4) > 0;

        //act on wheel and left click
        if (leftButton && !rightButton && !wheel) {
            !!props.onStartDrag && props.onStartDrag(cursor, props.contentBlock);
        }
    }, [ cursor, props.onStartDrag, props.contentBlock ]);

    const borderDivRef = useRef<HTMLDivElement>(null);
    const handleContextMenu = useCallback((event: HTMLElementEventMap["contextmenu"]) => {
        event.preventDefault();
        !!props.onContextMenu && props.onContextMenu(props.contentBlock);
    }, [props.onContextMenu, props.contentBlock]);
    const onBorderDivChange = useCallback((borderDiv: HTMLDivElement) => {
        if (!!borderDivRef.current) {
            borderDivRef.current.removeEventListener("contextmenu", handleContextMenu, { passive: false });
        }
        borderDivRef.current = borderDiv;

        if (!!borderDivRef.current) {
            borderDivRef.current.addEventListener("contextmenu", handleContextMenu, { passive: false});
        }
    }, []);

    if (!contentBlockData || isDragging) { // contentblock which is being dragged is rendered seperately
        return null; //dont render unless contentblock is loaded
    }

    //calculate supplementary style
    const suppStyle = {
        cursor: anyEditing ? undefined : cursor,
        ...(anyDragging && { pointerevents: "none" }),
        ...(((hovering || isHighlighted) && !anyDragging && !anyEditing) && { backgroundColor: alpha(contentBlockConstants.color, 0.2)}),
    };

    return (
        <div
            ref={onBorderDivChange}
            onMouseDown={handleMouseDown}
            className={styles.contentblockBorder}
            style={{
                position: "absolute",
                left: `${contentBlockData.left * pageBounds.width - 2}px`,
                top: `${contentBlockData.top * pageBounds.height - 2}px`,
                width: `${contentBlockData.width * pageBounds.width}px`,
                height: `${contentBlockData.height * pageBounds.height}px`,

                borderColor: contentBlockConstants.color,
                ...suppStyle,
            }}
        >
            <span
                style={{
                    backgroundColor: contentBlockConstants.color,
                    borderBottomRightRadius: "2px",
                    opacity: isHighlighted || hovering ? 1 : 0,
                }}
            >
                {contentBlockConstants.name}
            </span>
        </div>
    );
}
