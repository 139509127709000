// @flow

import type { PdfElementData, PdfElementIndex, PdfElementSTOMPPayload } from "./PdfElementTypes";

/**
 * Converts a payload to the relevant pdfelement data
 * @param payload
 */
export function pdfElementPayloadToData(payload: PdfElementSTOMPPayload): PdfElementData {
    return {
        left: payload.boundingBox?.left,
        top: payload.boundingBox?.top,
        width: payload.boundingBox?.width,
        height: payload.boundingBox?.height,

        type: payload.type,
        ordinal: payload.ordinal,
        altText: !!payload.altText ? payload.altText : undefined,
        content: !!payload.content ? payload.content : undefined,
    }
}

/**
 * Converts pdfelement data to a stomp payload
 * @param data
 */
export function pdfElementDataToPayload(data: PdfElementData): PdfElementSTOMPPayload {
    // const hasBoundingBox: boolean = !!data.left || !!data.top || !!data.width || !!data.height;

    return {
        // only the ordinal can be changed on a pdfelement
        // boundingBox: hasBoundingBox ? {
        //     x: data.left,
        //     y: data.top,
        //     width: data.width,
        //     height: data.height,
        // } : undefined,
        // type: data.type,
        ordinal: data.ordinal,
        // altText: !!data.altText ? data.altText : undefined,
        // content: !!data.content ? data.content : undefined,
    }
}

/**
 * Checks whether the two indices match
 * @param a
 * @param b
 * @returns {boolean}
 */
export function pdfElementIndexMatch(a: PdfElementIndex, b: PdfElementIndex): boolean {
    return a.document === b.document && a.page === b.page && a.pdfelement === b.pdfelement;
}
