// @flow

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { PageLoadedPayload, PageUnloadedPayload } from "./PageSlice";
import { loaded as pageLoaded, unloaded as pageUnloaded } from "./PageSlice";
import type { PageElementCollection } from "./SliceTypes";
import type { PdfElementData, PdfElementIndex } from "../data/pdfelement/PdfElementTypes";
import { pageIndexMatch } from "../data/page/PageUtil";
import { pdfElementIndexMatch } from "../data/pdfelement/PdfElementUtil";
import { addTruthLayer, removeTruthLayer, updateGroundTruth } from "./SliceUtil";
import type { PageIndex } from "../data/page/PageTypes";

type PdfElementLoadingPayload = {
    index: PdfElementIndex,
};

type PdfElementLoadedPayload = {
    index: PdfElementIndex,
    version: number,
    data: PdfElementData,
};

type PdfElementUnloadedPayload = {
    index: PdfElementIndex,
};

type PdfElementUpdateUnconfirmedPayload = {
    origin: string,
    index: PdfElementIndex,
    data: PdfElementData,
};

type PdfElementUpdateRejectedPayload = {
    origin: string,
    index: PdfElementIndex,
};

type PdfElementUpdateConfirmedPayload = {
    origin: string,
    index: PdfElementIndex,
    version: number,
    data: PdfElementData,
};

type PdfElementAllUnloadedPayload = {
    page: PageIndex,
};

const pdfElementsInitialState: { pdfElements: PageElementCollection<PdfElementIndex, PdfElementData>[] } = { pdfElements: [] }

const PdfElementSlice = createSlice({
    name: "pdfelements",
    initialState: pdfElementsInitialState,
    reducers: {
        loading: (state, action: PayloadAction<PdfElementLoadingPayload>) => {
            // find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // add new element to page collection
                state.pdfElements[pageIndex].elements = [ ...state.pdfElements[pageIndex].elements, {
                    index: action.payload.index,
                    groundTruth: null,
                    groundTruthVersion: 0,
                    truthLayers: [],
                }];
            }
        },
        loaded: (state, action: PayloadAction<PdfElementLoadedPayload>) => {
            // find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) {//find element
                const elementIndex = state.pdfElements[pageIndex].elements.findIndex(element => pdfElementIndexMatch(element.index, action.payload.index));
                if (elementIndex >= 0) {
                    state.pdfElements[pageIndex].elements[elementIndex].groundTruth = action.payload.data;
                    state.pdfElements[pageIndex].elements[elementIndex].groundTruthVersion = action.payload.version;
                }
            }
        },
        unloaded: (state, action: PayloadAction<PdfElementUnloadedPayload>) => {
            //find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) { // remove element
                state.pdfElements[pageIndex].elements = state.pdfElements[pageIndex].elements.filter(element => !pdfElementIndexMatch(element.index, action.payload.index));
            }
        },
        updateUnconfirmed: (state, action: PayloadAction<PdfElementUpdateUnconfirmedPayload>) => {
            //find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) {
                const elementIndex = state.pdfElements[pageIndex].elements.findIndex(element => pdfElementIndexMatch(element.index, action.payload.index));
                if (elementIndex >= 0) { //add new truth layer
                    addTruthLayer(state.pdfElements[pageIndex].elements[elementIndex], action.payload.origin, action.payload.data);
                }
            }
        },
        updateRejected: (state, action: PayloadAction<PdfElementUpdateRejectedPayload>) => {
            //find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) {
                const elementIndex = state.pdfElements[pageIndex].elements.findIndex(element => pdfElementIndexMatch(element.index, action.payload.index));
                if (elementIndex >= 0) {//remove truthlayer with matching origin
                    removeTruthLayer(state.pdfElements[pageIndex].elements[elementIndex], action.payload.origin);
                }
            }
        },
        updateConfirmed: (state, action: PayloadAction<PdfElementUpdateConfirmedPayload>) => {
            // find page
            const pageIndex = state.pdfElements.findIndex((collection) => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }));
            if (pageIndex >= 0) {
                const elementIndex = state.pdfElements[pageIndex].elements.findIndex(element => pdfElementIndexMatch(element.index, action.payload.index));
                if (elementIndex >= 0) {
                    removeTruthLayer(state.pdfElements[pageIndex].elements[elementIndex], action.payload.origin);
                    updateGroundTruth(state.pdfElements[pageIndex].elements[elementIndex], action.payload.data, action.payload.version);
                }
            }
        },
        allUnloaded: (state, action: PayloadAction<PdfElementAllUnloadedPayload>) => {
            const pageIndex = state.pdfElements.findIndex(pageContainer => pageIndexMatch(pageContainer.page, action.payload.page));
            if (pageIndex >= 0) {
                state.pdfElements[pageIndex].elements = [];
            }
        },
    },
    extraReducers: {
        [pageLoaded]: (state, action: PayloadAction<PageLoadedPayload>) => {
            state.pdfElements = [ ...state.pdfElements, {
                page: action.payload.index,
                elements: [],
            }];
        },
        [pageUnloaded]: (state, action: PayloadAction<PageUnloadedPayload>) => {
            state.pdfElements = state.pdfElements.filter(collection => pageIndexMatch(collection.page, {
                document: action.payload.index.document,
                page: action.payload.index.page,
            }))
        },
    }
});

export const { loading, loaded, unloaded, updateUnconfirmed, updateRejected, updateConfirmed, allUnloaded } = PdfElementSlice.actions;
export default PdfElementSlice.reducer;

//basic selector
function selectAll(state) {
    return state.pdfelements.pdfElements;
}

//selectors
/**
 * Selects all pdfelements on a specific page
 */
export const selectIndicesFromPage = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (pdfelements: PageElementCollection<PdfElementIndex, PdfElementData>[], page: PageIndex) => {
        //find page index
        const pageIndex = pdfelements.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) { //found the page
            return pdfelements[pageIndex].elements.map(element => element.index);
        } else {
            return null; //not loaded
        }
    });

/**
 * Selects all pdfelements on a specific page
 */
export const selectIndicesFromPageOrdered = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (pdfelements: PageElementCollection<PdfElementIndex, PdfElementData>[], page: PageIndex) => {
        //find page index
        const pageIndex = pdfelements.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) { //found the page
            return [ ...pdfelements[pageIndex].elements ].sort((a, b) => b.resolvedTruth.ordinal - a.resolvedTruth.ordinal).map(element => element.resolvedTruth);
        } else {
            return null; //not loaded
        }
    });

/**
 * Selects all pdfelements on a specific page
 */
export const selectFromPage = createSelector([
        selectAll,
        (state, page: PageIndex) => page,
    ],
    (pdfelements: PageElementCollection<PdfElementIndex, PdfElementData>[], page: PageIndex) => {
        //find page index
        const pageIndex = pdfelements.findIndex(collection => pageIndexMatch(collection.page, page));
        if (pageIndex >= 0) { //found the page
            return pdfelements[pageIndex].elements.map(element => element.resolvedTruth);
        } else {
            return null; //not loaded
        }
    });

/**
 * Selects all pdfelements on a page but orders it by their ordinal
 */
export const selectFromPageOrdered = createSelector([selectFromPage],
    (pdfelements: PdfElementData[]) => [ ...pdfelements ].sort((a, b) => b.ordinal - a.ordinal)
);

/**
 * Selects a specific pdfelement
 */
export const selectPdfElement = createSelector([
        selectAll,
        (state, element: PdfElementIndex) => element,
    ],
    (pdfelements: PageElementCollection<PdfElementIndex, PdfElementData>[], element: PdfElementIndex) => {
        //find page index
        const pageIndex = pdfelements.findIndex(collection => pageIndexMatch({
            document: element.document,
            page: element.page,
        }, collection.page));
        if (pageIndex >= 0) { //found the page
            const elementIndex = pdfelements[pageIndex].elements.findIndex(e => pdfElementIndexMatch(e.index, element));
            if (elementIndex >= 0) {
                return pdfelements[pageIndex].elements[pageIndex].resolvedTruth;
            } else {
                return null; //not loaded
            }
        } else {
            return null; //not loaded
        }
    });
