// @flow

import type { ElementContainer } from "./SliceTypes";

/**
 * Creates an object based on the given containers groundtruth with all truth layers applied
 * @param container
 * @returns {T}
 */
export function getResolvedTruth<I, T>(container: ElementContainer<I, T>): T {
    return container.truthLayers.map(layer => layer.contents).reduce((prev, current) => ({ ...prev, ...current }), container.groundTruth);
}

/**
 * Adds new truthlayer from the given origin and data
 * @param container
 * @param origin
 * @param data
 */
export function addTruthLayer<I, T>(container: ElementContainer<I, T>, origin: string, data: T) {
    container.truthLayers = [
        ...container.truthLayers,
        {
            origin: origin,
            timestamp: +new Date(),
            contents: data,
        },
    ];

    //update resolved truth
    container.resolvedTruth = getResolvedTruth(container);
}

/**
 * Removes all layers with the given origin from the given container
 * @param container
 * @param origin
 */
export function removeTruthLayer<I, T>(container: ElementContainer<I, T>, origin: string) {
    container.truthLayers = container.truthLayers.filter(layer => layer.origin !== origin);

    //update resolved truth
    container.resolvedTruth = getResolvedTruth(container);
}

/**
 * Updates the given containers groundtruth to reflect the given update
 * @param container
 * @param update
 * @param version
 */
export function updateGroundTruth<I, T>(container: ElementContainer<I, T>, update: T, version: number) {
    if (container.groundTruthVersion < version) {
        container.groundTruth = {
            ...container.groundTruth,
            ...update,
        };
        container.groundTruthVersion = version;

        //update groundtruth
        container.resolvedTruth = getResolvedTruth(container);
    }
}
