// @flow

import { addMessage, removeMessage } from "../slice/LoggingSlice";

/**
 * Converts logging actions into actions to store the messages in the LoggingSlice and automatic removal after the set timeout
 * @param storeApi
 * @returns {function(*): function(*): void}
 * @constructor
 */
export const LoggingMiddleware = storeApi => next => {
    //keep track of index to allow
    const indexRef: { current: number } = { current: 0 };

    return action => {
        if (action.type === 'log') {
            const index = indexRef.current;
            indexRef.current = indexRef.current + 1;

            storeApi.dispatch(addMessage({
                message: {
                    ...action.payload,
                    timestamp: +new Date(),
                },
                index: index,
            }));

            //remove message after set timeout
            if (action.payload.timeout > 0) {
                setTimeout(() => storeApi.dispatch(removeMessage({ index: index })), action.payload.timeout);
            }
        } else {
            next(action);
        }
    };
};
