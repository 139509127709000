import React from "react";
import { Route, Switch } from "react-router";
import { BlockEditorContent } from "./components/blockeditor/BlockEditorContent";
import { HomeViewContent } from "./components/home/HomeViewContent";
import LoadingScreen from "./components/home/LoadingScreen";

function ContentRouter() {
    return (
        <Switch>
            <Route
                exact
                path="/"
                component={HomeViewContent}
            />
            <Route
                exact
                path={"/loading/:document"}
                component={LoadingScreen}
            />
            <Route
                exact
                path="/blockedit/:document"
                component={BlockEditorContent}
            />
        </Switch>
    );
}

export { ContentRouter };
