// @flow

import React, { useState, useMemo } from "react";
import { ThemeProvider } from "@material-ui/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery'; //used to find out which theme user prefers
import { ContentRouter } from "./routes";
import { createTheme } from "@material-ui/core";
import type { Authentication } from "./api/structure/Base";
import { HotkeyProvider } from "./hotkeys/HotkeyProvider";
import { StatusBar } from "./components/statusbar/StatusBar";

function App() {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)'); // find out if user prefers dark mode @todo: doesn't seem to work for all browsers
    const [mode, setMode] = useState(prefersDarkMode ? 'dark': 'light');
    const theme = useMemo(
        () => createTheme({
            palette: {
                type: mode,
                primary: {
                    main: "#4064AD"
                },
                secondary: {
                    main: "#FF6600"
                },
            }
        }),[mode]
    );

    //user login is a global variable
    const [ authentication: Authentication | null, setAuthentication: (Authentication | null) => void ] = useState<Authentication | null>({
        username: "demo@example.com",
        password: "demo",
    });

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <header>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            </header>
            <ThemeProvider theme={theme}>
                <HotkeyProvider>
                    <ContentRouter/>
                </HotkeyProvider>
            </ThemeProvider>
            <StatusBar/>
        </div>
    );
}

export default App;
