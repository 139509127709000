// @flow

import { ContentBlockBorder } from "../../common/element/block/ContentBlockBorder";
import { useContentBlockIndices } from "../../redux/hook/useContentBlock";
import { useCallback, useRef } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
    endDrag,
    selectContentBlockDragging,
    selectContentBlockEditing,
    selectCurrentDraggingBox,
    selectCurrentPageBounds,
    selectIsNewDragging,
    setContentBlockEditing,
    setCurrentMousePosition
} from "../../redux/slice/EditorSlice";
import type { ContentBlockIndex } from "../../api/structure/ContentBlock";
import { createBlock, startContentBlockDrag, startNewDrag, updateBlockBounds } from "../../redux/action";
import { ContentBlockBorderDragging } from "../../common/element/block/ContentBlockBorderDragging";
import { selectDocument } from "../../redux/slice/DocumentSlice";

type ContentBlockRendererProps = {
    isPageMove?: boolean,
};

export function ContentBlockRenderer(props: ContentBlockRendererProps) {
    const dispatch = useDispatch();
    const store = useStore(); //only use store in callbacks and selectors. Never set data.
    const pageBounds = useSelector(selectCurrentPageBounds);
    const contentblocks: ContentBlockIndex[] = useContentBlockIndices();

    const rendererRef = useRef();

    /*
        Handle Dragging contentblocks. There are 9 different modes:
        - 1 resize for each edge
        - 1 resize for each corner
        - moving the contentblock

        The implementation tries to simplify this by implementing resize-horizontal and resize-vertical behavior wich can be active simultaneously
        to allow resizing both when the user grabs a corner
     */

    const handleEmptyDragStart = useCallback(() => {
        if (!!selectContentBlockEditing(store.getState())) { //if already editing, dont start drags
            return;
        }

        dispatch(startNewDrag())
    }, []);
    const handleContentBlockDragStart = useCallback((mode: string, block: ContentBlockIndex) => {
        if (!!selectContentBlockEditing(store.getState())) { //if already editing, dont start drags
            return;
        }

        dispatch(startContentBlockDrag(block,
            mode === "move",
            mode === "ns-resize" || mode === "nwse-resize" || mode === "nesw-resize",
            mode === "ew-resize" || mode === "nwse-resize" || mode === "nesw-resize"
        ));
    }, []);
    const handleDragStop = useCallback(() => {
        const isNewDragging = selectIsNewDragging(store.getState());
        const isBlockDragging = !!selectContentBlockDragging(store.getState());

        //handle drag ending
        if (isNewDragging || isBlockDragging) {
            let currentBox: { left: number, top: number, width: number, height: number } = selectCurrentDraggingBox(store.getState());

            //clamp box to appropriate values
            const newLeft = Math.max(0, Math.min(1, currentBox.left));
            const newRight = Math.max(0, Math.min(1, currentBox.left + currentBox.width));
            const newTop = Math.max(0, Math.min(1, currentBox.top));
            const newBottom = Math.max(0, Math.min(1, currentBox.top + currentBox.height));

            currentBox = {
                left: newLeft,
                width: newRight - newLeft,
                top: newTop,
                height: newBottom - newTop,
            };

            if (isBlockDragging) {
                //set contentblock to obtained new values
                dispatch(updateBlockBounds(selectContentBlockDragging(store.getState()), currentBox));

            } else if (isNewDragging) {
                //if box is unlikely to be accidental, add a contentblock for it
                //we assume, that a box bigger than 16 pixels in one direction is not an accident
                const pageBounds = selectCurrentPageBounds(store.getState());
                if ((currentBox.width * pageBounds.width) > 16 || (currentBox.height * pageBounds.height) > 16) {

                    //create new contentblock based on dragging context and set as editing
                    dispatch(createBlock({
                        ...currentBox,
                        details: {
                            type: "text",
                            language: selectDocument(store.getState()).language || "en",
                            replacementText: "",
                        },
                    }));
                }
            }


            //remove dragging context
            dispatch(endDrag({}));
        }
    }, []);

    const handleMouseEnter = useCallback(() => {}, []); //mouse enter is not really necessary since it just does the same as mouse move for now
    const handleMouseLeave = useCallback((event) => {
        handleDragStop();
        dispatch(setCurrentMousePosition(null));
    }, [ handleDragStop ]);
    const handleMouseMove = useCallback((event) => {
        if (props.isPageMove) { //only track mouse position, if page is not currently being moved to avoid too many redux events
            return;
        }

        const bounds = rendererRef.current?.getBoundingClientRect();
        if (bounds) {
            dispatch(setCurrentMousePosition({
                left: event.clientX - bounds.left,
                top: event.clientY - bounds.top,
            }))
        }
    }, [ props.isPageMove ]);
    const handleMouseUp = useCallback(event => (event.button === 0) && handleDragStop(), [ handleDragStop ]);
    const handleMouseDown = useCallback(event => {
        //check all clicked buttons
        const leftButton: boolean = (event.buttons & 1) > 0;
        const rightButton: boolean = (event.buttons & 2) > 0;
        const wheel: boolean = (event.buttons & 4) > 0;

        //only react on left click
        if (leftButton && !rightButton && !wheel && !selectContentBlockDragging(store.getState())) {
            handleEmptyDragStart();
        }
    }, [ handleEmptyDragStart ]);

    const handleContextMenu = useCallback((target: ContentBlockIndex) => {
        if (!selectContentBlockEditing(store.getState())) {
            dispatch(setContentBlockEditing({ contentblock: target }));
        }
    }, [ dispatch ]);

    return (
        <div
            ref={rendererRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            style={{
                position: "absolute",
                width: `${pageBounds.width}px`,
                height: `${pageBounds.height}px`,
                zIndex: 10,
            }}
        >
            {contentblocks.map(contentblock => <ContentBlockBorder
                key={contentblock.contentblock}
                contentBlock={contentblock}
                onStartDrag={handleContentBlockDragStart}
                onContextMenu={handleContextMenu}
            />)}

            {/* Renders the current dragging box accordingly */}
            <ContentBlockBorderDragging/>
        </div>
    )
}
