// @flow
import {selectTask, selectTaskByType} from "../../../redux/slice/TaskSlice";
import type { TaskData } from "../../../redux/data/task/TaskTypes";
import {Box, CircularProgress, FormLabel, LinearProgress, Typography} from "@material-ui/core";
import { connect } from "react-redux";
import {useTranslation} from "react-i18next";
import {HtmlTooltip} from "../tooltips/HtmlTooltip";


type SegmentationTaskProgressDisplayProps = {
    segmentationTask: ?TaskData
};

function mapStateToProps(state) : SegmentationTaskProgressDisplayProps {
    const result = {
        segmentationTask: selectTaskByType(state, "segmentation")
    };
    return result;
}

function SegmentationTaskProgressDisplay(props: SegmentationTaskProgressDisplayProps) {
    const segmentationTask = props.segmentationTask;

    const segmentationOngoing = !!segmentationTask && !segmentationTask?.successful;

    const percentage = !segmentationOngoing ? 100 : Math.round(segmentationTask?.percentage);

    const { t, i18n } = useTranslation();

    return (
        <HtmlTooltip title={t('common.extractionTooltip')}>
            <div
                style={{
                    backgroundColor: "#999999",
                    top: "1rem",
                    left: "1rem",
                    position: "absolute",
                    padding: ".3rem",
                    zIndex: "50"
                }}
            >
                {segmentationOngoing ?
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Typography variant={"body2"}>{t("common.segmentationProgress")}</Typography>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress variant="determinate" value={percentage} />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                >{percentage}</Typography>
                            </Box>
                        </Box>
                    </div>:
                    <Typography variant={"body2"}>Segmentation Done </Typography>

                }

            </div>
        </HtmlTooltip>
    );
}

export default connect(mapStateToProps)(SegmentationTaskProgressDisplay)
