// @flow
import type { ContentBlockIndex } from "../../redux/data/contentblock/ContentBlockTypes"; // index from database
import { selectContentBlockEditing, setContentBlockHighlighted } from "../../../redux/slice/EditorSlice";
import styles from "./ReadingOrderSideBarItem.module.css";
import {useContentBlockIndices, useEditableContentBlock} from "../../../redux/hook/useContentBlock";
import { useDispatch, useSelector } from "react-redux";
import { contentBlockIndexMatch } from "../../../redux/data/contentblock/ContentBlockUtil";
import { constants } from "../../../common/element/block/ContentBlockConstants";
import { useSortable } from "@dnd-kit/sortable";
import { forwardRef } from "react";
import { CSS } from "@dnd-kit/utilities";
import {selectContentBlock, selectContentBlockById} from "../../../redux/slice/ContentBlockSlice";
import {useTranslation} from "react-i18next";


type ReadingOrderSidebarItemProps = {
    contentBlockIndex: ContentBlockIndex,
    key: string,
    id: string,
};

export function ReadingOrderSidebarItem(props:ReadingOrderSidebarItemProps){
    // need to call all hooks (start with use) before any conditionals
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const currentlyEdited = useSelector(selectContentBlockEditing);
    const contentBlockData = useEditableContentBlock(props.contentBlockIndex); // useEditable to be able to change ordinal
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});
    //console.log("contenblockindex in Item: ",props.contentBlockIndex);
    if (!contentBlockData) {
        return null;
    }

    const contentBlockConstants = constants(contentBlockData);
    // get the alt text
    let replText = contentBlockData.details.alternativeText;

    // contentBlockData.ordinal = 1; this is how to access the ordinal

    const type = contentBlockData? contentBlockData.details.type: "placeholder";
    let displayType = "";
    switch (type) {
        case "image_with_text":
            displayType = t('common.types.image');
            break;
        case "mathexpression":
            displayType = t('common.types.math');
            break;
        case "heading":
            displayType = t('common.types.heading');
            break;
        case "text":
            displayType = t('common.types.text');
            break;
        case "table":
            displayType = t('common.types.table');
            break;
        case "chemicalformula":
            displayType = t('common.types.chem');
            break;
        default:
            displayType = t('common.types.unknown');
    }
    const ordinal = contentBlockData? contentBlockData.ordinal: "placeholder";


    const isActiveEditing = !!currentlyEdited && contentBlockIndexMatch(props.contentBlockIndex, currentlyEdited);
    // !! to indicate that we want to check that currentlyEdited is not null/undefined

    /*console.log("ordinal: ", ordinal);
    console.log("attributes: ", attributes);
    console.log("listeners: ", listeners);
    console.log("setNodeRef: ", setNodeRef);
    console.log("transform: ", transform); */
    /*
    const style = transform ? {
        transform: CSS.Translate.toString(transform),
    } : undefined;
     */
    const st = styles.readingOrderItem;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        st,
        borderColor: contentBlockConstants.color,
        backgroundColor: contentBlockConstants.fadeColor,
        "--lh": "30px",
    };

    /**
     * @Todo: Find out if pointer within or closest corners is better suited as intersection method
     * useDroppable() returns setNodeRef which contains id (has to be unique) that can registered in the DnD context
     */

    return(
        <div
            ref={setNodeRef}
            style={style}
            className={styles.readingOrderItem}
            {...attributes}
            {...listeners}
            onMouseOver={() => dispatch(setContentBlockHighlighted({ contentblock: props.contentBlockIndex }))}
            onMouseLeave={() => dispatch(setContentBlockHighlighted({ contentblock: null }))}
            id={props.id}
        >
            {replText ? replText.slice(0,50) : displayType}
        </div>
    );

}
// //
export const ReadingOrderSidebarItemDragOverlay = forwardRef(({id, ...props}, ref) => {
    console.log("item id: ", id);
    const {t} = useTranslation();
    //const cb = useSelector(state => selectContentBlock(state, JSON.parse(id)));
    const contentBlockIndices = useContentBlockIndices();
    console.log("id", id);
    const idx = contentBlockIndices.findIndex(elem => elem.contentblock === id);
    console.log("idx: ", idx);
    const cb = useSelector((state => selectContentBlock(state, contentBlockIndices[idx])));
    console.log("cb: ", cb);
    //const cb = useSelector(selectContentBlock(idx));
    //cb.details.alternativeText.slice(0,50);
    return (
        <div
            style={{
                backgroundColor: "#d8d8d8",
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "30px",
                lineHeight: "30px",
                padding: "2px 2px 2px 10px", /* top right bottom left */
                margin: "1px 10px 2px 2px", /* top right bottom left */
                borderWidth: "2px",
                borderColor: "373737",
                borderStyle: "solid",
                borderRadius: ".5em", /* creates rounded corners */
                width: "17rem",
                overflow: "hidden",
                textOverflow: "ellipsis",

            }}
            {...props}
            ref={ref}
        >
            {cb.details.alternativeText.slice(0,50) || t(`types.${cb.details.type}`)}
        </div>
    )
});
