// @flow

import React, { forwardRef } from "react";
import {useEditableMetadata} from "../../../redux/hook/useMetadata";
import {Button, Chip, Dialog, Grid, TextField, Typography} from "@material-ui/core";
import styles from "../../../components/blockeditor/sidebar/SideBarTabMetadata.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { MetadataFrame } from "./MetadataFrame";


const useStyles = makeStyles( (theme) => ({
    formControl: {

    },
    textField: {
        width: 750,
    },
    root: {
        padding: "0.5em",
    },
}));

export function MetadataEditorDialog(props) {
    const { onClose, selectedValue, open } = props;
    const metadata = useEditableMetadata();
    //const initialLanguageIndex = LanguageCodes.indexOf(LanguageCodes.find(x => x.languageCode === metadata.language));
    const {t, i18n} = useTranslation();
    const handleClose = () => {
        console.log("closing dialog");
        onClose();
    };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby={"metadata"} maxWidth={"md"} fullWidth={true}>
            <div className={styles.container}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    padding: ".5rem",
                }}
            >
                <MetadataFrame/>
                <Button
                    variant={"outlined"}
                    onClick={handleClose}
                >
                    {t('common.done')}
                </Button>
            </div>
        </Dialog>
    );
};
