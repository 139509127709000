// @flow

import { useMemo } from "react";

type StatusBarElementProps = {
    color?: string,
    contentElement: any => React$Node,
    contentElementProps?: any,
    contentNoMemo?: boolean,
    popoverColor?: string,
    popoverElement?: any => React$Node,
    popoverProps?: any,
}

/**
 * Element that should wrap all elements in a status bar
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function StatusBarElement(props: StatusBarElementProps) {
    const contentMemo = useMemo(() => props.contentNoMemo ? null : props.contentElement(props.contentElementProps)
        , [ props.contentNoMemo, props.contentElement, props.contentElementProps]);

    return (
        <span
            style={{
                backgroundColor: props.color,

                display: "flex",
                flexDirection: "row",
                alignItems: "center",

                paddingLeft: "0.5rem",
                paddingRight: "0.5rem",
            }}
        >
            {props.contentNoMemo ? props.contentElement(props.contentElementProps) : contentMemo}
        </span>
    )
}
