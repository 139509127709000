// @flow

import type { SideBarTabConfig } from "./SideBarDrawer";
import { GetApp } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import styles from "./SideBarTabDownload.module.css";
import { useSelector } from "react-redux";
import { selectDocumentIndex } from "../../../redux/slice/DocumentSlice";
import { useMemo } from "react";
import type { DocumentIndex } from "../../../redux/data/document/DocumentTypes";
import { TargetDownloadTray } from "../tutorials/targets";
import {useTranslation} from "react-i18next";

/**
 * Renders a list of download options for annotated documents from the backend.
 * @returns {JSX.Element}
 * @constructor
 */
export function SideBarTabDownload() {
    const documentIndex: DocumentIndex = useSelector(selectDocumentIndex);
    const taggedUrl = useMemo(() => `${process.env.REACT_APP_BACKEND_URL}/d/${documentIndex.document}/downloadTagged`, [ documentIndex ]);
    const wordUrl = useMemo(() => `${process.env.REACT_APP_BACKEND_URL}/d/${documentIndex.document}/downloadWord/`, [ documentIndex ]);
    const ePUBUrl = useMemo(() => `${process.env.REACT_APP_BACKEND_URL}/d/${documentIndex.document}/downloadEPUB/`, [ documentIndex ]);

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.container}>
            <Typography variant={"h6"}>
                {t('sideBar.Download.doneEditing')}
            </Typography>
            <Typography variant={"h6"}>
                {t('sideBar.Download.fileFormats')}
            </Typography>
            <Button
                variant={"outlined"}
                startIcon={<GetApp/>}
                onClick={() => {
                    let tab = window.open(taggedUrl, "_blank");
                    tab.focus();
                }}
            >
                {t('sideBar.Download.downloadPDF')}
            </Button>

            <Button
                variant={"outlined"}
                startIcon={<GetApp/>}
                onClick={() => {
                    let option1=document.getElementById('word-noimg');
                    let tmpEPUBURL = ePUBUrl;
                    if (option1.checked) {
                        tmpEPUBURL += "noimg";
                    }
                    let tab = window.open(tmpEPUBURL, "_blank");
                    tab.focus();
                }}
            >
                {t('sideBar.Download.downloadEPUB')}
            </Button>


            <Button
                variant={"outlined"}
                startIcon={<GetApp/>}
                onClick={() => {
                    let option1=document.getElementById('word-noimg');
                    let tmpWordURL = wordUrl;
                    if (option1.checked) {
                        tmpWordURL += "noimg";
                    }
                    /*let option2=document.getElementById('word-novisualbolditalic');
                    if (option2.checked) {
                        tmpWordURL += "nobolditalic";
                    }*/
                    let tab = window.open(tmpWordURL, "_blank");
                    tab.focus();
                }}
            >
                {t('sideBar.Download.downloadWord')}
            </Button>
            <input type="checkbox" id="word-noimg" /><label for="word-noimg">{t('sideBar.Download.noIncludeImg')}</label>

            {/* <input type="checkbox" id="word-novisualbolditalic" /><label for="word-novisualbolditalic">bold/italic on/off</label> */}
        </div>
    );
}

/**
 * Configuration for the Sidebar tab showing the download options for the user
 * @type {{name: string, icon: (function(*): *), id: string, render: (function(): *)}}
 */
export const SideBarTabDownloadConfig: SideBarTabConfig = {
    id: "download",
    name: "Download",
    trayTarget: TargetDownloadTray.className(),
    icon: props => <GetApp {...props}/>,
    render: SideBarTabDownload,
};
