// @flow

import {Typography} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export function TextDescriptionHelperFrame() {
    const {t, i18n} = useTranslation();
    return (
        <Typography>{t('tutorials.textAltTexts.text1')}</Typography>
    )
}
