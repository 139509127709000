// @flow

import {
    Description,
    Help,
    HelpOutline,
    HelpSharp,
    HelpRounded,
    QuestionAnswer,
    HelpOutlineRounded, HelpOutlineOutlined, HelpOutlineTwoTone, HelpTwoTone, Info, HelpCenter
} from "@material-ui/icons";
import React, {useState} from "react";
import {Chip, TextField, Tooltip, Typography} from "@material-ui/core";
import {useEditableMetadata} from "../../../redux/hook/useMetadata";
import {LanguageCodes} from "../../constants/LanguageCodesIETF";
import {useTranslation} from "react-i18next";
import {HtmlTooltip} from "../tooltips/HtmlTooltip";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "./MetadataFrame.module.css";



type AddKeywordChipProps = {
    onAddKeyword: string => void,
};

function AddKeywordChip(props: AddKeywordChipProps) {
    const { onAddKeyword } = props;

    const [ editing, setEditing ] = useState<boolean>(false);
    const [ currentKeyword, setCurrentKeyword ] = useState<string>("");

    const stopEditing = (keyword: string, save: boolean) => {
        if (save && currentKeyword.trim().length > 0) {
            onAddKeyword(keyword);
        }

        setEditing(false);
        setCurrentKeyword("");
    }

    return (
        <Chip
            label={editing ? (
                <input
                    value={currentKeyword}
                    onChange={e => setCurrentKeyword(e.target.value)}

                    autoFocus
                    onBlur={() => stopEditing(currentKeyword, true)}
                    onKeyPress={e => e.key === "Enter" && stopEditing(currentKeyword, true)}
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        outline: "none",
                    }}
                />
            ) : "+"}
            onClick={() => {
                if (!editing) {
                    setEditing(true);
                }
            }}
        />
    )
}


export function MetadataFrame() {
    const metadata = useEditableMetadata();

    const initialLanguageIndex = LanguageCodes.indexOf(LanguageCodes.find(x => x.languageCode === metadata.language));
    const { t, i18n } = useTranslation();

    return (
        <div className={styles.container}>
            <Typography variant={"h6"}>
                {t('sideBar.Metadata.metadata')}
            </Typography>
            <div className={styles.row}>
                <TextField
                    className={styles.text}
                    variant={"outlined"}
                    label={t('sideBar.Metadata.title')}
                    error={(metadata.title || "").length <= 0}
                    value={metadata.title || ""}
                    onChange={e => metadata.title = e.target.value}
                />
                <HtmlTooltip title={t('sideBar.Metadata.helpTitle')}><span style={{marginLeft: "1rem"}} ><HelpOutline/></span></HtmlTooltip>
            </div>

            <div className={styles.row} >
                <TextField
                    className={styles.text}
                    variant={"outlined"}
                    label={t('sideBar.Metadata.author')}
                    error={(metadata.author || "").length <= 0}
                    value={metadata.author || ""}
                    onChange={e => metadata.author = e.target.value}
                />
                <HtmlTooltip title={t('sideBar.Metadata.helpAuthor')}><span style={{marginLeft: "1rem"}} ><HelpOutline/></span></HtmlTooltip>
            </div>

            <div className={styles.row} >
                <TextField
                    className={styles.text}
                    variant={"outlined"}
                    label={t('sideBar.Metadata.subject')}
                    error={(metadata.subject || "").length <= 0}
                    value={metadata.subject || ""}
                    onChange={e => metadata.subject = e.target.value}
                />
                <HtmlTooltip title={t('sideBar.Metadata.helpSubject')}><span style={{marginLeft: "1rem"}} ><HelpOutline/></span></HtmlTooltip>
            </div>

            <InputLabel>{t('sideBar.Metadata.language')}</InputLabel>
            <FormControl style={{textAlign: 'right'}} variant="outlined">
                <Select
                native
                onChange={e => metadata.language = e.target.value}
                label="Language"
                inputProps={{
                    name: 'language',
                }}
                >
                    {LanguageCodes.map((item, index) =>
                    <option key={item.language} value={item.languageCode} defaultValue={index === initialLanguageIndex}>{item.language}</option>)}
                </Select>
            </FormControl>
            <div
                className={styles.row}
                style={{
                    width: "90%"
                }}
            >
                <HtmlTooltip title={t('sideBar.Metadata.helpTags')}><span><HelpOutline/></span></HtmlTooltip>
                <Typography>{t('sideBar.Metadata.tags')}</Typography>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: 0,
                        gap: "0.2rem",
                        flexWrap: "wrap",
                        width: "80%",
                        marginLeft: ".2rem"
                    }}
                >
                    {metadata.keywords.map((keyword: string) => (
                        <Chip
                            key={keyword}
                            label={keyword}
                            onDelete={() => metadata.removeKeyword(keyword)}
                        />
                    ))}
                    <AddKeywordChip onAddKeyword={metadata.addKeyword}/>
                </div>

            </div>
        </div>
    );
}
