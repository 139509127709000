// @flow

import { useCallback, useMemo } from "react";
import type { TutorialStep } from "./Tutorial";
import { Tutorial } from "./Tutorial";
import {
    TargetHotzone,
    TargetMetadataTray,
    TargetPdfArea,
    TargetProgressTray,
    TargetReadingOrderTray,
    TargetReadingOrderList,
    TargetSideBarExpand,
    TargetSideBarTabs,
    TargetTutorialsTray, TargetReadingOrderAnchor0, TargetReadingOrderAnchorAll
} from "./targets";
import {
    setContentBlockEditing,
    setReadingOrderEditorActive,
    setSideBarExpanded,
    setSideBarTab
} from "../../../redux/slice/EditorSlice";
import { Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "@material-ui/icons";
import styles from "./BasicEditorTutorial.module.css";
import { SideBarTabReadingOrderConfig } from "../sidebar/SideBarTabReadingOrder";
import { SideBarTabMetadataConfig } from "../sidebar/SideBarTabMetadata";
import { SideBarTabProgressConfig } from "../sidebar/SideBarTabDownload";
import { SideBarTabTutorialsConfig } from "../sidebar/SideBarTabTutorials";
import {Trans, useTranslation} from "react-i18next";

/**
 * The id used in stateManagement to refer to this tutorial
 * @type {string}
 */
export const StartupTutorialId = "startup-tutorial";

/**
 * A component that shows the startup tutorial when necessary
 * @constructor
 */
export function StartupTutorial() {
    const {t, i18n} = useTranslation();
    const steps = useMemo<TutorialStep[]>( () => [
        {   // welcome
            target: TargetPdfArea.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.welcome')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.welcomeBody" components={{1: <br/>}} />
                </Typography>
            ), disableBeacon: true,
            placement: "left",
        },
        {   // document view
            target: TargetPdfArea.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title1')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.step1" components={{ 1: <br /> }} />
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {   // document view
            target: TargetPdfArea.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title1-1')}</Typography>),
            content: (
                <div>
                    <Typography>
                        <Trans i18nKey="tutorials.startup.step1-1" components={{ 1: <li />, 3: <table />}} />
                    </Typography>
                    <ul>
                        {t('tutorials.startup.blockTypes', {returnObjects: true}).map((item, index) => {
                            return (<li key={index}>{item}</li>)
                        })}
                    </ul>
                </div>
            ),
            disableBeacon: true,
            placement: "left",
        },
        {   // reading order tray
            target: TargetReadingOrderTray.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title2')}</Typography>),
            content: (
                <Typography>
                    {t('tutorials.startup.step2')}
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarExpanded({expanded: false}));
                start();
            },
            onNext: (dispatch, start)  => {
                dispatch(setSideBarExpanded({expanded: true}));
                dispatch(setSideBarTab({ tab: SideBarTabReadingOrderConfig.id }));
                window.setTimeout(()=>start(), 500);
            },
        },
        {   // reading order list
            target: TargetReadingOrderList.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title3')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.step3" components={{ 1: <br /> }} />
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",

        },
        {   // reading order overlay
            target: TargetPdfArea.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title4')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.step4" components={{ 1: <br /> }} />
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",

        },
        {   // reading order anchors
            target: TargetReadingOrderAnchor0.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title5')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.step5" components={{ 1: <br /> }} />
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onNext: (dispatch, start) => {
                dispatch(setSideBarExpanded({ expanded: false }));
                window.setTimeout(()=>start(), 500);
            },

        },
        {   // tutorials
            target: TargetTutorialsTray.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title6')}</Typography>),
            content: (
                <Typography>
                    <Trans i18nKey="tutorials.startup.step6" components={{1: <br/>}}/>
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
            onBack: (dispatch, start) => {
                dispatch(setSideBarExpanded({expanded: true}));
                start();
            },
        },
        {   // start work
            target: TargetPdfArea.target(),
            title: (<Typography variant={"h6"}>{t('tutorials.startup.title7')}</Typography>),
            content: (
                <Typography display={"block"}>
                    <Trans i18nKey="tutorials.startup.step7" components={{ 1: <br /> }} />
                </Typography>
            ),
            disableBeacon: true,
            placement: "left",
        },
    ], []);

    const prepare = useCallback((dispatch, start) => {
        dispatch(setSideBarExpanded({ expanded: false }));
        dispatch(setContentBlockEditing( { contentblock: null }));
        start();
    }, []);

    const cleanup = useCallback(dispatch => {
        dispatch(setSideBarExpanded({ expanded: false }));
    }, []);

    return <Tutorial tutorial={StartupTutorialId} steps={steps} prepare={prepare} cleanup={cleanup}/>;
}
