// @flow

import type { AccDocsIssueData , AccDocsIssueIndex, AccDocsIssueSTOMPPayload} from "./AccDocsIssueTypes";
import type {PageSTOMPPayload} from "../page/PageTypes";


/**
 * Converts payload to usable data
 * @param payload
 * @returns issueNumber
 */
export function accDocsIssuePayloadToData(payload: AccDocsIssueSTOMPPayload): AccDocsIssueData {
    console.log("transforming accDocsIssue Payload to data: ", payload);
    return {
        id: payload.id,
        //issueNumber: payload.issueNumber,
        contentBlock: payload.contentBlock,
        document: payload.document,
        done: payload.done,
    };
}

/**
 * Converts data to stomp payload
 * @param data
 * @returns issueNumber
 */
export function accDocsIssueDataToPayload(data: AccDocsIssueData): PageSTOMPPayload {
    console.log("transforming accDocsIssue Data to Payload: ", data);
    return {
        id: data.id,
        //issueNumber: data.issueNumber,
        //document: data.document,
        done: data.done,
    }
}

export function accDocsIssueIndexMatch(a: AccDocsIssueIndex, b: AccDocsIssueIndex): boolean {
    //console.log("a: ", a, " b: ", b);
    if(a === undefined || b === undefined) return false;
    return a.accDocsIssue === b.accDocsIssue && a.document === b.document;
}
