// @flow

import type { ContentBlockIndex } from "../../../redux/data/contentblock/ContentBlockTypes";
import { useDispatch, useSelector } from "react-redux";
import { useContentBlock } from "../../../redux/hook/useContentBlock";
import { constants } from "./ContentBlockConstants";
import styles from "./ContentBlockCentralAnchor.module.css";
import {
    selectCurrentPageBounds,
    setReadingOrderEditorActive,
    setReadingOrderEditorOrder
} from "../../../redux/slice/EditorSlice";
import { useCallback, useMemo } from "react";
import {
    TargetReadingOrderAnchor0,
    TargetReadingOrderAnchor1,
    TargetReadingOrderAnchor2,
    TargetReadingOrderAnchorAll
} from "../../../components/blockeditor/tutorials/targets";

type ContentBlockCentralAnchorProps = {
    contentblock: ContentBlockIndex,
    renderIndex: number,
};

/**
 * Renders a circular anchor with the contentblock-types icon in the middle of the given contentblock
 * @constructor
 */
export function ContentBlockCentralAnchor(props: ContentBlockCentralAnchorProps) {
    const dispatch = useDispatch();

    const contentBlockData = useContentBlock(props.contentblock);
    const contentBlockConstants = constants(contentBlockData);

    const pageBounds = useSelector(selectCurrentPageBounds);
    const anchorStyle = useMemo(() => ({
        left: `${(contentBlockData.left + contentBlockData.width / 2) * pageBounds.width - 16}px`,
        top: `${(contentBlockData.top + contentBlockData.height / 2) * pageBounds.height - 16}px`,
        backgroundColor: contentBlockConstants.color,
    }), [ contentBlockData, pageBounds, contentBlockConstants ]);

    const handleClick = useCallback(() => {
        dispatch(setReadingOrderEditorActive({ active: true }));
        dispatch(setReadingOrderEditorOrder({ order: [ props.contentblock ] }));
    }, [ props.contentblock ]);

    //make identifiable for the tutorial
    const tutorialClassNames = useMemo(() =>
        `${TargetReadingOrderAnchorAll.className()} ${props.renderIndex < 3 ? [TargetReadingOrderAnchor0, TargetReadingOrderAnchor1, TargetReadingOrderAnchor2][props.renderIndex].className() : ""}`
        , [ props.renderIndex ]);

    return (
        <div
            onClick={handleClick}
            className={`${styles.anchor} ${tutorialClassNames}`}
            style={anchorStyle}
        >
            <contentBlockConstants.icon/>
        </div>
    );
}
