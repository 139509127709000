// @flow

import { Button, CircularProgress, Modal, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
    selectContentBlockEditing,
    selectEditMenuDeveloperModeActive,
    setContentBlockEditing
} from "../../redux/slice/EditorSlice";
import React, { useCallback, useEffect, useRef, useState} from "react";
import styles from "./NewContentBlockEditMenu.module.css";
import { BlockSvg } from "../../common/element/block/BlockSvg";
import type { PageData } from "../../redux/data/page/PageTypes";
import { selectPage } from "../../redux/slice/PageSlice";
import type { ContentBlockData } from "../../redux/data/contentblock/ContentBlockTypes";
import { BlockTypeDropDown } from "../../common/element/block/BlockTypeDropDown";
import {Close, Delete, Save} from "@material-ui/icons";
import { useContentBlock, useEditableContentBlock } from "../../redux/hook/useContentBlock";
import type { ContentBlockTypeConstants } from "../../common/element/block/ContentBlockConstants";
import { constants } from "../../common/element/block/ContentBlockConstants";
import ReactJson from "react-json-view";
import { HtmlTooltip } from "../../common/element/tooltips/HtmlTooltip";

import { EditorHelperSelect } from "./helpers/EditorHelperSelector";
import {useTranslation} from "react-i18next";

export function NewContentBlockEditMenu() {
    const dispatch = useDispatch();

    /*
        Editor settings
     */
    const devModeActive = useSelector(selectEditMenuDeveloperModeActive);

    /*
        Fetch data
     */
    const contentBlock = useSelector(selectContentBlockEditing);
    const contentBlockData = useEditableContentBlock(contentBlock);
    const contentBlockConstants: ContentBlockTypeConstants = constants(contentBlockData);
    const pageData: PageData = useSelector(state => !!contentBlock && selectPage(state, contentBlock));
    const showModal = !!contentBlock;

    const rawBlockDataRef = useRef<ContentBlockData>({});
    const rawBlockData = useContentBlock(contentBlock);
    if (!!rawBlockData) { //update ref if block exists
        rawBlockDataRef.current = rawBlockData;
    }

    /*
        State management and callbacks
     */
    const [ zoom, setZoom ] = useState<number>(2);
    const zoomRef = useRef<number>(zoom);
    zoomRef.current = zoom;

    const [ left, setLeft ] = useState<number>(0);
    const leftRef = useRef<number>(left);
    leftRef.current = left;

    const [ top, setTop ] = useState<number>(0);
    const topRef = useRef<number>(top);
    topRef.current = top;

    const dragAnchorRef = useRef<{left: number, top: number}>({left: 0, top: 0});

    // hooks
    const {t} = useTranslation();

    useEffect(() => {
        if (!!contentBlockData && !!pageData) {
            setZoom(2);
            setLeft(128 - contentBlockData.width * pageData.width); // * 2 / 2
            setTop(128 - contentBlockData.height * pageData.height); // * 2 / 2
        }
    }, [ contentBlockData, pageData ]);

    const setPosition = useCallback((left: number, top: number) => {
        if (!!contentBlockData && !!pageData) { //clamp position and set it in states and refs
            const upperThreshold = 16 * 15; // (16 - 1)rem
            const leftLowerThreshold = 16 - (contentBlockData.width * pageData.width * zoomRef.current);
            const topLowerThreshold = 16 - (contentBlockData.height * pageData.height * zoomRef.current);
            const rLeft = left < leftLowerThreshold ? leftLowerThreshold : (left > upperThreshold ? upperThreshold : left);
            const rTop = top < topLowerThreshold ? topLowerThreshold : (top > upperThreshold ? upperThreshold : top);

            setLeft(rLeft);
            setTop(rTop);
            leftRef.current = rLeft;
            topRef.current = rTop;
        }
    }, [ contentBlockData, pageData ]);

    //handle zooming (more complex when trying to center the zoom around the mouse position
    const handleMouseScroll = useCallback((e: HTMLElementEventMap["wheel"]) => {
        const newZoom = zoom + (e.deltaY < 0 ? .1 : -.1);

        //calculate how much the position of the mouse is going to be moved over the contentblock and adjust accordingly
        if (!!contentBlockData && !!pageData) {
            const targetBounds = (e.target: Element).getBoundingClientRect();
            const mouseRelLeft = (e.clientX  - targetBounds.left) / targetBounds.width;
            const mouseRelTop = (e.clientY - targetBounds.top) / targetBounds.height;

            //calculate difference in height and width
            const widthDiff = targetBounds.width * (1 - newZoom / zoom);
            const heightDiff = targetBounds.height * (1 - newZoom / zoom);

            setPosition(leftRef.current + widthDiff * mouseRelLeft, topRef.current + heightDiff * mouseRelTop);
        }

        setZoom(newZoom);
    }, [ contentBlockData, pageData, zoom ]);

    //handle dragging stuff
    const handleDragStart = useCallback((e: HTMLElementEventMap["dragstart"]) => {
        dragAnchorRef.current = {
            left: e.clientX,
            top: e.clientY,
        };
    }, []);

    const handleDragStop = useCallback((e: HTMLElementEventMap["dragend"]) => {
        setPosition(leftRef.current + e.clientX - dragAnchorRef.current.left, topRef.current + e.clientY - dragAnchorRef.current.top);
    }, [ setPosition ]);

    const closeModal = useCallback(() => {
        dispatch(setContentBlockEditing({ contentblock: null }));
    }, [ dispatch ]);

    /*
        Save editor data when exiting or manually saving when button is pressed
     */
    const editorRef = useRef();
    const saveEditorData = useCallback(() => !!editorRef.current?.save && editorRef.current.save(), []);
    const lastShowModalRef = useRef<boolean>(showModal);
    useEffect(() => {
        if (lastShowModalRef.current && !showModal) { //only save if showModal truely changed
            saveEditorData();
        }
        lastShowModalRef.current = showModal;
    }, [ saveEditorData, showModal ]); //save editor data on unmount

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    }
    const handleTooltipClose = () => {
        setTooltipOpen(false);
    }

    const toggleTooltipOpen = () => {
        setTooltipOpen(!tooltipOpen);
    }

    const currentContentBlockType = contentBlockData?.details.type;
    const segmentationConfidence = rawBlockData?.details.segmentationConfidence;

    return (
        <Modal
            open={showModal}
            onClose={closeModal}
        >
            <div className={styles.itemsContainer}>
                <div className={styles.blockContainer}>
                    <span
                        className={styles.blockWrapper}
                        draggable={true}
                        style={{
                            left: `${left}px`,
                            top: `${top}px`,
                        }}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragStop}
                        onWheel={handleMouseScroll}
                    >
                        <BlockSvg index={contentBlock} zoom={zoom}/>
                    </span>
                </div>
                <HtmlTooltip
                    title={
                        <EditorHelperSelect type={currentContentBlockType}/>
                    }
                    placement={"left-end"}
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                <div className={styles.editDialogContainer}>
                    <div className={styles.editDialogToolBar}>
                        <BlockTypeDropDown
                            selectedType={contentBlockConstants}
                            onChangeType={(type: ContentBlockTypeConstants) => contentBlockData.details.type = type.type}
                        />
                        <div className={styles.editDialogToolBarSpacer}>

                                <Button variant={"outlined"} onClick={toggleTooltipOpen}>{t('editors.helpButton')}</Button>

                            <span style={{width:".5rem"}}></span>
                            <Typography>
                                { contentBlockData?.runningAutofillServices > 0 && <>{t("editors.aiLoading")} <CircularProgress style={{width: "1rem", height: "1rem"}}/> </> }
                                { (contentBlockData?.runningAutofillServices <= 0) && (segmentationConfidence >= 0) && <>
                                    {t("editors.aiConfidence")} {Math.round(segmentationConfidence * 100)}%
                                </>}
                            </Typography>
                        </div>
                        <div
                            className={styles.actionButton}
                            onClick={saveEditorData}
                        >
                            <Save/>
                        </div>
                        <div
                            className={styles.actionButton}
                            onClick={() => {
                                dispatch(setContentBlockEditing({ contentBlock: null }));
                                contentBlockData.delete();
                            }}
                        >
                            <Delete/>
                        </div>
                        <div
                            className={`${styles.actionButton} ${styles.closeButton}`}
                            onClick={() => dispatch(setContentBlockEditing({ contentBlock: null }))}
                        >
                            <Close/>
                        </div>
                    </div>
                    {!devModeActive && contentBlockConstants.editor(r => !!r && (editorRef.current = r))}
                    {devModeActive && <ReactJson src={rawBlockDataRef.current} style={{ overflowY: "scroll", height: "inherit" }} />}
                </div>
                </HtmlTooltip>
            </div>
        </Modal>
    )
}



