// @flow

import type { ElementContainer } from "./SliceTypes";
import type { PageData, PageIndex } from "../data/page/PageTypes";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addTruthLayer, removeTruthLayer, updateGroundTruth } from "./SliceUtil";
import { pageIndexMatch } from "../data/page/PageUtil";

export type PageLoadingPayload = {
    index: PageIndex,
}

export type PageLoadedPayload = {
    index: PageIndex,
    version: number,
    data: PageData,
}

export type PageUpdateUnconfirmedPayload = {
    origin: string,
    index: PageIndex,
    data: PageData,
}

export type PageUpdateRejectedPayload = {
    origin: string,
    index: PageIndex,
}

export type PageUpdateConfirmedPayload = {
    origin: string,
    index: PageIndex,
    version: number,
    data: PageData,
}

export type PageUnloadedPayload = {
    index: PageIndex,
}

const pagesInitialState: { pages: ElementContainer<PageIndex, PageData>[] } = { pages: [] }; //initial state is an empty array, as no pages are loaded yet

const PageSlice = createSlice({
    name: 'pages',
    initialState: pagesInitialState,
    reducers: {
        loading: (state, action: PayloadAction<PageLoadingPayload>) => {
            //insert new container for loading page
            state.pages = [ ...state.pages, {
                index: action.payload.index,
                groundTruth: null, //ground truth is null while loading
                resolvedTruth: null,
                groundTruthVersion: 0,
                truthLayers: [], //truth layers should be an empty array while
            }];
        },
        loaded: (state, action: PayloadAction<PageLoadedPayload>) => {
            //find page with given index
            const existingIndex = state.pages.findIndex(page => pageIndexMatch(page.index, action.payload.index));
            if (existingIndex >= 0) {
                state.pages[existingIndex].groundTruth = action.payload.data;
                state.pages[existingIndex].resolvedTruth = action.payload.data;
                state.pages[existingIndex].groundTruthVersion = action.payload.version;
            }
        },
        unloaded: (state, action: PayloadAction<PageUnloadedPayload>) => {
            //remove page with given index
            state.pages = state.pages.filter(page => page.index.document !== action.payload.index.document || page.index.page !== action.payload.index.page);
        },
        updateUnconfirmed: (state, action: PayloadAction<PageUpdateUnconfirmedPayload>) => {
            //find index of given page
            const existingIndex = state.pages.findIndex(page => page.index.document === action.payload.index.document && page.index.page === action.payload.index.page);
            if (existingIndex >= 0) {
                addTruthLayer(state.pages[existingIndex], action.payload.origin, action.payload.data);
            }
        },
        updateRejected: (state, action: PayloadAction<PageUpdateRejectedPayload>) => {
            //find page and remove rejected update
            const existingIndex = state.pages.findIndex(page => pageIndexMatch(page.index, action.payload.index));
            if (existingIndex >= 0) {
                removeTruthLayer(state.pages[existingIndex], action.payload.origin);
            }
        },
        updateConfirmed: (state, action: PayloadAction<PageUpdateConfirmedPayload>) => {
            //find page, remove old truth layer (optional) and add the new truth layer and collapse layers
            const existingIndex = state.pages.findIndex(page => page.index.document === action.payload.index.document && page.index.page === action.payload.index.page);
            if (existingIndex >= 0) {
                removeTruthLayer(state.pages[existingIndex], action.payload.origin);
                updateGroundTruth(state.pages[existingIndex], action.payload.data, action.payload.version);
            }
        },
    }
});

export const { loading,
                loaded,
                unloaded,
                updateUnconfirmed,
                updateRejected,
                updateConfirmed } = PageSlice.actions;
export default PageSlice.reducer;

//basic selectors
function selectAllPages(state): ElementContainer<PageIndex, PageData>[] {
    return state.pages.pages;
}

//selectors
/**
 * Selects the indices of all pages
 */
export const selectPageIndices = createSelector([
        selectAllPages
    ],
    (pages: ElementContainer<PageIndex, PageData>[]) => pages.map(page => page.index));

/**
 * Selects the indices of all pages, ordered by the pagenumbers
 */
export const selectPageIndicesOrdered = createSelector([
        selectAllPages
    ],
    (pages: ElementContainer<PageIndex, PageData>[]) => [ ...pages ].sort(
        (a, b) => !!a.resolvedTruth ? !!b.resolvedTruth ? a.resolvedTruth.pageNumber - b.resolvedTruth.pageNumber : -1 : 1)
        .map(page => page.index));

/**
 *  Selects the data of all existing pages
 */
export const selectPages = createSelector([
        selectAllPages
    ],
    (pages: ElementContainer<PageIndex, PageData>[]) => pages.map(page => page.resolvedTruth));

/**
 * Selects the data of all existing pages ordered by the pagenumbers
 */
export const selectPagesOrdered = createSelector([
        selectPages,
    ],
    (pages: PageData[]) => pages.sort((a, b) => !!a && !!b ? a.pageNumber - b.pageNumber : !!a ? -1 : 1)
);
/**
 * Selects the truth of a single page
 */
export const selectPage = createSelector([
        selectAllPages,
        (state, page: PageIndex) => page,
    ],
    (pages: ElementContainer<PageIndex, PageData>[], page: PageIndex) => {
        const pageIndex = pages.findIndex(p => pageIndexMatch(p.index, page));
        if (pageIndex >= 0) {
            return pages[pageIndex].resolvedTruth;
        } else {
            return null;
        }
    });
