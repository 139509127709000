// @flow

import type { SideBarTabConfig} from "./SideBarDrawer";
import { useState } from "react";
import styles from "./SideBarTabProgess.module.css";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {Typography} from "@material-ui/core";
import {TargetProgressTray} from "../tutorials/targets";
import {DoneAll, HelpOutline, Speed} from "@material-ui/icons";
import {
    selectAccDocsIssuesIndicesOrdered,
    selectAccDocsIssuesOrdered,
} from "../../../redux/slice/AccDocsIssueSlice";
import {updateAccDocsIssue} from "../../../redux/action";
import {SideBarTabProgressIssueItem} from "./SidebarTabProgressIssueItem";
import type {AccDocsIssueIndex} from "../../../redux/data/accdocsissues/AccDocsIssueTypes";
import {selectAllContentBlocks} from "../../../redux/slice/ContentBlockSlice";
import {HtmlTooltip} from "../../../common/element/tooltips/HtmlTooltip";

/**
 * Renders a list of open issues for the currently opened document, filters for said list, and a download option
 * @returns {JSX.Element}
 * @constructor
 */
export function SideBarTabProgress() {

    const {t} = useTranslation();
    /*
    const [imageProgress, setImageProgress] = useState(0);
    const [nImages, setNImages] = useState(0);
    const [textProgress, setTextProgress] = useState(0);
    const [nTextBlocks, setNTextBlocks] = useState(0);
    const [tableProgress, setTableProgress] = useState(0);
    const [nTables, setNTables] = useState(0);
    const [headlineProgress, setHeadlineProgress] = useState(0);
    const [nHeadlines, setNHeadlines] = useState(0);
    const [mathProgress, setMathProgress] = useState(0);
    const [nMath, setNMath] = useState(0);
    const [chemicalProgress, setChemicalProgress] = useState(0);
    const [nChemFormula, setNChemForm] = useState(0);
     */
    let imageProgress = 0;
    let nImages = 0;
    let textProgress = 0;
    let nTextBlocks = 0;
    let tableProgress = 0;
    let nTables = 0;
    let headlineProgress = 0;
    let nHeadlines = 0;
    let mathProgress = 0;
    let nMaths = 0;
    let chemicalProgress = 0;
    let nChems = 0;

    const allContentBlocks = useSelector(selectAllContentBlocks);
    console.log("allContentBlocks: ", allContentBlocks);

    const dispatch = useDispatch();
    const accDocsIssuesIndices : AccDocsIssueIndex[] = useSelector(selectAccDocsIssuesIndicesOrdered);
    //console.log("accdocsIssuesIndices: ", accDocsIssuesIndices);
    const accDocsIssues = useSelector(selectAccDocsIssuesOrdered);
    console.log("accDocsIssues: ", accDocsIssues);
    //const accDocsIssuesIndicesFinal = accDocsIssuesIndices.filter(issue => issue !== null);
    //const accDocsIssuesFinal = accDocsIssues.filter(issue => issue !== null);
    //console.log("accDocsIssues: ", accDocsIssues);
    //console.log("accDocsIssuesFinal: ", accDocsIssuesFinal);
    //console.log("accDocsIssuesIndices: ", accDocsIssuesIndices);
    //console.log("accDocsIssuesIndicesFinal: ", accDocsIssuesIndicesFinal);
    const calculateNewAverage = (oldAvg, newVal, numVals) => {
        return numVals > 0 ? oldAvg + ((newVal - oldAvg) / numVals) : oldAvg;
    }

    allContentBlocks.filter(cb => cb !== null).forEach(cb => {

        const segConf = cb.details.segmentationConfidence;
        switch(cb.details.type) {
            case "image_with_text":
                nImages += 1;
                imageProgress = calculateNewAverage(imageProgress, segConf, nImages);
                break;
            case "mathexpression":
                nMaths += 1;
                mathProgress = calculateNewAverage(mathProgress, segConf, nMaths);
                break;
            case "heading":
                nHeadlines += 1;
                headlineProgress = calculateNewAverage(headlineProgress, segConf, nHeadlines);
                break;
            case "text":
                nTextBlocks += 1;
                textProgress = calculateNewAverage(textProgress, segConf, nTextBlocks);
                break;
            case "table":
                nTables += 1;
                tableProgress = calculateNewAverage(tableProgress, segConf, nTables);
                break;
            case "chemicalformula":
                nChems += 1;
                chemicalProgress = calculateNewAverage(chemicalProgress, segConf, nChems);
                break;
            default:
                console.log("warning, this content block has no type");
        }
    });

    const progressColorIndication = (percentage: number) => {
        if (percentage < 0.3) return "#ff0000"; // red
        if (percentage >= 0.3 && percentage < 0.8) return "#FFA500"; // orange
        if (percentage >= 0.8) return "#008000"; // green
        return "#000000"; // black
    };

    const handleDoneClick = (adi) => {
        console.log("clicked done button", adi);

        //const index = useSelector(selectAccDocsIssueIndexById(adi.id));
        //const issueIdx = accDocsIssues.findIndex(issue => issue.id === adi.id);
        const indicesIdx = accDocsIssuesIndices.findIndex(issueIndex => issueIndex.accDocsIssue === adi.id);
        //console.log("tmp is: ", issueIdx);
        //console.log("tmp2 is: ", indexIdx);
        //const idx = accDocsIssuesFinal[issueIdx];
        //console.log("idx: ", idx);
        const accDocsIssueIdx = accDocsIssuesIndices[indicesIdx];
        //console.log("foo: ", accDocsIssueIdx);
        dispatch(updateAccDocsIssue(accDocsIssueIdx, accDocsIssueIdx.accDocsIssue, true));
        //dispatch(updateAccDocsIssue(accDocsIssueIdx, adi.issueNumber, true));
    }


    return(
        <div className={styles.container}>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", alignContent:"center", justifyContent:"center", verticalAlign:"center"}}>
                <Typography variant={"h6"}>
                    {t('sideBar.Progress.progress')}
                </Typography>
                <HtmlTooltip title={t('sideBar.Progress.helpProgress')}><span style={{marginLeft: "1rem"}} ><HelpOutline/></span></HtmlTooltip>
            </div>
            {/* only display progress meters for types of content blocks that actually occur in the document*/}
            {(nHeadlines > 0) && <div className={styles.row}>
                <Typography>{t('sideBar.Progress.headlines')}</Typography>
                <Speed htmlColor={progressColorIndication(headlineProgress)}/>
            </div>}
            {(nTextBlocks > 0) && <div className={styles.row}>
                <Typography>{t('sideBar.Progress.textBlocks')}</Typography>
                <Speed htmlColor={progressColorIndication(textProgress)}/>
            </div>}
            {(nImages > 0) && <div className={styles.row}>
                <Typography>{t('sideBar.Progress.images')}</Typography>
                <Speed htmlColor={progressColorIndication(imageProgress)}/>
            </div>}
            {(nTables > 0) && <div className={styles.row}>
                <Typography>{t('sideBar.Progress.tables')}</Typography>
                <Speed htmlColor={progressColorIndication(tableProgress)}/>
            </div>}
            {(nMaths > 0) && <div className={styles.row}>
                <Typography>{t('sideBar.Progress.maths')}</Typography>
                <Speed htmlColor={progressColorIndication(mathProgress)}/>
            </div>}
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", alignContent:"center", justifyContent:"center", verticalAlign:"center"}}>
                <Typography variant={"h6"}>
                    {t('sideBar.Progress.issues')}
                </Typography>
                <HtmlTooltip title={t('sideBar.Progress.helpIssues')}><span style={{marginLeft: "1rem"}} ><HelpOutline/></span></HtmlTooltip>
            </div>
            <div style={{flexDirection: "column", width: "100%", alignItems: "flex-start"}}>
                {/*
                {accDocsIssues && accDocsIssues.map((adi) => {
                    console.log(adi.contentBlock);
                    //return <SideBarTabProgressIssueItem issueNumber={adi.issueNumber} contentBlockIndex={null} accDocsIssueIndex={adi}></SideBarTabProgressIssueItem>
                    return <SideBarTabProgressIssueItem accDocsIssue={adi}/>
                })}
                */}
                {accDocsIssuesIndices && accDocsIssuesIndices.map((adii) => {
                    return <SideBarTabProgressIssueItem accDocsIssueIndex={adii} key={JSON.stringify(adii)}/>
                })}


            </div>
        </div>
    );
}

/**
 * Configuration for the Sidebar tab showing the progress the user is making
 * @type {{name: string, icon: (function(*): *), id: string, render: (function(): *)}}
 */
export const SideBarTabProgressConfig: SideBarTabConfig = {
    id: "progress",
    name: "Progress",
    trayTarget: TargetProgressTray.className(),
    icon: props => <DoneAll {...props}/>,
    render: SideBarTabProgress,
};
// Todo: options for icon are: <Score/> <Timeline/> <Speed/> <DoneAll/>
