// @flow

import {
    useContentBlock,
    useContentBlockIndicesOrdered,
    useContentBlocksOrdered
} from "../../redux/hook/useContentBlock";
import { useDispatch, useSelector } from "react-redux";
import {
    selectCurrentPage,
    selectCurrentPageBounds,
    selectMousePosition,
    selectReadingOrderEditorActive,
    selectReadingOrderEditorOrder,
    selectSideBarExpanded,
    selectSideBarTab,
    setCurrentMousePosition,
    setReadingOrderEditorActive,
    setReadingOrderEditorOrder
} from "../../redux/slice/EditorSlice";
import { SideBarTabReadingOrderConfig } from "./sidebar/SideBarTabReadingOrder";
import styles from "./ReadingOrderOverlay.module.css";
import type { ContentBlockData, ContentBlockIndex } from "../../redux/data/contentblock/ContentBlockTypes";
import { ContentBlockCentralAnchor } from "../../common/element/block/ContentBlockCentralAnchor";
import { Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import { constants } from "../../common/element/block/ContentBlockConstants";
import { contentBlockIndexMatch } from "../../redux/data/contentblock/ContentBlockUtil";
import { updateBlockOrdinal } from "../../redux/action";
import { TargetReadingOrderOverlay } from "./tutorials/targets";
import { useHotkey } from "../../hotkeys/HotkeyHooks";

type ContentBlockArrowProps = {
    start: ContentBlockIndex,
    end: ContentBlockIndex,
    aspect: number,
};

/**
 * Creates an svg element which connects the 2 given contentblocks
 * @param props
 * @constructor
 */
function ContentBlockArrow(props: ContentBlockArrowProps) {
    const startBlockData = useContentBlock(props.start);
    const startConstants = constants(startBlockData);

    const endBlockData = useContentBlock(props.end);
    const endConstants = constants(endBlockData);

    const startX = startBlockData.left + startBlockData.width / 2;
    const endX = endBlockData.left + endBlockData.width / 2;
    const startY = startBlockData.top + startBlockData.height / 2;
    const endY = endBlockData.top + endBlockData.height / 2;

    return (
        <Fragment>
            <defs>
                <marker id={`as${props.start.contentblock}e${props.end.contentblock}`}
                        markerWidth={4} markerHeight={4} markerUnits={"strokeWidth"}
                        viewBox={"0 0 4 4 "}
                        refX={6.5} refY={2} orient={"auto"}>
                    <polygon points="0 0, 4 2, 0 4" stroke={startConstants.color} fill={"black"}/>
                </marker>
            </defs>
            <line
                key={`s${props.start.contentblock}e${props.end.contentblock}`}
                x1={startX}
                y1={startY * props.aspect}
                x2={endX}
                y2={endY * props.aspect}
                strokeWidth={".006px"}
                stroke={startConstants.color}
                markerEnd={`url(#as${props.start.contentblock}e${props.end.contentblock})`}
            />
        </Fragment>
    );
}

type OrderedContentBlockArrowsProps = {
    contentblocks: ContentBlockIndex[],
};
function OrderedContentBlockArrows(props: OrderedContentBlockArrowsProps) {

    const pageBounds = useSelector(selectCurrentPageBounds);
    const aspect = pageBounds.height / pageBounds.width;

    //pairs up blocks so that succeeding indices are pairs (01, 12, 23 etc)
    const arrows = useMemo(() => {
        const pairs = props.contentblocks
            .filter((index, i, array) => i < array.length - 1)
            .map((index, i) => [ index, props.contentblocks[i + 1]]);

        return pairs.map(blocks => <ContentBlockArrow key={blocks[0].contentblock} start={blocks[0]} end={blocks[1]} aspect={aspect}/>)
    }, [ props.contentblocks ]);

    return (
        <svg
            viewBox={`0 0 1 ${aspect}`}
            xmlns={"http://www.w3.org/2000/svg"}
            className={styles.svgContainer}
            style={{
                width: `${pageBounds.width}px`,
                height: `${pageBounds.height}px`,
            }}
        >
            {arrows}
        </svg>
    );
}

type ContentBlockToMouseArrowProps = {
    contentBlock: ContentBlockIndex,
    targetPosition: {left: number, top: number},
};

/**
 * Renders an arrow from the given contentblocks center to the given position
 * @param props
 * @constructor
 */
function ContentBlockToMouseArrow(props: ContentBlockToMouseArrowProps) {
    const pageBounds = useSelector(selectCurrentPageBounds);
    const aspect = pageBounds.height / pageBounds.width;

    const contentBlockData = useContentBlock(props.contentBlock);
    const contentBlockConstants = constants(contentBlockData);
    const startX = contentBlockData.left + contentBlockData.width / 2;
    const startY = contentBlockData.top + contentBlockData.height / 2;

    return (
        <svg
            viewBox={`0 0 1 ${aspect}`}
            xmlns={"http://www.w3.org/2000/svg"}
            className={styles.svgContainer}
            style={{
                width: `${pageBounds.width}px`,
                height: `${pageBounds.height}px`,
            }}
        >
            <defs>
                <marker id={`as${props.contentBlock.contentblock}m`}
                        markerWidth={4} markerHeight={4} markerUnits={"strokeWidth"}
                        viewBox={"0 0 4 4 "}
                        refX={6.5} refY={2} orient={"auto"}>
                    <polygon points="0 0, 4 2, 0 4" stroke={contentBlockConstants.color} fill={"black"}/>
                </marker>
            </defs>
            <line
                key={`s${props.contentBlock.contentblock}m`}
                x1={startX}
                y1={startY * aspect}
                x2={props.targetPosition.left}
                y2={props.targetPosition.top * aspect}
                strokeWidth={".006px"}
                stroke={contentBlockConstants.color}
                markerEnd={`url(#as${props.contentBlock.contentblock}m)`}
            />
        </svg>
    )
}

function selectHoveredBlock(normalizedMousePosition: {left: number, top: number}, indices: ContentBlockIndex[], data: ContentBlockData[], exclude: ContentBlockIndex[]=[]): [ContentBlockIndex, ContentBlockData] | null {
    const zippedBlocks: [ContentBlockIndex, ContentBlockData][] = indices.map((_, i) => [indices[i], data[i]]);
    const hoveredBlocks: [ContentBlockIndex, ContentBlockData][] = zippedBlocks.filter((block) => (
        block[1].left < normalizedMousePosition.left
        && block[1].left + block[1].width > normalizedMousePosition.left
        && block[1].top < normalizedMousePosition.top
        && block[1].top + block[1].height > normalizedMousePosition.top
        && !(exclude.filter(index => contentBlockIndexMatch(index, block[0])).length > 0)  //if block is already in the reading order, it is irrelevant
    )).sort((a, b) => a[1].width * a[1].height < b[1].width * b[1].height ? -1 : 1);

    return hoveredBlocks.length > 0 ? hoveredBlocks[0] : null;
}

type ReadingOrderOverlayProps = {
    isPageMove: boolean,
};

/**
 * Renders an overlay displaying the current reading order and has an edit mode which allows to click on contentblocks in the desired order
 * @constructor
 */
export function ReadingOrderOverlay(props: ReadingOrderOverlayProps) {
    const dispatch = useDispatch();

    //check whether reading order tab is open
    const readingOrderShown = useSelector(state => selectSideBarExpanded(state) && selectSideBarTab(state) === SideBarTabReadingOrderConfig.id);

    //page data
    const pageNumber = useSelector(selectCurrentPage);
    const pageBounds = useSelector(selectCurrentPageBounds);

    //contentblock data
    const contentBlockIndices: ContentBlockIndex[] = useContentBlockIndicesOrdered();
    const contentBlockDatas: ContentBlockData[] = useContentBlocksOrdered();

    //readingorder editor
    const editorActive: boolean = useSelector(selectReadingOrderEditorActive);
    const editorOrder: ContentBlockIndex[] = useSelector(selectReadingOrderEditorOrder);
    const mousePosition: {left: number, top: number} = useSelector(selectMousePosition);

    const mousePositionNormalized = !!mousePosition && {
        left: mousePosition.left / pageBounds.width,
        top: mousePosition.top / pageBounds.height,
    };
    let mouseArrowTarget: {left: number, top: number} = mousePositionNormalized;
    if (editorActive && editorOrder.length > 0 && !!mousePositionNormalized) {
        const hoveredBlock = selectHoveredBlock(mousePositionNormalized, contentBlockIndices, contentBlockDatas, editorOrder);

        if (!!hoveredBlock) {
            mouseArrowTarget = {
                left: hoveredBlock[1].left + hoveredBlock[1].width / 2,
                top: hoveredBlock[1].top + hoveredBlock[1].height / 2,
            };
        }
    }

    //tracking mouseposition
    const renderRef = useRef<HTMLDivElement | null>(null);
    const handleMouseMove = useCallback((event: HTMLElementEventMap["mousemove"]) => {
        if (props.isPageMove) { //if page is being moved, do not update mouseposition
            return;
        }

        const bounds = renderRef.current?.getBoundingClientRect();
        if (bounds) {
            dispatch(setCurrentMousePosition({
                left: event.clientX - bounds.left,
                top: event.clientY - bounds.top,
            }));
        }
    }, [ props.isPageMove ]);
    const handleMouseLeave = useCallback(e => dispatch(setCurrentMousePosition(null)), []);
    const handleMouseClick = useCallback( (event: HTMLElementEventMap["click"]) => {
        if (editorActive) { //if the area is clicked and the editor is active, select the contentblokc that is clicked and add or remove it from the reading order
            const bounds = renderRef.current?.getBoundingClientRect();
            if (bounds) {
                const normalizedMousePosition = {
                    left: (event.clientX - bounds.left) / pageBounds.width,
                    top: (event.clientY - bounds.top) / pageBounds.height,
                };

                const clickedBlock = selectHoveredBlock(normalizedMousePosition, contentBlockIndices, contentBlockDatas);
                //if there is no block selected, abort the editor without changing order
                if (!clickedBlock) {
                    dispatch(setReadingOrderEditorActive({ active: false }));
                    dispatch(setReadingOrderEditorOrder({ order: [] }));
                } else if (editorOrder.filter(index => contentBlockIndexMatch(index, clickedBlock[0])).length > 0) {
                    //if block is in reading order, remove it
                    const index = editorOrder.findIndex(index => contentBlockIndexMatch(index, clickedBlock[0]));
                    const newOrder = [...editorOrder.slice(0, index), ...editorOrder.slice(index + 1)]
                    dispatch(setReadingOrderEditorOrder({ order:  newOrder}));
                    if (newOrder.length <= 0) { //if last contentblock has been removed, deactivate editor
                        dispatch(setReadingOrderEditorActive({ active: false }));
                    }
                } else {
                    //block should be added to reading order
                    const newOrder = [...editorOrder, clickedBlock[0]]
                    dispatch(setReadingOrderEditorOrder({ order: newOrder}));

                    //if there is no more contentblock to add to the reading order, submit new ordinals and close editor
                    if (newOrder.length === contentBlockIndices.length) { //each contentblock should be in the reading order at most once
                        newOrder.forEach((index, ordinal) => dispatch(updateBlockOrdinal(pageNumber, index.contentblock, ordinal)));
                        dispatch(setReadingOrderEditorActive({ active: false }));
                        dispatch(setReadingOrderEditorOrder({ order: [] })); //reset order
                    }
                }
            }
        }
    }, [ editorActive, editorOrder, contentBlockIndices, contentBlockDatas, pageBounds, pageNumber ]);

    useEffect(() => { //discard editor changes when the reading order is hidden
        if (!readingOrderShown) {
            dispatch(setReadingOrderEditorActive({ active: false }));
            dispatch(setReadingOrderEditorOrder({ order: [] }));
        }
    }, [ readingOrderShown ]);

    //allow users to close editor with escape
    useHotkey(editorActive ? "close_reading_order_editor" : null, "escape", () => {
        dispatch(setReadingOrderEditorActive({ active: false }));
        dispatch(setReadingOrderEditorOrder({ order: [] }));
    }, [ dispatch ]);

    if (!readingOrderShown) { //if readingorder is not shown, do not show overlay
        return null;
    }

    return (
        <div
            ref={renderRef}
            onClick={handleMouseClick}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}

            className={`${styles.container} ${TargetReadingOrderOverlay.className()}`}
            style={{
                width: `${pageBounds.width}px`,
                height: `${pageBounds.height}px`,
            }}
        >
            <OrderedContentBlockArrows contentblocks={editorActive ? editorOrder : contentBlockIndices}/>
            {editorActive && editorOrder.length > 0 && !!mouseArrowTarget && <ContentBlockToMouseArrow contentBlock={editorOrder[editorOrder.length - 1]} targetPosition={mouseArrowTarget}/>}
            {contentBlockIndices.map((index, i) => <ContentBlockCentralAnchor key={index.contentblock} contentblock={index} renderIndex={i}/>)}
        </div>
    );
}
