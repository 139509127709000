// @flow

import { createSelector, createSlice, PayloadAction, TaskRejected } from "@reduxjs/toolkit";
import type { TaskData, TaskIndex, TaskType } from "../data/task/TaskTypes";
import { taskIndexMatch } from "../data/task/TaskUtil";
import type { ElementContainer } from "./SliceTypes";
import { addTruthLayer, removeTruthLayer, updateGroundTruth } from "./SliceUtil";


export type TaskLoadingPayload = {
    index: TaskIndex
};

export type TaskLoadedPayload = {
    index: TaskIndex,
    version: number,
    data: TaskData
};

export type TaskUpdateUnconfirmedPayload = {
    origin: string,
    index: TaskIndex,
    data: TaskData
};

export type TaskUpdateRejectedPayload = {
    origin: string,
    index: TaskIndex
}

export type TaskUpdateConfirmedPayload = {
    origin: string,
    index: TaskIndex,
    version: number,
    data: TaskData
};

export type TaskUnloadedPayload = {
    index: TaskIndex
};

const taskInitialState = { tasks: [] };

const TaskSlice = createSlice({
    name: "tasks",
    initialState: taskInitialState,
    reducers: {
        loading: (state, action: PayloadAction<TaskLoadingPayload>) => {
            state.tasks.push({
                index: action.payload.index,
                groundTruth: null,
                resolvedTruth: null,
                groundTruthVersion: 0,
                truthLayers: []
            });
        },
        loaded: (state, action: PayloadAction<TaskLoadedPayload>) => {
            const existingIndex = state.tasks.findIndex(task => taskIndexMatch(task.index, action.payload.index));

            if (existingIndex >= 0) {
                state.tasks[existingIndex].groundTruth = action.payload.data;
                state.tasks[existingIndex].resolvedTruth = action.payload.data;
                state.tasks[existingIndex].groundTruthVersion = action.payload.version;
            }
        },
        unloaded: (state, action: PayloadAction<TaskUnloadedPayload>) => {
            state.tasks = state.tasks.filter(task => !taskIndexMatch(task.index, action.payload.index));
        },
        updateUnconfirmed: (state, action: PayloadAction<TaskUpdateUnconfirmedPayload>) => {
            const existingIndex = state.tasks.findIndex(task => taskIndexMatch(task.index, action.payload.index));
            if (existingIndex >= 0) {
                addTruthLayer(state.tasks[existingIndex], action.payload.origin, action.payload.data);
            }
        },
        updateConfirmed: (state, action: PayloadAction<TaskUpdateConfirmedPayload>) => {
            const existingIndex = state.tasks.findIndex(task => taskIndexMatch(task.index, action.payload.index));
            if (existingIndex >= 0) {
                removeTruthLayer(state.tasks[existingIndex], action.payload.origin);
                updateGroundTruth(state.tasks[existingIndex], action.payload.data, action.payload.version);
            }

        },
        updateRejected: (state, action: PayloadAction<TaskRejected>) => {
            const existingIndex = state.tasks.findIndex(task => taskIndexMatch(task.index, action.payload.index));
            if (existingIndex >= 0) {
                removeTruthLayer(state.tasks[existingIndex], action.payload.origin);
            }
        }
    }
});

export const { loading, loaded, unloaded, updateUnconfirmed, updateRejected, updateConfirmed } = TaskSlice.actions;
export default TaskSlice.reducer;

function selectAllTasks(state): ElementContainer<TaskIndex, TaskData>[] {
    return state.tasks.tasks;
}

export const selectTaskIndices = createSelector([selectAllTasks],
    (tasks: ElementContainer<TaskIndex, TaskData>[]) => tasks.map(task => task.index)
);

export const selectTasks = createSelector([selectAllTasks],
    (tasks: ElementContainer<TaskIndex, TaskData>[]) => tasks.map(task => task.resolvedTruth)
);

export const selectTask = createSelector([
        selectAllTasks,
        (state, task: TaskIndex) => task
    ],
    (tasks: ElementContainer<TaskIndex, TaskData>[], task: TaskIndex) => {
        const taskIndex = tasks.findIndex(p => taskIndexMatch(p.index, task));
        if (taskIndex >= 0) {
            return tasks[taskIndex].resolvedTruth;
        } else {
            return null;
        }
    });

export const selectTaskByType = createSelector([
        selectAllTasks,
        (state, type: TaskType) => type
    ],
    (tasks: ElementContainer<TaskIndex, TaskData>[], type: TaskType) => tasks
        .map(t => t?.resolvedTruth)
        .filter(t => t?.type === type).at(0)
);
