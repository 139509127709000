// @flow

import React, { createContext } from "react";

/**
 * Context for registering and unregistering global hotkeys
 * @type {React$Context<[(function(string, (string|string[]), function(): void): void), (function(string): void)]>}
 */
export const HotkeyContext: React$Context<[(string, string | string[], () => void) => void, (string) => void]> =
    createContext<[(string, string | string[], () => void) => void, (string) => void]>([
        () => console.log("No HotkeyProvider found"),
        () => console.log("No HotkeyProvider found")
    ]);
